import Vue from 'vue';

const abstractValue = {
    currentPage: 0,
    currentSort: null,
    currentSortDir: null,
    totalPages: 1,
};

const abstractGetter = {
    currentPage(state) {
        return state.currentPage;
    },
    currentSort(state) {
        return state.currentSort;
    },
    currentSortDir(state) {
        return state.currentSortDir;
    },
    totalPages(state) {
        return state.totalPages;
    },
    data(state) {
        return state.item;
    },
    list(state) {
        return state.list;
    },
};

const abstractAction = {
    currentSort(context, ugs) {
        context.commit('currentSort', ugs);
    },
    currentSortDir(context, ugs) {
        context.commit('currentSortDir', ugs);
    },
    currentPage(context, pageNr) {
        context.commit('updateCurrentPage', pageNr);
    },
    clearValues(context, params) {
        context.commit('clearValues', params);
    },
};

const abstractMutation = {
    update(state, data) {
        Vue.set(state, 'item', data);
    },
    updateList(state, data) {
        Vue.set(state, 'list', data);
    },
    updateCurrentPage(state, data) {
        Vue.set(state, 'currentPage', data);
    },
    currentSort(state, data) {
        Vue.set(state, 'currentSort', data);
    },
    currentSortDir(state, data) {
        Vue.set(state, 'currentSortDir', data);
    },
    updateTotalPages(state, data) {
        Vue.set(state, 'totalPages', data);
    },
    clearValues(state, params) {
        Vue.set(state, 'currentPage', abstractValue.currentPage);
        Vue.set(state, 'currentSort', params.currentSort);
        Vue.set(state, 'currentSortDir', params.currentSortDir);
        Vue.set(state, 'totalPages', abstractValue.totalPages);
    },
};

export default class abstractStore {
    constructor() {}

    static getAbstractValue(currentSort, currentSortDir) {
        abstractValue.currentSort = currentSort;
        abstractValue.currentSortDir = currentSortDir;
        return abstractValue;
    }

    static getAbstractMutation() {
        return abstractMutation;
    }

    static getAbstractAction() {
        return abstractAction;
    }

    static getAbstractGetter() {
        return abstractGetter;
    }

    static getDefaultStore() {
        return {
            mutations: abstractStore.getAbstractMutation(),
            actions: abstractStore.getAbstractAction(),
            getters: abstractStore.getAbstractGetter(),
        };
    }
}
