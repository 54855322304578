import Vue from 'vue';
import VerleihService from './verleih.service';
import * as _ from 'lodash';
import abstractStore from '../../store/abstractstore';

export const getDefaultSort = () => ({ currentSort: 'verleihDate', currentSortDir: 'desc' });

const getDefaultItem = () => ({
    id: null,
    artikel: null,
    uebergeber: null,
    uebernehmer: null,
    signature: null,
    isPainted: false,
    verleih_date: null,
    checked: false,
});

const getDefaultSelectedKunde = () => ({});
const getDefaultSelectedArtikel = () => ({});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
    selectedKundenId: null,
    selectedKunde: getDefaultSelectedKunde(),
    selectedArtikel: getDefaultSelectedArtikel(),
    nurVerliehene: false,
    ...abstractStore.getAbstractValue(getDefaultSort().currentSort, getDefaultSort().currentSortDir),
});

const verleih = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        selectedKundenId(state, data) {
            state.selectedKundenId = data;
        },
        selectedKunde(state, data) {
            state.selectedKunde = data;
        },
        selectedArtikel(state, data) {
            state.selectedArtikel = data;
        },
        nurVerliehene(state, data) {
            state.nurVerliehene = data;
        },
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state) {
            Vue.set(state, 'item', getDefaultState().item);
            Vue.set(state, 'list', getDefaultState().list);
        },
        clearFilter(state) {
            Vue.set(state, 'selectedKundenId', getDefaultState().selectedKundenId);
            Vue.set(state, 'selectedKunde', getDefaultState().selectedKunde);
            Vue.set(state, 'selectedArtikel', getDefaultState().selectedArtikel);
            Vue.set(state, 'currentPage', getDefaultState().currentPage);
        },
    },

    actions: {
        selectedKundenId(context, kundenId) {
            context.commit('selectedKundenId', kundenId);
        },
        selectedKunde(context, kundenId) {
            context.commit('selectedKunde', kundenId);
        },
        selectedArtikel(context, artikel) {
            context.commit('selectedArtikel', artikel);
        },
        nurVerliehene(context, nurVerliehene) {
            context.commit('nurVerliehene', nurVerliehene);
        },
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            const { data } = await VerleihService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await VerleihService.update(client_data, client_data);
            context.commit('update', data);
            return data;
        },
        async updateBatch(context, client_data) {
            const { data } = await VerleihService.updateBatch(client_data);
            context.commit('updateList', data);
            return data;
        },
        async get(context, params) {
            const { data } = await VerleihService.get(params.slug, params);
            context.commit('update', data);
            return data;
        },
        async query(context, params) {
            const { headers, data } = await VerleihService.query(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let size = params && params.params && params.params.size ? params.params.size : 20;
            let totalCount = headers['x-total-count'];
            let totalPages = 1;

            if (_.toNumber(totalCount) > 0 && _.toNumber(size) > 0) {
                totalPages = _.toNumber(totalCount) / _.toNumber(size);
            }

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            await VerleihService.delete(slug);
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
        selectedKundenId(state) {
            return state.selectedKundenId;
        },
        selectedKunde(state) {
            return state.selectedKunde;
        },
        selectedArtikel(state) {
            return state.selectedArtikel;
        },
        nurVerliehene(state) {
            return state.nurVerliehene;
        },
    },
};

export default _.merge(verleih, abstractStore.getDefaultStore());
