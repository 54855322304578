const defaults = {
    sidebar: {
        visible: true,
    },
    title: '',
    routeFrom: null,
    layout: 'DefaultLayout',
    dialog: {
        visible: false,
        text: '',
    },
    snackbar: {
        visible: false,
        text: '',
        timeout: 6000,
        color: '',
    },
    error: {
        code: null,
        level: null,
        message: '',
    },
    loaderStateError: false,
    todayHighlighted: {
        dates: [new Date()],
    },
};

export default {
    namespaced: true,

    state: Object.assign({}, defaults),

    mutations: {
        updateTitle(state, title) {
            state.title = title;
        },
        updateRouteFrom(state, routeFrom) {
            state.routeFrom = routeFrom;
        },
        error(state, options) {
            state.error = Object.assign({}, defaults.error, options);
        },
        clear(state) {
            Object.assign(state, defaults);
        },
    },

    actions: {
        clear({ commit, dispatch }) {
            commit('clear');
            dispatch('auth/clear', {}, { root: true });
            dispatch('mandant/clear', {}, { root: true });
        },
        updateTitle({ commit }, title) {
            commit('updateTitle', title);
        },

        updateRouteFrom({ commit }, routeFrom) {
            commit('updateRouteFrom', routeFrom);
        },
    },
    getters: {
        loaderState(state) {
            return state.loaderStateError;
        },

        routeFrom(state) {
            return state.routeFrom;
        },

        todayHighlighted(state) {
            return state.todayHighlighted;
        },
    },
};
