<template>
    <div>
        <v-select
            :placeholder="$t('placeholder')"
            :value="value"
            :filterable="false"
            :options="options"
            label="belegNummer"
            class="artikel-bezeichnung-responsive"
            @input="onChange"
            @search="onSearch"
        >
            <template #option="option">
                {{ option.belegNummer }}
                {{ option.kunde ? ' - ' + option.kunde.bezeichnung : '' }}
            </template>
            <template slot="no-options">
                <span>{{ $t('placeholder') }}</span>
            </template>
        </v-select>
    </div>
</template>

<script>
import _ from 'lodash';
import ApiService from '@/common/api.service';
import { DEBOUNCE_DEFAULT_MILLIS } from '@/constants';

export default {
    name: 'BelegSearch',
    props: {
        value: {
            type: String,
            default: '',
        },
        belegType: {
            type: String,
            default: null,
        },
        artikel: {
            type: Object,
            default: null,
        },
        kunde: {
            type: Object,
            default: null,
        },
        project: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            options: [],
            search_value: null,
        };
    },
    methods: {
        onChange(val) {
            this.$emit('input', val);
        },
        onSearch(search, loading) {
            loading(true);
            this.search_value = search;
            if (search.length > 0) {
                this.search(loading, search, this);
            } else {
                loading(false);
            }
        },
        search: _.debounce((loading, search, vm) => {
            const resource = 'belegsearch';
            vm.options = [];
            vm.searched = false;
            let projekts = [];
            if (vm.project && vm.project.length > 0) {
                vm.project.forEach((x) => {
                    projekts.push(x.id);
                });
            }

            ApiService.query(resource, {
                params: {
                    search: search,
                    belegType: vm.belegType && vm.belegType.length > 0 ? vm.belegType : null,
                    artikelId: vm.artikel ? vm.artikel.id : null,
                    kundeId: vm.kunde ? vm.kunde.id : null,
                    projects: projekts.length > 0 ? projekts.join(',') : null,
                },
            }).then((res) => {
                vm.searched = true;
                if (res && res.data && Array.isArray(res.data)) {
                    vm.options = res.data;
                } else {
                    vm.options = [];
                }
                loading(false);
            });
        }, DEBOUNCE_DEFAULT_MILLIS),
    },
};
</script>

<style scoped>
/*.artikel-bezeichnung-responsive {*/
/*    width: 100%;*/
/*}*/
</style>

<!-- language=json -->
<i18n>
{
    "de": {
        "placeholder": "Schnellsuche: Belegnummer",
        "no-result": "Kein Ergebnis",
        "save": "Speichern",
        "cancel": "Abbrechen"
    },
    "en": {
        "placeholder": "Fast search: Receipt number",
        "no-result": "No result"
    }
}
</i18n>
