import Vue from 'vue';
import AccountService from './account.service';
import store from '@/store';
import TotpService from './totp.service';

const getDefaultAccount = () => ({
    firstName: '',
    login: '',
    is2FaUsed: false,
    timermode: false,
    enablemailtransfer: false,
    signature: '',
    newsread: false,
    zeiterfassungUserData: {
        workhours: 0,
        workhoursMon: 0,
        workhoursTue: 0,
        workhoursWed: 0,
        workhoursThu: 0,
        workhoursFri: 0,
        uberstunden_aktuell: 0,
        uberstunden_beginn: 0,
        urlaub_aktuell: 0,
        urlaubsanspruchjahr: 0,
        eintrittsdatum: null,
        uberstunden_deadline: null,
        uberstunden_end: 0,
        mainly_homeoffice: false,
        part_time: false,
    },
});

const getDefaultResetAccount = () => ({
    email: null,
});

const getDefaultMeta = () => ({
    success: false,
    error: false,
    errorEmailExists: false,
    notEqual: false,
    notFound: false,
    keyMissing: false,
});

const getDefaultPwd = () => ({
    password: '',
    confirmPassword: '',
});

const getDefaultTotp = () => ({
    barcodebase64img: null,
});

const getDefaultState = () => ({
    account: getDefaultAccount(),
    resetAccount: getDefaultResetAccount(),
    meta: getDefaultMeta(),
    pwd: getDefaultPwd(),
    totp: getDefaultTotp(),
});

const account = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'account', data);
        },
        updateMeta(state, data) {
            Vue.set(state, 'meta', data);
        },
        clearMeta(state) {
            Vue.set(state, 'meta', getDefaultState().meta);
        },
        clearPwd(state) {
            Vue.set(state, 'pwd', getDefaultState().pwd);
        },
        clearResetAccount(state) {
            Vue.set(state, 'resetAccount', getDefaultState().resetAccount);
        },
        updateTotp(state, data) {
            Vue.set(state, 'totp', data);
        },
        clearTotp(state) {
            Vue.set(state, 'totp', getDefaultState().totp);
        },
    },

    actions: {
        clearMeta(context) {
            context.commit('clearMeta');
        },
        async update(context, data) {
            context.commit('clearMeta');
            context.commit('clearPwd');
            await AccountService.create(data);
            store.dispatch('auth/updateFriendlyUsername', data.firstName + ' ' + data.lastName);
        },
        async updatePassword(context, password) {
            context.commit('clearMeta');
            context.commit('clearPwd');
            return await AccountService.changePassword(password);
        },
        async resetPassword(context, email) {
            context.commit('clearMeta');
            context.commit('clearResetAccount');
            const response = await AccountService.resetPassword(email);
            if (response.status === 200) {
                context.commit('updateMeta', { success: true });
            }

            return response;
        },
        async resetPasswordFinish(context, password) {
            context.commit('clearMeta');
            context.commit('clearResetAccount');
            return await AccountService.resetPasswordFinish(password);
        },
        async get(context, id) {
            context.commit('clearMeta');
            context.commit('clearPwd');
            const { data } = await AccountService.get(id);
            context.commit('update', data);
            return data;
        },
        async get2FaBarcode(context) {
            const { data } = await TotpService.get2FaBarcode();
            const object = {
                barcodebase64img: 'data:image/png;base64,' + data,
            };
            context.commit('updateTotp', object);
            return data;
        },
        async validateCode(context, validationCode) {
            const response = await TotpService.validateCode(validationCode);
            let success = true;
            if (!response || response.status !== 200) {
                success = false;
            }
            return success;
        },
        async clearTotp(context) {
            context.commit('clearTotp');
        },
        async disable2Fa(context, validationCode) {
            const response = await TotpService.disable2Fa(validationCode);
            let success = true;
            if (!response || response.status !== 200) {
                success = false;
            }
            return success;
        },
        async send2FaDeactivationEmail(context, client_data) {
            const response = await TotpService.send2FaDeactivationEmail(client_data);
            let success = true;
            if (!response || response.status !== 200) {
                success = false;
            }
            return success;
        },
        async deactivate2Fa(context, key) {
            context.commit('clearMeta');

            const response = await TotpService.deactivate2Fa(key);
            if (response && response.status === 200) {
                context.commit('updateMeta', { success: true });
                return true;
            } else {
                context.commit('updateMeta', { success: false });
                return false;
            }
        },
    },

    getters: {
        data(state) {
            return state.account;
        },
        dataReset(state) {
            return state.resetAccount;
        },
        meta(state) {
            return state.meta;
        },
        pwd(state) {
            return state.pwd;
        },
        totp(state) {
            return state.totp;
        },
    },
};

export default account;
