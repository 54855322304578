<template>
    <div>
        <button v-if="artikel && artikel.artikelImages.length > 0" class="btn btn-link" :title="$t('artikelBilder')" @click="loadImages($event)">
            <font-awesome-icon :icon="['fad', 'images']" />
        </button>
        <div v-if="showModal">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 id="staticBackdropLabel" class="modal-title"></h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" @click="showModal = false">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body modalartikel">
                                    <div v-for="thumbnail in thumbnails" :key="thumbnail.assetId" style="text-align: center">
                                        <img :src="thumbnail.src" alt="" style="padding-bottom: 1rem" />
                                    </div>
                                </div>
                                <div class="modal-footer"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import ConfigService from '@/common/config.service';
import ApiService from '@/common/api.service';

export default {
    name: 'ArtikelBilder',
    props: {
        artikel: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            url: ConfigService.getConfigIfExists('backendBaseUrl') + 'artikelimage',
            showModal: false,
            thumbnails: [],
            loader: null,
            isLoading: false,
        };
    },
    methods: {
        loadImages(e) {
            e.preventDefault();
            this.loader = this.$loading.show();
            this.thumbnails = [];
            this.artikel.artikelImages.forEach((image) => {
                ApiService.get(this.url + '/' + image.assetId + '/thumbnail/1024/800').then((res) => {
                    this.thumbnails.push({
                        assetId: image.assetId,
                        src: 'data:image/jpeg;base64,' + res.data.asset,
                    });
                    this.showModal = true;
                    this.loader.hide();
                });
            });
        },
    },
};
</script>

<style>
.modalartikel {
    overflow: auto;
    height: 800px;
}
body.modal-open {
    overflow: visible;
}
</style>
<!-- language=json -->
<i18n>
    {
        "de": {
            "artikelBilder": "Artikelbilder"
        },
        "en": {
            "artikelBilder": "Artikelbilder"
        }
    }
</i18n>
