const config = {
    localhost: {
        backendBaseUrl: 'http://localhost:8080/app/api/',
        I18N_LOCALE: 'de',
        I18N_FALLBACK_LOCALE: 'en',
        stage: 'local'
    },
    '192.168.0.108': {
        backendBaseUrl: 'http://192.168.0.108:8080/app/api/',
        I18N_LOCALE: 'de',
        I18N_FALLBACK_LOCALE: 'en',
    },
    'dev.hoerb.at': {
        backendBaseUrl: 'https://dev.hoerb.at/app_v2/api/',
        I18N_LOCALE: 'de',
        I18N_FALLBACK_LOCALE: 'en',
        stage: 'acc'
    },
    'hoerb.at': {
        backendBaseUrl: 'https://hoerb.at/app_v2/api/',
        I18N_LOCALE: 'de',
        I18N_FALLBACK_LOCALE: 'en',
        stage: 'prod'
    },
};
export default config;
