<template>
    <div>
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <form name="editForm" role="form" @submit.prevent="createLieferadresse()">
                            <div class="modal-header">
                                <slot name="header">
                                    <h5>{{ $t('new') }}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('close')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </slot>
                            </div>
                            <div class="modal-body">
                                <slot name="body">
                                    <div class="container">
                                        <div class="row">
                                            <div class="form-group col-md-7">
                                                <label class="control-label" for="field_strasse">{{ $t('adresse.strasse') }}</label>
                                                <input
                                                    v-model="lieferadresse.strasse"
                                                    type="text"
                                                    class="form-control"
                                                    autocomplete="new-street"
                                                    id="field_strasse"
                                                />
                                                <span v-if="!lieferadresse.strasse" class="red-text">
                                                    {{ $t('entity.validation.required') }}
                                                </span>
                                            </div>
                                            <div class="form-group col-md-2">
                                                <label class="control-label" for="field_hausNummer">{{ $t('adresse.hausNummer') }}</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    autocomplete="new-housenumber"
                                                    maxlength="10"
                                                    v-model="lieferadresse.hausNummer"
                                                    id="field_hausNummer"
                                                    name="hausNummer"
                                                />
                                                <span v-if="!lieferadresse.hausNummer" class="red-text">
                                                    {{ $t('entity.validation.required') }}
                                                </span>
                                            </div>
                                            <div class="form-group col-md-2">
                                                <label class="control-label" for="field_stiege">{{ $t('adresse.stiege') }}</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    autocomplete="new-floor"
                                                    maxlength="10"
                                                    v-model="lieferadresse.stiege"
                                                    id="field_stiege"
                                                    name="stiege"
                                                />
                                            </div>
                                            <div class="form-group col-md-1">
                                                <label class="control-label" for="field_tuer">{{ $t('adresse.tuer') }}</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    autocomplete="new-door"
                                                    maxlength="10"
                                                    v-model="lieferadresse.tuer"
                                                    id="field_tuer"
                                                    name="tuer"
                                                />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-md-6">
                                                <label class="control-label" for="field_adressZusatz">{{ $t('adresse.adresszusatz') }}</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    autocomplete="new-addition"
                                                    maxlength="100"
                                                    v-model="lieferadresse.adressZusatz"
                                                    id="field_adressZusatz"
                                                    name="adressZusatz"
                                                />
                                            </div>
                                            <div class="form-group col-md-3" :title="$t('zuhandeninfo')">
                                                <label class="control-label" for="field_zuhandenaktiv">{{ $t('zuhandenaktiv') }}</label>
                                                <input
                                                    type="checkbox"
                                                    id="field_zuhandenaktiv"
                                                    class="form-control-checkbox"
                                                    v-model="lieferadresse.zuHanden"
                                                />
                                                <!-- name="zuHandenAktiv" -->
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-md-2">
                                                <label class="control-label" for="field_plz">{{ $t('adresse.plz') }}</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    autocomplete="new-zipcode"
                                                    maxlength="10"
                                                    v-model="lieferadresse.plz"
                                                    id="field_plz"
                                                    name="plz"
                                                />
                                                <span v-if="!lieferadresse.plz" class="red-text">
                                                    {{ $t('entity.validation.required') }}
                                                </span>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label class="control-label" for="field_ort">{{ $t('adresse.ort') }}</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="field_ort"
                                                    name="ort"
                                                    autocomplete="new-city"
                                                    v-model="lieferadresse.ort"
                                                />
                                                <span v-if="!lieferadresse.ort" class="red-text">
                                                    {{ $t('entity.validation.required') }}
                                                </span>
                                            </div>
                                            <div class="form-group col-md-4">
                                                <label>{{ $t('adresse.land') }}</label>
                                                <land-component required v-model="lieferadresse.land" name="land"></land-component>
                                                <span v-if="!lieferadresse.land" class="red-text">
                                                    {{ $t('entity.validation.required') }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </slot>
                            </div>
                            <div class="modal-footer">
                                <slot name="footer">
                                    <button type="button" class="btn dg-btn--cancel" @click="$emit('close')">
                                        <font-awesome-icon :icon="['fad', 'ban']" />&nbsp;<span>{{ $t('cancel') }}</span>
                                    </button>
                                    <button-spinner
                                        :style-class="'btn dg-btn--ok dg-pull-right'"
                                        :is-loading="isLoading"
                                        :text="$t('save')"
                                        :disabled="isLoading || setButtonStatus()"
                                        :typ="'button'"
                                        :icon="'save'"
                                        @finished="isLoading = false"
                                        @action="
                                            isLoading = true;
                                            createLieferadresse();
                                        "
                                    >
                                    </button-spinner>
                                </slot>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '@/common/api.service';
import Toast from '@/common/toast';
import { ADRESSEN_TYP } from '@/constants';
export default {
    name: 'LieferadresseSchnellanlage',
    props: {
        kunde: {
            type: Object,
            default: null,
        },
        mandant: {
            type: Object,
            default: null,
        },
        value: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isLoading: false,
            lieferadresse: {},
        };
    },
    computed: {
        ...mapGetters({}),
    },
    mounted() {
        // this.$store.dispatch('kunde/clear').then(() => {
        //     this.kunde.firma = 'firma';
        //     this.kunde.geschlecht = 'herr';
        // });
    },
    methods: {
        setButtonStatus() {
            return (
                this.lieferadresse.strasse == null ||
                this.lieferadresse.strasse === '' ||
                this.lieferadresse.hausNummer == null ||
                this.lieferadresse.hausNummer === '' ||
                this.lieferadresse.plz == null ||
                this.lieferadresse.plz === '' ||
                this.lieferadresse.ort == null ||
                this.lieferadresse.ort === '' ||
                this.lieferadresse.land == null
            );
        },
        createLieferadresse() {
            this.lieferadresse.adressenTyp = ADRESSEN_TYP.LIEFERADRESSE;
            this.lieferadresse.kunde = this.kunde;
            this.lieferadresse.adressZusatz = !this.lieferadresse.adressZusatz ? null : this.lieferadresse.adressZusatz;
            this.lieferadresse.tuer = !this.lieferadresse.tuer ? null : this.lieferadresse.tuer;
            this.lieferadresse.stiege = !this.lieferadresse.stiege ? null : this.lieferadresse.stiege;
            this.lieferadresse.zuHanden = !this.lieferadresse.zuHanden ? null : this.lieferadresse.zuHanden;

            let params = this.lieferadresse;
            ApiService.post('adresses', params)
                .then((res) => {
                    this.$emit('close-modal', res.data);
                    Toast.showCreateOkToastI18n();
                })
                .catch((err) => {
                    Toast.showSaveErrorToastI18n();
                });
        },
    },
};
</script>

<style></style>
<!-- language=json -->
<i18n>
    {
        "de": {
            "new": "Neue Lieferadresse anlegen",
            "no-result": "Kein Ergebnis",
            "save": "Speichern",
            "cancel": "Abbrechen",
            "adresseList": "AdresseList",
            "adressZusatz": "Adresszusatz",
            "zuhandenaktiv": "zu Handen Feld (wird am Beleg angezeigt)",
            "hausnummer": "Hausnummer",
            "strasse": "Strasse",
            "stiege": "Stiege",
            "tuer": "Tür",
            "ort": "Ort",
            "land": "Land",
            "plz": "Postleitzahl"
        },
        "en": {
            "new": "Create new billing adress",
            "no-result": "No result",
            "save": "Save",
            "cancel": "Cancel",
            "zuhandenaktiv": "to the attention (show field on Beleg)",
            "adresseList": "AdresseList",
            "adressezusatz": "Adressezusatz",
            "hausnummer": "Housenumber",
            "strasse": "Street",
            "stiege": "stairway",
            "tuer": "Door",
            "ort": "City",
            "land": "Country",
            "plz": "Zip Code"
        }
    }
</i18n>
