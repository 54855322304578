import Vue from 'vue';
import abstractStore from '@/store/abstractstore';
import * as _ from 'lodash';
import AccountService from '../account/account.service';

export const getDefaultSort = () => ({ currentSort: 'lastName', currentSortDir: 'asc' });

const getDefaultZeiterfassungUserData = () => ({
    id: null,
    workhours: 0,
    urlaubsanspruchjahr: 0,
    urlaub_aktuell: 0,
    eintrittsdatum: null,
    mainly_homeoffice: false,
    uberstunden_beginn: 0,
    uberstunden_aktuell: 0,
    uberstunden_deadline: null,
    uberstunden_end: 0,
    part_time: false,
    workhoursMon: 0,
    workhoursTue: 0,
    workhoursWed: 0,
    workhoursThu: 0,
    workhoursFri: 0,
});

const getDefaultItem = () => ({
    id: null,
    firstName: '',
    lastName: '',
    zeiterfassungUserData: getDefaultZeiterfassungUserData(),
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
    currentPage: 0,
    totalPages: 1,
    ...abstractStore.getAbstractValue(getDefaultSort().currentSort, getDefaultSort().currentSortDir),
});

const zemsetting = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state) {
            Vue.set(state, 'item', getDefaultState().item);
            Vue.set(state, 'list', getDefaultState().list);
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
    },
    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async update(context, client_data) {
            if (client_data._id == null || client_data.id == null) {
                return false;
            }
            const { data } = await AccountService.update(client_data.id, client_data);
            return data;
        },
        async get(context, params) {
            const { headers, data } = await AccountService.query(params);

            let currPage = params && params.params && params.params.get('page') ? params.params.get('page') : 0;
            context.commit('updateCurrentPage', +currPage);

            let size = params && params.params && params.params.get('size') ? params.params.get('size') : 20;
            let totalCount = headers['x-total-count'];
            let totalPages = 1;

            if (_.toNumber(totalCount) > 0 && _.toNumber(size) > 0) {
                totalPages = _.toNumber(totalCount) / _.toNumber(size);
            }

            let array = [data];
            context.commit('updateTotalPages', _.ceil(totalPages));
            context.commit('updateList', array);
            return data;
        },
    },
    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
        defaultZeiterfassungUserData() {
            return getDefaultZeiterfassungUserData();
        },
    },
};

export default _.merge(zemsetting, abstractStore.getDefaultStore());
