<template>
    <div>
        <div class="form-row">
            <div class="col-12">
                <div class="input-group">
                    <v-select
                        :placeholder="$t('placeholder')"
                        :value="value"
                        :filterable="false"
                        :options="options"
                        class="artikel-bezeichnung-responsive"
                        label="label"
                        @input="onChange"
                        @search="onSearch"
                    >
                        <template slot="no-options">
                            <span v-if="searched && createUserFlag" class="error-text text-left">
                                <button type="button" class="btn btn-outline-primary" style="padding-top: 1px; padding-bottom: 2px" @click="createBenutzer">
                                    <font-awesome-icon :icon="['fad', 'plus']" /> {{ $t('new') }}
                                </button>
                            </span>
                            <span v-else>{{ $t('placeholder') }}</span>
                        </template>
                    </v-select>
                    <button v-if="hasDetailSuche" type="button" class="btn btn-secondary btn-sm" style="margin-left: 5px" @click="showModalDetail = true">
                        <font-awesome-icon :icon="['fad', 'file-search']" />
                    </button>
                </div>
            </div>
        </div>
        <transition name="modal">
            <artikel-schnell-anlage v-if="showModal" :value="search_value" @close="showModal = false" @close-modal="closeModal($event)" />
        </transition>
        <artikel-detail-suche v-if="showModalDetail" @close="showModalDetail = false" @close-modal="closeModal($event)" />
    </div>
</template>

<script>
import _ from 'lodash';
import ApiService from '@/common/api.service';
import { DEBOUNCE_DEFAULT_MILLIS } from '@/constants';

export default {
    name: 'BenutzerSearch',
    props: {
        value: {
            type: String,
            default: '',
        },
        user: {
            type: Object,
            default: null,
        },
        createUserFlag: {
            type: Boolean,
            default: false,
        },
        hasDetailSuche: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            options: [],
            search_value: null,
            searched: false,
            showModal: false,
            showModalDetail: false,
        };
    },
    methods: {
        onChange(val) {
            this.$emit('input', val);
        },
        onSearch(search, loading) {
            loading(true);
            this.search_value = search;
            if (search.length > 0) {
                this.search(loading, search, this);
            } else {
                loading(false);
            }
        },
        createBenutzer() {
            this.showModal = true;
        },
        closeModal(user) {
            this.showModalDetail = false;
            this.showModal = false;
            this.onChange(user);
        },
        search: _.debounce((loading, search, vm) => {
            const resource = 'usersearch';
            vm.options = [];
            vm.searched = false;
            ApiService.query(resource, { params: { search: search } }).then((res) => {
                vm.searched = true;
                if (res && res.data && Array.isArray(res.data)) {
                    res.data.forEach((x) => {
                        x.label = x.firstName + ' ' + x.lastName + ' / ' + x.login;
                    });
                    vm.options = res.data;
                } else {
                    vm.options = [];
                }
                loading(false);
            });
        }, DEBOUNCE_DEFAULT_MILLIS),
    },
};
</script>

<style scoped></style>

<!-- language=json -->
<i18n>
    {
        "de": {
            "placeholder": "Schnellsuche: Vor- / Nachname, Login oder E-Mail",
            "new": "Neuen Benutzer anlegen",
            "no-result": "Kein Ergebnis",
            "save": "Speichern",
            "cancel": "Abbrechen"
        },
        "en": {
            "placeholder": "Fast search: first- / lastname, login or e-mail",
            "new": "Create new user",
            "no-result": "Kein Ergebnis",
            "save": "Speichern",
            "cancel": "Abbrechen"
        }
    }
</i18n>
