<template>
    <div>
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <form name="editForm" role="form" @submit.prevent="createArticle()">
                            <div class="modal-header">
                                <slot name="header">
                                    <h5>{{ $t('new') }}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('close')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </slot>
                            </div>
                            <div class="modal-body" style="height: 500px; overflow-y: auto">
                                <slot name="body">
                                    <div class="container" align="center">
                                        <div class="row">
                                            <div class="form-group col-md-6">
                                                <label for="field_warengruppe">{{ $t('global.menu.entities.warengruppe') }}</label>
                                                <select
                                                    id="field_warengruppe"
                                                    v-model="warengruppe"
                                                    class="form-control"
                                                    name="warengruppe"
                                                    @change="selectUG($event)"
                                                >
                                                    <option value=""></option>
                                                    <option v-for="wg in warengruppen" :key="wg.id" :value="wg.id">
                                                        {{ wg.warenGruppe }} - {{ wg.bezeichnung }}
                                                    </option>
                                                </select>
                                            </div>

                                            <div class="form-group col-md-6">
                                                <label for="field_untergruppe">{{ $t('global.menu.entities.untergruppe') }}</label>
                                                <select
                                                    id="field_untergruppe"
                                                    v-model="untergruppe"
                                                    class="form-control"
                                                    name="untergruppe"
                                                    @change="searchArtikel($event)"
                                                >
                                                    <option value=""></option>
                                                    <option v-for="ug in untergruppen" :key="ug.id" :value="ug.id">
                                                        {{ ug.unterGruppe }} - {{ ug.bezeichnung }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div v-if="loading" class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <div v-if="artikels.length > 0" class="table-responsive">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th scope="col">{{ $t('artikelNummer') }}</th>
                                                    <th scope="col">{{ $t('artikelBezeichnung') }}</th>
                                                    <th scope="col">{{ $t('verkaufsPreisExklUst') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="artikel in artikels" :key="artikel.id">
                                                    <td>
                                                        <button
                                                            type="button"
                                                            class="btn btn-secondary btn-sm"
                                                            style="margin-left: 5px"
                                                            @click="selectArticle(artikel)"
                                                        >
                                                            <font-awesome-icon :icon="['fad', 'file-check']" />
                                                        </button>
                                                    </td>
                                                    <td>{{ artikel.artikelNummer }}</td>
                                                    <td>{{ artikel.artikelBezeichnung }}</td>
                                                    <td class="text-right">
                                                        {{ $n(getPreis(artikel), 'currency', $i18n.locale) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </slot>
                            </div>
                            <div class="modal-footer">
                                <slot name="footer">
                                    <button type="button" class="btn dg-btn--cancel" @click="$emit('close')">
                                        <font-awesome-icon :icon="['fad', 'ban']" />&nbsp;<span>{{ $t('cancel') }}</span>
                                    </button>
                                </slot>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { artikelutils } from './mixins/artikelutils';
import ApiService from '@/common/api.service';
import ArtikelPreisHelper from '@/app/artikel/artikel.preis.helper';
import { warengruppeutils } from './mixins/warengruppeutils';

export default {
    name: 'ArtikelDetailSuche',
    mixins: [artikelutils, warengruppeutils],
    data() {
        return {
            loading: false,
            untergruppe: null,
            warengruppe: null,
            untergruppen: [],
            artikels: [],
            allUntergruppen: [],
            warengruppen: [],
        };
    },
    computed: {},
    validations: {},
    async mounted() {
        this.warengruppen = await warengruppeutils.methods.getWarengruppen();
        this.allUntergruppen = await warengruppeutils.methods.getUntergruppen();
    },
    methods: {
        selectArticle(artikel) {
            this.$emit('close-modal', artikel);
        },
        getPreis(artikel) {
            let preis = ArtikelPreisHelper.getRecentPreis(artikel.artikelPreisSet);
            return preis && preis.preis ? preis.preis : 0;
        },
        selectUG(event) {
            this.artikels = [];
            this.untergruppe = null;
            this.untergruppen = [];
            this.untergruppen = this.allUntergruppen.filter((x) => x.warengruppe.id == event.target.value);
        },
        searchArtikel(event) {
            this.artikels = [];
            if (event.target.value != null && event.target.value != '') {
                this.loading = true;
                ApiService.query('artikelsearch/' + event.target.value).then((res) => {
                    this.artikels = res.data.artikelList;
                    this.loading = false;
                });
            }
        },
        createArticle() {
            this.artikel.artikelPreisSet = [
                {
                    preis: this.preis,
                    vonDatum: new Date(),
                },
            ];

            if (this.artikel.untergruppe === '') {
                this.artikel.untergruppe = null;
            }
            this.$store.dispatch('artikel/update', this.artikel).then((response) => this.$emit('close-modal', response));
        },
    },
};
</script>

<style></style>
<!-- language=json -->
<i18n>
    {
        "de": {
            "new": "Detailsuche",
            "cancel": "Abbrechen",
            "artikelNummer": "Artikelnummer",
            "artikelBezeichnung": "Artikelbezeichnung",
            "verkaufsPreisExklUst": "Verkaufspreis Netto"
        },
        "en": {
            "new": "Detailsearch",
            "cancel": "Cancel",
            "artikelNummer": "Artikelnummer",
            "artikelBezeichnung": "Artikelbezeichnung",
            "verkaufsPreisExklUst": "Verkaufspreis Netto"
        }
    }
</i18n>
