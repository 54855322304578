<template>
    <div style="padding-right: 0; padding-top: 0.1em; padding-bottom: 0.4em">
        <div class="editor" style="outline: none !important">
            <div class="btn-toolbar" role="toolbar">
                <div class="btn-group mr-2" role="group">
                    <button
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        :class="{ 'is-active': editor.isActive('bold') }"
                        @click="editor.chain().focus().toggleBold().run()"
                    >
                        <font-awesome-icon :icon="['fad', 'bold']" />
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        :class="{ 'is-active': editor.isActive('italic') }"
                        @click="editor.chain().focus().toggleItalic().run()"
                    >
                        <font-awesome-icon :icon="['fad', 'italic']" />
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        :class="{ 'is-active': editor.isActive('underline') }"
                        @click="editor.chain().focus().toggleUnderline().run()"
                    >
                        <font-awesome-icon :icon="['fad', 'underline']" />
                    </button>
                </div>
                <div class="btn-group mr-2" role="group">
                    <button
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        :class="{ 'is-active': editor.isActive('paragraph') }"
                        @click="editor.chain().focus().setParagraph().run()"
                    >
                        <font-awesome-icon :icon="['fad', 'paragraph']" />
                    </button>
                    <button
                        v-if="editorDisabledButtons && editorDisabledButtons.indexOf('H1') === -1"
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
                        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                    >
                        <font-awesome-icon :icon="['fad', 'h1']" />
                    </button>
                    <button
                        v-if="editorDisabledButtons && editorDisabledButtons.indexOf('H2') === -1"
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
                        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                    >
                        <font-awesome-icon :icon="['fad', 'h2']" />
                    </button>
                    <button
                        v-if="editorDisabledButtons && editorDisabledButtons.indexOf('H3') === -1"
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
                        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                    >
                        <font-awesome-icon :icon="['fad', 'h3']" />
                    </button>
                    <button
                        v-if="editorDisabledButtons && editorDisabledButtons.indexOf('Code') === -1"
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        :class="{ 'is-active': editor.isActive('code') }"
                        @click="editor.chain().focus().toggleCode().run()"
                    >
                        <font-awesome-icon :icon="['fad', 'code']" />
                    </button>
                </div>
                <button
                    v-if="editorDisabledButtons && editorDisabledButtons.indexOf('Image') === -1"
                    type="button"
                    class="btn btn-outline-primary btn-sm mr-2"
                    @click="uploadImage()"
                >
                    <font-awesome-icon :icon="['fad', 'image']" />
                </button>
                <input id="uploadImage" class="input-file" type="file" accept="image/*" style="display: none" @change="encodeImageFileAsURL" />
                <div class="btn-group mr-2" role="group">
                    <button
                        v-if="editorDisabledButtons && editorDisabledButtons.indexOf('Left') === -1"
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
                        @click="editor.chain().focus().setTextAlign('left').run()"
                    >
                        <font-awesome-icon :icon="['fad', 'align-left']" />
                    </button>
                    <button
                        v-if="editorDisabledButtons && editorDisabledButtons.indexOf('Center') === -1"
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
                        @click="editor.chain().focus().setTextAlign('center').run()"
                    >
                        <font-awesome-icon :icon="['fad', 'align-center']" />
                    </button>
                    <button
                        v-if="editorDisabledButtons && editorDisabledButtons.indexOf('Right') === -1"
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
                        @click="editor.chain().focus().setTextAlign('right').run()"
                    >
                        <font-awesome-icon :icon="['fad', 'align-right']" />
                    </button>
                </div>
                <div class="btn-group mr-2" role="group">
                    <button
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        :class="{ 'is-active': editor.isActive('bulletList') }"
                        @click="editor.chain().focus().toggleBulletList().run()"
                    >
                        <font-awesome-icon :icon="['fad', 'list-ul']" />
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-primary btn-sm"
                        :class="{ 'is-active': editor.isActive('orderedList') }"
                        @click="editor.chain().focus().toggleOrderedList().run()"
                    >
                        <font-awesome-icon :icon="['fad', 'list-ol']" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EditorMenu',
    props: {
        editor: {
            type: Object,
            default: null,
        },
        editorDisabledButtons: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        uploadImage() {
            document.getElementById('uploadImage').click();
        },
        encodeImageFileAsURL(event) {
            let file = event.target.files[0];
            let reader = new FileReader();
            reader.onloadend = (event) => {
                let url = event.target.result;
                if (url) {
                    this.setImage(url);
                }
            };
            reader.readAsDataURL(file);
        },
        setImage(url) {
            this.editor.chain().focus().setImage({ src: url }).run();
        },
    },
};
</script>

<style lang="css" scoped>
.is-active {
    color: #fff !important;
    background-color: #175276 !important;
    border-color: #175276 !important;
}
</style>
