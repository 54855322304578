<template>
    <div>
        <div class="form-row">
            <div class="col-12">
                <div class="input-group">
                    <v-select
                        :placeholder="$t('placeholder')"
                        :value="value"
                        :filterable="true"
                        :options="projekte"
                        :searchable="true"
                        :multiple="true"
                        :disabled="getEditable()"
                        label="bezeichnung"
                        style="margin-left: auto; width: 100%"
                        class="artikel-bezeichnung-responsive"
                        ref="dropdown"
                        @input="onChange"
                    >
                    </v-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MultiProjektSearch',
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        projekt: {
            type: Object,
            default: null,
        },
        projekte: {
            type: Array,
            default: () => [],
        },
        canEdit: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            options: [],
            search_value: null,
        };
    },
    methods: {
        onChange(val) {
            this.$emit('input', val);
        },
        closeModal(projekt) {
            this.showModalDetail = false;
            this.showModal = false;
            this.onChange(projekt);
        },
        getEditable() {
            return !this.canEdit;
        },
    },
};
</script>

<style scoped></style>

language=json
<i18n>
{
    "de": {
        "placeholder": "Schnellsuche: Projekt",
        "deactivated": "deaktiviert",
        "noresult": "Keine Projekte gefunden"
    },
    "en": {
        "placeholder": "Fast search: project",
        "deactivated": "deaktiviert",
        "noresult": "No projects found"
    }
}
</i18n>
