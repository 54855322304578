import ApiService from '@/common/api.service';

const resource = 'zeitreport';
const resourcework = 'zeiterfassungreport';

const ZeitReportService = {
    async queryReportData(params) {
        return ApiService.query(resource, params);
    },
    async queryUserReportData(params) {
        return ApiService.query(resource + '/user', params);
    },
    async queryWorkUserReportData(params) {
        return ApiService.query(resourcework, params);
    },
};

export default ZeitReportService;
