import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'kundes';
const resourceNumber = 'nextkundennumber';
const resourceAll = 'allkundes';

const KundeService = {
    async get(slug) {
        return ApiService.get(resource, slug);
    },
    async getNextNumber() {
        return ApiService.get(resourceNumber);
    },
    async getByKnr(knr) {
        return ApiService.get(resource + 'knr', knr);
    },
    async getLandByLaenderkuerzel(landkuerzel) {
        return ApiService.get('landsByLaenderKuerzel', landkuerzel);
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
    async queryAll(params) {
        return ApiService.query(resourceAll, params);
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params, showToast = true) {
        let promise = null;
        try {
            promise = await ApiService.put(resource, params);
            Toast.showSaveOkToastI18n(null, showToast);
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async updateList(params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource + 'list', params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async getAllLands() {
        return ApiService.get('alllands');
    },
    async getAllPreisschienes() {
        return ApiService.get('allpreisschienes');
    },
    async delete(params) {
        let promise = null;
        try {
            promise = await ApiService.delete(resource, params.slug);
            Toast.showDeleteOkToastI18n();
        } catch (e) {
            Toast.showDeleteErrorToastI18n(params.deleteErrorText);
        }
        return promise;
    },
};

export default KundeService;
