import Vue from 'vue';
import * as _ from 'lodash';
import abstractStore from '../../store/abstractstore';
import ArtikelKatalogService from '@/app/artikel/artikelkatalog.service';

export const getDefaultSort = () => ({ currentSort: 'artikelBezeichnung', currentSortDir: 'asc' });

const getDefaultState = () => ({
    searchTerm: '',
    selectedUntergruppe: null,
    selectedLieferant: null,
    selectedWG: [],
    selectedUG: [],
    selectedUGS: [],
    selectedVerleihbar: false,
    selectedIncludeDeactivated: false,
    ...abstractStore.getAbstractValue(getDefaultSort().currentSort, getDefaultSort().currentSortDir),
});

const artikelkatalog = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        searchTerm(state, data) {
            Vue.set(state, 'searchTerm', data);
        },
        selectedUntergruppe(state, data) {
            Vue.set(state, 'selectedUntergruppe', data);
        },
        selectedLieferant(state, data) {
            Vue.set(state, 'selectedLieferant', data);
        },
        selectedWG(state, data) {
            Vue.set(state, 'selectedWG', data);
        },
        selectedUG(state, data) {
            Vue.set(state, 'selectedUG', data);
        },
        selectedUGS(state, data) {
            Vue.set(state, 'selectedUGS', data);
        },
        selectedVerleihbar(state, data) {
            Vue.set(state, 'selectedVerleihbar', data);
        },
        clearComplete(state) {
            Object.assign(state, getDefaultState());
        },
    },
    actions: {
        clearComplete({ commit }) {
            commit('clearComplete');
        },
        async query(context, params) {
            if ((params && params.params && params.params.init) === true && params.params.page === 0 && context.rootState.artikelkatalog.currentPage !== 0) {
                params.params.page = context.rootState.artikelkatalog.currentPage;
            }
            const { data, headers } = await ArtikelKatalogService.query(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let size = params && params.params && params.params.size ? params.params.size : 20;
            let totalCount = headers['x-total-count'];
            let totalPages = 1;

            if (_.toNumber(totalCount) > 0 && _.toNumber(size) > 0) {
                totalPages = _.toNumber(totalCount) / _.toNumber(size);
            }

            context.commit('updateTotalPages', _.ceil(totalPages));
            return data;
        },
        searchTerm(context, searchTerm) {
            context.commit('searchTerm', searchTerm);
        },
        selectedUntergruppe(context, selectedUntergruppe) {
            context.commit('selectedUntergruppe', selectedUntergruppe);
        },
        selectedLieferant(context, selectedLieferant) {
            context.commit('selectedLieferant', selectedLieferant);
        },
        selectedWG(context, wg) {
            context.commit('selectedWG', wg);
        },
        selectedUG(context, ug) {
            context.commit('selectedUG', ug);
        },
        selectedUGS(context, ugs) {
            context.commit('selectedUGS', ugs);
        },
        selectedVerleihbar(context, ugs) {
            context.commit('selectedVerleihbar', ugs);
        },
        selectedIncludeDeactivated(context, ugs) {
            context.commit('selectedIncludeDeactivated', ugs);
        },
    },

    getters: {
        selectedUntergruppe(state) {
            return state.selectedUntergruppe;
        },
        searchTerm(state) {
            return state.searchTerm;
        },
        selectedLieferant(state) {
            return state.selectedLieferant;
        },
        selectedWG(state) {
            return state.selectedWG;
        },
        selectedUG(state) {
            return state.selectedUG;
        },
        selectedUGS(state) {
            return state.selectedUGS;
        },
        selectedVerleihbar(state) {
            return state.selectedVerleihbar;
        },
        selectedIncludeDeactivated(state) {
            return state.selectedIncludeDeactivated;
        },
    },
};

export default _.merge(artikelkatalog, abstractStore.getDefaultStore());
