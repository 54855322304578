import config from '@/config/configuration';

let articleImages = 5;
let articleImagesInitial = 5;
let pdfMaxFiles = 5;

const ConfigService = {
    getArticleImagesInitial() {
        return articleImagesInitial;
    },
    setMaxFilesArticles(articles) {
        articleImages = articles;
    },
    getMaxFilesArticles() {
        return articleImages;
    },
    getPdfMaxFiles() {
        return pdfMaxFiles;
    },
    getConfigIfExists(key) {
        if (config[window.location.hostname] && config[window.location.hostname][key]) {
            return config[window.location.hostname][key];
        }
        switch (key) {
            case 'backendBaseUrl':
                return '/app/api/';
            case 'I18N_LOCALE':
                return 'de';
            case 'I18N_FALLBACK_LOCALE':
                return 'en';
            case 'stage':
                return 'local';
            default:
                return '';
        }
    },
};

export default ConfigService;
