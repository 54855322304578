import Vue from 'vue';
import Vuex from 'vuex';
import common from './common';
import { sessionStoragePlugin, localStoragePlugin } from './plugins';
import auth from '@/app/auth/auth.store';
import mandant from '@/app/mandant/mandant.store';
import account from '@/app/account/account.store';
import belegtext from '@/app/belegtext/belegtext.store';
import projekt from '@/app/projekt/projekt.store';
import kassabuch from '@/app/kassabuch/kassabuch.store';
import warengruppe from '@/app/warengruppe/warengruppe.store';
import dashboard from '../app/home/dashboard.store';
import untergruppe from '@/app/untergruppe/untergruppe.store';
import zemsetting from '@/app/zeiterfassung_mitarbeiter_settings/zem_settings.store';
//import user from '@/app/user/user.store'
import kunde from '@/app/kunde/kunde.store';
import firmatyp from '@/app/firmatyp/firmatyp.store';
import geschlecht from '@/app/geschlecht/geschlecht.store';
import role from '@/app/role/role.store';
import belegPdflayout from '@/app/belegpdflayout/belegPdflayout.store';
import preisschiene from '@/app/preisschiene/preisschiene.store';
import zahlungsziel from '@/app/zahlungsziel/zahlungsziel.store';
import lieferbedingung from '@/app/lieferbedingung/lieferbedingung.store';
import zahlungsbedingung from '@/app/zahlungsbedingung/zahlungsbedingung.store';
import rabatt from '@/app/rabatt/rabatt.store';
import artikel from '@/app/artikel/artikel.store';
import beleg from '@/app/beleg/beleg.store';
import belegtyp from '@/app/belegtyp/belegtyp.store';
import belegtypGui from '@/app/belegtyp/belegtypGui.store';
import mehrwertssteuer from '@/app/mehrwertssteuer/mehrwertssteuer.store';
import auswertung from '@/app/auswertung/auswertung.store';
import rechnungsvorlage from '@/app/rechnungsvorlage/rechnungsvorlage.store';
import zahlung from '@/app/zahlung/zahlung.store';
import user from '@/app/admin/user/user.store';
import modul from '@/app/admin/module/module.store';
import abrechnung from '@/app/admin/abrechnung/abrechnung.store';
import modultypen from '@/app/admin/modultypen/modultypen.store';
import lagerplaetze from '@/app/lager/lagerplaetze/lagerplaetze.store';
import einauslagerung from '@/app/lager/einauslagerung/einauslagerung.store';
import freielagerplaetze from '@/app/lager/freielagerplaetze/freielagerplaetze.store';
import rechnungskreise from '@/app/admin/rechnungskreise/rechnungskreise.store';
import belegtypBestellung from '../app/belegtyp/belegtypBestellung.store';
import zeiterfassung from '../app/zeiterfassung/zeiterfassung.store';
import zeiterfassung_mitarbeiter from '../app/zeiterfassung_mitarbeiter/zeiterfassung_mitarbeiter.store';
import zeitreport from '../app/zeitreport/zeitreport.store';
import verleih from '../app/verleih/verleih.store';
import belegfelddefinition from '../app/belegfelddefinition/belegfelddefinition.store';
import kundefelddefinition from '../app/kundefelddefinition/kundefelddefinition.store';
import artikelfelddefinition from '../app/artikelfelddefinition/artikelfelddefinition.store';
import belegfeldtypen from '../app/belegfelddefinition/belegfeldtyp/belegfeldtyp.store';
import note from '../app/home/note.store';
import group from '../app/group/group.store';
import firmengruppe from '@/app/firmengruppe/firmengruppe.store';
import benachrichtigungen from '@/app/benachrichtigungen/benachrichtigungen.store';
import artikelkatalog from '@/app/artikel/artikelkatalog.store';
import folder from '@/app/content/folder.store';
import content from '@/app/content/content.store';
import favorites from '@/app/favorites/favorites.store';
import contentfile from '@/app/content/contentfile.store';
import belegnummerkreise from '@/app/admin/belegnummerkreise/belegnummerkreise.store';
import wccategory from '@/app/webshop/wccategory.store';
import constructionSite from '@/app/quartalskalender/constructionsite.store';
import quartalskalender from '@/app/quartalskalender/quartalskalender.store';
import holidayEntry from '@/app/quartalskalender/holidayentry.store';
import quartalskalenderNote from '@/app/quartalskalender/quartalskalendernote.store';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        packageVersion: JSON.stringify(require('../../package.json').version),
        // the rest of your state here
    },
    getters: {
        appVersion: (state) => state.packageVersion,
    },
    modules: {
        common,
        auth,
        mandant,
        account,
        belegtext,
        projekt,
        belegPdflayout,
        user,
        kunde,
        firmatyp,
        geschlecht,
        preisschiene,
        zahlungsziel,
        lieferbedingung,
        zahlungsbedingung,
        dashboard,
        rabatt,
        role,
        artikel,
        beleg,
        belegtypGui,
        belegtypBestellung,
        belegtyp,
        mehrwertssteuer,
        auswertung,
        rechnungsvorlage,
        zahlung,
        modul,
        abrechnung,
        modultypen,
        lagerplaetze,
        einauslagerung,
        freielagerplaetze,
        rechnungskreise,
        kassabuch,
        zeiterfassung,
        zeiterfassung_mitarbeiter,
        warengruppe,
        untergruppe,
        zemsetting,
        zeitreport,
        verleih,
        belegfelddefinition,
        artikelfelddefinition,
        belegfeldtypen,
        note,
        group,
        firmengruppe,
        benachrichtigungen,
        artikelkatalog,
        kundefelddefinition,
        content,
        folder,
        favorites,
        contentfile,
        belegnummerkreise,
        wccategory,
        quartalskalender,
        constructionSite,
        holidayEntry,
        quartalskalenderNote,
    },
    plugins: [sessionStoragePlugin, localStoragePlugin],
});
