<template>
    <div>
        <table class="hoerb-table table table-striped">
            <thead>
                <tr>
                    <th
                        v-for="field in fields"
                        :key="field.key"
                        :style="field.sortable ? { cursor: 'pointer' } : {}"
                        @click="field.sortable ? sort(field.key) : null"
                    >
                        {{ field.label }}
                        <sort-icon :sort-typ="field.key" :current-sort="currentSort" :current-sort-dir="currentSortDir"></sort-icon>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in sortedItems" :key="item.id">
                    <td v-for="field in fields" :key="field.key">
                        <span class="hoerb-table-title"> {{ field.label }}</span>
                        <div v-if="isBoolean(item[field.key])" style="display: inline">
                            <checkbox-list :value="item[field.key]" />
                        </div>
                        <div v-if="!isBoolean(item[field.key])" style="display: inline">{{ item[field.key] | strippedContent }}</div>
                    </td>
                    <td class="text-right">
                        <div class="btn-group">
                            <button v-if="showPreview" type="submit" class="btn btn-link" @click="$emit('preview', item.id)">
                                <font-awesome-icon :icon="['far', 'search']" />
                                <span class="hidden-xs hidden-sm"> {{ $t('entity.action.preview') }}</span>
                            </button>
                            <router-link v-if="showEdit" tag="button" :to="{ name: routeTo, params: { slug: item.id } }" type="submit" class="btn btn-link">
                                <font-awesome-icon :icon="['fad', 'pencil-alt']" />
                                <span class="hidden-xs hidden-sm"> {{ $t('entity.action.edit') }}</span>
                            </router-link>
                            <button v-if="showDelete" type="submit" class="btn btn-link" @click="$emit('deleted', item.id)">
                                <font-awesome-icon :icon="['fad', 'trash-alt']" />
                                <span class="hidden-xs hidden-sm"> {{ $t('entity.action.delete') }}</span>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import * as _ from 'lodash';
import sanitizeHtml from 'sanitize-html';

export default {
    name: 'DataTable',
    filters: {
        strippedContent: function (string) {
            if (typeof string == 'number') {
                return string;
            } else {
                if (string && string.length > 0) {
                    const regex = /(<([^>]+)>)/gi;
                    return string.replace(regex, '');
                } else {
                    return '';
                }
            }
        },
    },
    props: {
        items: {
            type: Array,
            default: null,
        },
        fields: {
            type: Array,
            default: null,
        },
        sortField: {
            type: String,
            default: 'id',
        },
        routeTo: {
            type: String,
            default: '',
        },
        totalPages: {
            type: Number,
            default: 0,
        },
        showPreview: {
            type: Boolean,
            default: false,
        },
        showEdit: {
            type: Boolean,
            default: true,
        },
        showDelete: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        page: function () {
            return this.routeTo ? this.$store.getters[this.routeTo + '/currentPage'] : {};
        },
        currentSort: function () {
            return this.routeTo ? this.$store.getters[this.routeTo + '/currentSort'] : {};
        },
        currentSortDir: function () {
            return this.routeTo ? this.$store.getters[this.routeTo + '/currentSortDir'] : {};
        },
        sortedItems: function () {
            return _.orderBy(
                this.items,
                [
                    (item) =>
                        item[this.currentSort] != null && (typeof item[this.currentSort] === 'string' || item[this.currentSort] instanceof String)
                            ? item[this.currentSort].toLowerCase()
                            : item[this.currentSort],
                ],
                this.currentSortDir
            );
        },
    },
    methods: {
        isBoolean(value) {
            return _.isBoolean(value);
        },
        sort(key) {
            if (key === this.currentSort) {
                this.$store.dispatch(this.routeTo + '/currentSortDir', this.currentSortDir === 'asc' ? 'desc' : 'asc');
            }
            this.$store.dispatch(this.routeTo + '/currentSort', key);

            this.loadData();
        },
        loadData() {
            let params = {
                page: this.page,
                size: 20,
                sort: this.currentSort + ',' + this.currentSortDir,
            };

            this.$store.dispatch(this.routeTo + '/query', { params });
        },
        sanitizeHtml: sanitizeHtml,
    },
};
</script>
