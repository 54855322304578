import _ from 'lodash';
export const kundemixin = {
    methods: {
        getKundenBezeichnungOrName(kunde) {
            if (!kunde) {
                return '';
            }
            if (kunde.firma === 'privatperson') {
                if (kunde.geschlecht === 'herr' || kunde.geschlecht === 'frau' || kunde.geschlecht === 'familie') {
                    if (kunde.vorname != null) {
                        return _.capitalize(kunde.geschlecht) + ' ' + kunde.vorname + ' ' + kunde.nachname;
                    }
                    return _.capitalize(kunde.geschlecht) + ' ' + kunde.nachname;
                } else {
                    return kunde.bezeichnung;
                }
            }
            return kunde.bezeichnung;
        },
        getKundenAdresseLand(kundenadresse) {
            if (kundenadresse && kundenadresse.land && kundenadresse.land.land) {
                return kundenadresse.land.land;
            }
            return '';
        }
    },
};
