import ApiService from '@/common/api.service';

const resource = 'bestellungsbelege';

const BestellungsBelegService = {
    async query(params, kundenIds, status, showDrafts) {
        let url = resource;

        if (kundenIds) {
            url += '/' + kundenIds;
        } else {
            url += '/all';
        }

        if (status) {
            url += '/' + status;
        } else {
            url += '/all';
        }

        if (showDrafts) {
            url += '/true';
        } else {
            url += '/false';
        }

        return ApiService.query(url, params);
    },
};

export default BestellungsBelegService;
