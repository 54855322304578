import Vue from 'vue';
import KundeService from './kunde.service';
import * as _ from 'lodash';
import abstractStore from '../../store/abstractstore';
import { ADRESSEN_TYP } from '@/constants';

export const getDefaultSort = () => ({ currentSort: 'bezeichnung', currentSortDir: 'asc' });

const getDefaultItem = () => ({
    id: null,
    kundenNummer: null,
    bezeichnung: null,
    vorname: null,
    nachname: null,
    geschlecht: null,
    adresseLists: [
        {
            adressZusatz: null,
            plz: null,
            ort: null,
            strasse: null,
            land: null,
            adressenTyp: ADRESSEN_TYP.RECHNUNGSADRESSE,
        },
    ],
    email: null,
    telnr: null,
    innergemeinschaftlich: null,
    export: null,
    preisschiene: null,
    rabattVorschlag: 0,
    zahlungsziel: null,
    belegVorlageVerwenden: true,
    belegLangKey: 'de',
    firma: null,
    user: null,
    zuHandenAktiv: false,
    lieferant: false,
    kundeFelder: [],
    fahrzeuge: [],
    kundePdfs: [],
    kundeCrms: [],
    kontonummer: null,
    kundeBeschreibung: null,
    kundeKontakte: [],
});

const getDefaultState = () => ({
    searchTerm: '',
    item: getDefaultItem(),
    list: [],
    allLands: [],
    allPreisschienes: [],
    kundeFelder: [],
    nextKundenNummer: null,
    selectedLieferant: false,
    ...abstractStore.getAbstractValue(getDefaultSort().currentSort, getDefaultSort().currentSortDir),
});

const kunde = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        searchTerm(state, data) {
            Vue.set(state, 'searchTerm', data);
        },
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        updateNextKundennumber(state, data) {
            Vue.set(state, 'nextKundenNummer', data);
        },
        selectedLieferant(state, data) {
            Vue.set(state, 'selectedLieferant', data);
        },
        clear(state, selectedMandant) {
            Vue.set(state, 'item', getDefaultState().item);
            if (selectedMandant && selectedMandant.adresseLists[0] != null) {
                Vue.set(state.item.adresseLists[0], 'land', selectedMandant.adresseLists[0].land);
            }
            Vue.set(state, 'list', getDefaultState().list);
        },
        updateAllLands(state, data) {
            state.allLands = data;
        },
        updateAllPreisschienes(state, data) {
            state.allPreisschienes = data;
        },
    },

    actions: {
        clear({ commit, rootState }) {
            commit('clear', rootState.mandant.selectedMandant);
        },
        async create(context, client_data) {
            const { data } = await KundeService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await KundeService.update(client_data.id, client_data, true);
            context.commit('update', data);
            return data;
        },
        async updateNoToast(context, client_data) {
            const { data } = await KundeService.update(client_data.id, client_data, false);
            context.commit('update', data);
            return data;
        },
        async updateList(context, client_data) {
            const { data } = await KundeService.updateList(client_data);
            context.commit('updateList', data);
            return data;
        },
        async getAllLands(state) {
            const { data } = await KundeService.getAllLands();
            state.commit('updateAllLands', data);
            return data;
        },
        async getAllPreisschienes(state) {
            const { data } = await KundeService.getAllPreisschienes();
            state.commit('updateAllPreisschienes', data);
            return data;
        },
        async getNextNumber(context) {
            const { data } = await KundeService.getNextNumber();
            context.commit('updateNextKundennumber', data);
            return data;
        },
        async get(context, id) {
            const { data } = await KundeService.get(id);
            if (data.adresseLists.length === 0) {
                data.adresseLists.push(getDefaultItem().adresseLists[0]);
            }
            context.commit('update', data);
            return data;
        },
        async getByKnr(context, knr) {
            const { data } = await KundeService.getByKnr(knr);
            context.commit('update', data);
            return data;
        },
        async getLandByLaenderkuerzel(context, laenderKuerzel) {
            const { data } = await KundeService.getLandByLaenderkuerzel(laenderKuerzel);
            return data;
        },
        async queryAll(context, params) {
            const { data, headers } = await KundeService.queryAll(params);
            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let size = params && params.params && params.params.size ? params.params.size : 20;
            let totalCount = headers['x-total-count'];
            let totalPages = 1;

            if (_.toNumber(totalCount) > 0 && _.toNumber(size) > 0) {
                totalPages = _.toNumber(totalCount) / _.toNumber(size);
            }

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async query(context, params) {
            if (context.state.searchTerm.length > 0) {
                params.params.search = context.state.searchTerm;
            }
            if (context.state.selectedLieferant) {
                params.params.lieferant = context.state.selectedLieferant;
            }

            const { data, headers } = await KundeService.query(params);
            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let size = params && params.params && params.params.size ? params.params.size : 20;
            let totalCount = headers['x-total-count'];
            let totalPages = 1;

            if (_.toNumber(totalCount) > 0 && _.toNumber(size) > 0) {
                totalPages = _.toNumber(totalCount) / _.toNumber(size);
            }

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            await KundeService.delete(slug);
        },
        selectedLieferant(context, param) {
            context.commit('selectedLieferant', param);
        },
        searchTerm(context, searchTerm) {
            context.commit('searchTerm', searchTerm);
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        data_rechnungsadress(state) {
            let rechnungsadresse = null;

            if (state.item != null && Array.isArray(state.item.adresseLists) && state.item.adresseLists.length > 0) {
                for (var i = 0; i < state.item.adresseLists.length; i++) {
                    if (state.item.adresseLists[i].adressenTyp === ADRESSEN_TYP.RECHNUNGSADRESSE || state.item.adresseLists[i].adressenTyp == null) {
                        rechnungsadresse = state.item.adresseLists[i];
                        break;
                    }
                }
            }
            return rechnungsadresse;
        },
        data_lieferAdressList(state) {
            let lieferadressen = [];
            if (state.item != null && Array.isArray(state.item.adresseLists) && state.item.adresseLists.length > 0) {
                for (var i = 0; i < state.item.adresseLists.length; i++) {
                    if (state.item.adresseLists[i].adressenTyp === ADRESSEN_TYP.LIEFERADRESSE && state.item.adresseLists[i].aktiv) {
                        lieferadressen.push(state.item.adresseLists[i]);
                    }
                }
            }
            return lieferadressen;
        },
        list(state) {
            return state.list;
        },
        allLands(state) {
            return state.allLands;
        },
        allPreisschienes(state) {
            return state.allPreisschienes;
        },
        selectedLieferant(state) {
            return state.selectedLieferant;
        },
        searchTerm(state) {
            return state.searchTerm;
        },
    },
};

export default _.merge(kunde, abstractStore.getDefaultStore());
