/**
 * Every route becomes a chunk, loaded only when used.
 * Reduces size of initial App load.
 */
const routes = [
    {
        name: 'login',
        path: '/login',
        component: () => import(/* webpackChunkName: "login" */ '@/app/login/login.vue'),
        title: 'Login',
        isPublic: true,
    },
    {
        name: 'validate2fa',
        path: '/validate2fa',
        component: () => import(/* webpackChunkName: "validate2fa" */ '@/app/login/validate2fa.vue'),
        title: '2-Faktor-Auth',
        isPublic: true,
    },
    {
        name: 'disable2fa',
        path: '/disable2fa/:key',
        component: () => import(/* webpackChunkName: "disable2fa" */ '@/app/account/settings/disable2fa.vue'),
        title: 'Disable 2-Faktor-Auth',
        isPublic: true,
    },
    {
        name: 'home',
        path: '/',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/app/home/home.vue'),
        title: 'Home',
        isPublic: false,
    },
    {
        name: 'settings',
        path: '/settings/:slug',
        component: () => import(/* webpackChunkName: "settings" */ '@/app/account/settings/settings.vue'),
        title: 'Settings',
        isPublic: false,
    },
    {
        name: 'belegtext_list',
        path: '/belegtext_list/',
        component: () => import(/* webpackChunkName: "belegtext_list" */ '@/app/belegtext/belegtext_list.vue'),
        title: 'Belegtexte',
        isPublic: false,
    },
    {
        name: 'belegtext',
        path: '/belegtext/:slug',
        component: () => import(/* webpackChunkName: "belegtext" */ '@/app/belegtext/belegtext.vue'),
        title: 'Belegtext',
        isPublic: false,
    },
    {
        name: 'belegfelddefinition_list',
        path: '/belegfelddefinition_list/',
        component: () => import(/* webpackChunkName: "belegfelddefinition_list" */ '@/app/belegfelddefinition/belegfelddefinition_list.vue'),
        title: 'Belegfelder',
        isPublic: false,
    },
    {
        name: 'artikelfelddefinition_list',
        path: '/artikelfelddefinition_list/',
        component: () => import(/* webpackChunkName: "artikelfelddefinition_list" */ '@/app/artikelfelddefinition/artikelfelddefinition_list.vue'),
        title: 'Artikelfelder',
        isPublic: false,
    },
    {
        name: 'artikelfelddefinition',
        path: '/artikelfelddefinition/',
        component: () => import(/* webpackChunkName: "artikelfelddefinition" */ '@/app/artikelfelddefinition/artikelfelddefinition.vue'),
        title: 'Artikelfeld',
        isPublic: false,
    },
    {
        name: 'belegfelddefinition',
        path: '/belegfelddefinition/:slug',
        component: () => import(/* webpackChunkName: "belegfelddefinition" */ '@/app/belegfelddefinition/belegfelddefinition.vue'),
        title: 'Belegfeld',
        isPublic: false,
    },

    {
        name: 'mandant_list',
        path: '/mandant_list/',
        component: () => import(/* webpackChunkName: "mandant_list" */ '@/app/mandant/mandant_list.vue'),
        title: 'Mandanten',
        isPublic: false,
    },
    {
        name: 'benachrichtigungen_list',
        path: '/benachrichtigungen_list/',
        component: () => import(/* webpackChunkName: "mandant_list" */ '@/app/benachrichtigungen/benachrichtigungen_list.vue'),
        title: 'Benachrichtigungen',
        isPublic: false,
    },
    {
        name: 'benachrichtigungen',
        path: '/benachrichtigungen/',
        component: () => import(/* webpackChunkName: "mandant_list" */ '@/app/benachrichtigungen/benachrichtigungen.vue'),
        title: 'Benachrichtigungen',
        isPublic: false,
    },
    {
        name: 'mandant',
        path: '/mandant/:slug',
        component: () => import(/* webpackChunkName: "mandant" */ '@/app/mandant/mandant.vue'),
        title: 'Mandant',
        isPublic: false,
    },
    {
        name: 'warengruppe_list',
        path: '/warengruppe_list/',
        component: () => import(/* webpackChunkName: "warengruppe_list" */ '@/app/warengruppe/warengruppe_list.vue'),
        title: 'Warengruppen',
        isPublic: false,
    },
    {
        name: 'warengruppe',
        path: '/warengruppe/:slug',
        component: () => import(/* webpackChunkName: "warengruppe" */ '@/app/warengruppe/warengruppe.vue'),
        title: 'Warengruppe',
        isPublic: false,
    },
    {
        name: 'untergruppe_list',
        path: '/untergruppe_list/',
        component: () => import(/* webpackChunkName: "untergruppe_list" */ '@/app/untergruppe/untergruppe_list.vue'),
        title: 'Untergruppen',
        isPublic: false,
    },
    {
        name: 'untergruppe',
        path: '/untergruppe/:slug',
        component: () => import(/* webpackChunkName: "untergruppe" */ '@/app/untergruppe/untergruppe.vue'),
        title: 'Untergruppe',
        isPublic: false,
    },
    {
        name: 'wizard',
        path: '/mandant/:slug',
        component: () => import(/* webpackChunkName: "mandant" */ '@/app/mandant/wizard.vue'),
        title: 'Mandant',
        isPublic: false,
    },
    {
        name: 'kunde_list',
        path: '/kunde_list/',
        component: () => import(/* webpackChunkName: "kunde_list" */ '@/app/kunde/kunde_list.vue'),
        title: 'Kunden',
        isPublic: false,
    },
    {
        name: 'kunde',
        path: '/kunde/:slug',
        component: () => import(/* webpackChunkName: "kunde" */ '@/app/kunde/kunde.vue'),
        title: 'Kunde',
        isPublic: false,
    },
    {
        name: 'kundefelddefinition_list',
        path: '/kundefelddefinition_list/',
        component: () => import(/* webpackChunkName: "kunde" */ '@/app/kundefelddefinition/kundefelddefinition_list'),
        title: 'Kunde',
        isPublic: false,
    },
    {
        name: 'kundefelddefinition',
        path: '/kundefelddefinition/',
        component: () => import(/* webpackChunkName: "kunde" */ '@/app/kundefelddefinition/kundefelddefinition'),
        title: 'Kunde',
        isPublic: false,
    },
    {
        name: 'firmengruppe',
        path: '/firmengruppe',
        component: () => import(/* webpackChunkName: "kunde" */ '@/app/firmengruppe/firmengruppe.vue'),
        title: 'Firmengruppe',
        isPublic: false,
    },
    {
        name: 'firmengruppe_list',
        path: '/firmengruppe_list',
        component: () => import(/* webpackChunkName: "kunde" */ '@/app/firmengruppe/firmengruppe_list.vue'),
        title: 'Firmengruppe',
        isPublic: false,
    },
    {
        name: 'artikel_list',
        path: '/artikel_list/',
        component: () => import(/* webpackChunkName: "artikel_list" */ '@/app/artikel/artikel_list.vue'),
        title: 'Artikel',
        isPublic: false,
    },
    {
        name: 'artikelkatalog',
        path: '/artikelkatalog/',
        component: () => import(/* webpackChunkName: "artikelkatalog" */ '@/app/artikel/artikelkatalog.vue'),
        title: 'Artikel',
        isPublic: false,
    },
    {
        name: 'artikel',
        path: '/artikel/:slug',
        component: () => import(/* webpackChunkName: "artikel" */ '@/app/artikel/artikel.vue'),
        title: 'Artikel',
        isPublic: false,
    },
    {
        name: 'artikel',
        path: '/artikel/:slug/:duplicate',
        component: () => import(/* webpackChunkName: "artikel" */ '@/app/artikel/artikel.vue'),
        title: 'Artikel',
        isPublic: false,
    },
    {
        name: 'beleg_list',
        path: '/beleg_list/',
        component: () => import(/* webpackChunkName: "beleg_list" */ '@/app/beleg/beleg_list.vue'),
        title: 'Belege',
        isPublic: false,
    },
    {
        name: 'beleg',
        path: '/beleg/:slug/edit/:edit/:duplicate',
        component: () => import(/* webpackChunkName: "beleg" */ '@/app/beleg/beleg.vue'),
        title: 'Belege',
        isPublic: false,
    },
    {
        name: 'projekt_list',
        path: '/projekte',
        component: () => import(/* webpackChunkName: "projekt_list" */ '@/app/projekt/projekt_list.vue'),
        title: 'Projekte',
        isPublic: false,
    },
    {
        name: 'projekt',
        path: '/projekte/:slug',
        component: () => import(/* webpackChunkName: "projekt" */ '@/app/projekt/projekt.vue'),
        title: 'Projekt',
        isPublic: false,
    },
    {
        name: 'mehrwertssteuer_list',
        path: '/mehrwertssteuer_list',
        component: () => import(/* webpackChunkName: "mehrwertssteuer_list" */ '@/app/mehrwertssteuer/mehrwertssteuer_list.vue'),
        title: 'Mehrwertssteuern',
        isPublic: false,
    },
    {
        name: 'mehrwertssteuer',
        path: '/mehrwertssteuer/:slug',
        component: () => import(/* webpackChunkName: "mehrwertssteuer" */ '@/app/mehrwertssteuer/mehrwertssteuer.vue'),
        title: 'Mehrwertssteuer',
        isPublic: false,
    },
    {
        name: 'preisschiene_list',
        path: '/preisschiene_list/',
        component: () => import(/* webpackChunkName: "preisschiene_list" */ '@/app/preisschiene/preisschiene_list.vue'),
        title: 'Preisschienen',
        isPublic: false,
    },
    {
        name: 'preisschiene',
        path: '/preisschiene/:slug',
        component: () => import(/* webpackChunkName: "preisschiene" */ '@/app/preisschiene/preisschiene.vue'),
        title: 'Preisschiene',
        isPublic: false,
    },
    {
        name: 'zahlung_list',
        path: '/zahlung_list/',
        component: () => import(/* webpackChunkName: "zahlung_list" */ '@/app/zahlung/zahlung_list.vue'),
        title: 'Zahlung',
        isPublic: false,
    },
    {
        name: 'zahlung_report',
        path: '/zahlung_report/',
        component: () => import(/* webpackChunkName: "zahlung_report" */ '@/app/zahlung/zahlung_report.vue'),
        title: 'Zahlung Report',
        isPublic: false,
    },
    {
        name: 'kassabuch_list',
        path: '/kassabuch_list/',
        component: () => import(/* webpackChunkName: "kassabuch_list" */ '@/app/kassabuch/kassabuch_list.vue'),
        title: 'Kassabuch',
        isPublic: false,
    },
    {
        name: 'zahlungsziel_list',
        path: '/zahlungsziel_list/',
        component: () => import(/* webpackChunkName: "zahlungsziel_list" */ '@/app/zahlungsziel/zahlungsziel_list.vue'),
        title: 'Zahlungsziels',
        isPublic: false,
    },
    {
        name: 'zahlungsziel',
        path: '/zahlungsziel/:slug',
        component: () => import(/* webpackChunkName: "zahlungsziel" */ '@/app/zahlungsziel/zahlungsziel.vue'),
        title: 'Zahlungsziel',
        isPublic: false,
    },
    {
        name: 'lieferbedingung_list',
        path: '/lieferbedingung/',
        component: () => import(/* webpackChunkName: "lieferbedingung_list" */ '@/app/lieferbedingung/lieferbedingung_list.vue'),
        title: 'Lieferbedingungen',
        isPublic: false,
    },
    {
        name: 'lieferbedingung',
        path: '/lieferbedingung/:slug',
        component: () => import(/* webpackChunkName: "lieferbedingung" */ '@/app/lieferbedingung/lieferbedingung.vue'),
        title: 'Lieferbedingung',
        isPublic: false,
    },
    {
        name: 'zahlungsbedingung_list',
        path: '/zahlungsbedingung/',
        component: () => import(/* webpackChunkName: "zahlungsbedingung_list" */ '@/app/zahlungsbedingung/zahlungsbedingung_list.vue'),
        title: 'Zahlungsbedingungen',
        isPublic: false,
    },
    {
        name: 'zahlungsbedingung',
        path: '/zahlungsbedingung/:slug',
        component: () => import(/* webpackChunkName: "zahlungsbedingung" */ '@/app/zahlungsbedingung/zahlungsbedingung.vue'),
        title: 'Zahlungsbedingung',
        isPublic: false,
    },
    {
        name: 'rabatt_list',
        path: '/rabatt_list',
        component: () => import(/* webpackChunkName: "rabatt_list" */ '@/app/rabatt/rabatt_list.vue'),
        title: 'Rabatte',
        isPublic: false,
    },
    {
        name: 'rabatt',
        path: '/rabatt/:slug',
        component: () => import(/* webpackChunkName: "rabatt" */ '@/app/rabatt/rabatt.vue'),
        title: 'Rabatt',
        isPublic: false,
    },
    {
        name: 'preiskalkulator',
        path: '/preiskalkulator',
        component: () => import(/* webpackChunkName: "rabatt" */ '@/app/preiskalkulator/preiskalkulator.vue'),
        title: 'Preiskalkulator',
        isPublic: false,
    },
    {
        name: 'auswertung',
        path: '/auswertung',
        component: () => import(/* webpackChunkName: "auswertung" */ '@/app/auswertung/auswertung.vue'),
        title: 'Auswertung',
        isPublic: false,
    },
    {
        name: 'changepassword',
        path: '/changepassword/:slug',
        component: () => import(/* webpackChunkName: "changepassword" */ '@/app/account/settings/password.vue'),
        title: 'Passwort',
        isPublic: false,
    },
    {
        name: 'passwordreset',
        path: '/passwordreset',
        component: () => import(/* webpackChunkName: "passwordreset" */ '@/app/account/settings/passwordreset.vue'),
        title: 'Account',
        isPublic: true,
    },
    {
        name: 'passwordresetfinish',
        path: '/reset/finish',
        component: () => import(/* webpackChunkName: "passwordresetfinish" */ '@/app/account/settings/passwordreset_finish.vue'),
        title: 'Account',
        isPublic: true,
    },
    {
        name: 'rechnungsvorlage_list',
        path: '/invoicetemplate',
        component: () => import(/* webpackChunkName: "rechnungsvorlage_list" */ '@/app/rechnungsvorlage/rechnungsvorlage_list.vue'),
        title: 'Rechnungsvorlagen',
        isPublic: true,
    },
    {
        name: 'rechnungsvorlage',
        path: '/invoicetemplate/:slug',
        component: () => import(/* webpackChunkName: "rechnungsvorlage" */ '@/app/rechnungsvorlage/rechnungsvorlage.vue'),
        title: 'Rechnungsvorlage',
        isPublic: true,
    },
    {
        name: 'user_list',
        path: '/user_list/',
        component: () => import(/* webpackChunkName: "user_list" */ '@/app/admin/user/user_list.vue'),
        title: 'Benutzer',
        isPublic: false,
    },
    {
        name: 'user',
        path: '/user/:slug',
        component: () => import(/* webpackChunkName: "user" */ '@/app/admin/user/user.vue'),
        title: 'Benutzer',
        isPublic: false,
    },
    {
        name: 'module_list',
        path: '/module_list/',
        component: () => import(/* webpackChunkName: "user_list" */ '@/app/admin/module/module_list.vue'),
        title: 'Module',
        isPublic: false,
    },
    {
        name: 'module',
        path: '/module/:slug',
        component: () => import(/* webpackChunkName: "user" */ '@/app/admin/module/module.vue'),
        title: 'Module',
        isPublic: false,
    },
    {
        name: 'group_list',
        path: '/group_list/',
        component: () => import(/* webpackChunkName: "group_list" */ '@/app/group/group_list.vue'),
        title: 'Gruppen',
        isPublic: false,
    },
    {
        name: 'group',
        path: '/group/:slug',
        component: () => import(/* webpackChunkName: "group" */ '@/app/group/group.vue'),
        title: 'Gruppe',
        isPublic: false,
    },
    {
        name: 'rechnungskreise_list',
        path: '/rechnungskreise_list/',
        component: () => import(/* webpackChunkName: "user_list" */ '@/app/admin/rechnungskreise/rechnungskreise_list.vue'),
        title: 'Rechnungskreise',
        isPublic: false,
    },
    {
        name: 'rechnungskreise',
        path: '/rechnungskreise/:slug',
        component: () => import(/* webpackChunkName: "user" */ '@/app/admin/rechnungskreise/rechnungskreise.vue'),
        title: 'Rechnungskreise',
        isPublic: false,
    },
    {
        name: 'belegnummerkreise_list',
        path: '/belegnummerkreise_list/',
        component: () => import(/* webpackChunkName: "belegnummerkreise_list" */ '@/app/admin/belegnummerkreise/belegnummerkreise_list.vue'),
        title: 'Belegnummernkreise',
        isPublic: false,
    },
    {
        name: 'belegnummerkreis',
        path: '/belegnummerkreis/',
        component: () => import(/* webpackChunkName: "belegnummerkreis" */ '@/app/admin/belegnummerkreise/belegnummerkreis.vue'),
        title: 'Belegnummernkreis',
        isPublic: false,
    },
    {
        name: 'register',
        path: '/register',
        component: () => import(/* webpackChunkName: "register" */ '@/app/register/register.vue'),
        title: 'Registrierung',
        isPublic: true,
    },
    {
        name: 'lagerplaetze_list',
        path: '/lagerplaetze_list/',
        component: () => import(/* webpackChunkName: "register" */ '@/app/lager/lagerplaetze/lagerplaetze_list.vue'),
        title: 'Lagerplätze',
        isPublic: false,
    },
    {
        name: 'lagerplaetze',
        path: '/lagerplaetze/:slug',
        component: () => import(/* webpackChunkName: "register" */ '@/app/lager/lagerplaetze/lagerplaetze.vue'),
        title: 'Lagerplätze',
        isPublic: false,
    },
    {
        name: 'einauslagerung_list',
        path: '/einauslagerung_list/',
        component: () => import(/* webpackChunkName: "register" */ '@/app/lager/einauslagerung/einauslagerung_list.vue'),
        title: 'Ein/Auslagerung',
        isPublic: false,
    },
    {
        name: 'einauslagerung',
        path: '/einauslagerung/:slug',
        component: () => import(/* webpackChunkName: "register" */ '@/app/lager/einauslagerung/einauslagerung.vue'),
        title: 'Ein/Auslagerung',
        isPublic: false,
    },
    {
        name: 'activate',
        path: '/activate/:slug',
        component: () => import(/* webpackChunkName: "register" */ '@/app/activate/activate.vue'),
        title: 'Benutzer freischalten',
        isPublic: true,
    },
    {
        name: 'rksv',
        path: '/rksv',
        component: () => import(/* webpackChunkName: "rksv" */ '@/app/account/settings/rksv.vue'),
        title: 'RKSV',
        isPublic: false,
    },
    {
        name: 'bestellung_list',
        path: '/bestellung_list/',
        component: () => import(/* webpackChunkName: "bestellung_list" */ '@/app/bestellung/bestellung_list.vue'),
        title: 'Bestellungen',
        isPublic: false,
    },
    {
        name: 'bestellung',
        path: '/bestellung/:slug',
        component: () => import(/* webpackChunkName: "bestellung" */ '@/app/bestellung/bestellung.vue'),
        title: 'Bestellung',
        isPublic: false,
    },
    {
        name: 'zeiterfassung',
        path: '/zeiterfassung',
        component: () => import(/* webpackChunkName: "zeiterfassung" */ '@/app/zeiterfassung/zeiterfassung.vue'),
        title: 'zeiterfassung',
        isPublic: false,
    },
    {
        name: 'zeiterfassung_report',
        path: '/zeiterfassung_report',
        component: () => import(/* webpackChunkName: "zeiterfassung_report" */ '@/app/zeitreport/reportOverview.vue'),
        title: 'Zeiterfassung Report',
        isPublic: false,
    },
    {
        name: 'zeiterfassung_mitarbeiter',
        path: '/zeiterfassung_mitarbeiter',
        component: () => import(/* webpackChunkName: "zeiterfassung_mitarbeiter" */ '@/app/zeiterfassung_mitarbeiter/zeiterfassung_mitarbeiter.vue'),
        title: 'zeiterfassung_mitarbeiter',
        isPublic: false,
    },
    {
        name: 'zem_settings_list',
        path: '/zem_settings_list',
        component: () => import(/* webpackChunkName: "zem_settings_list" */ '@/app/zeiterfassung_mitarbeiter_settings/zem_settings_list.vue'),
        title: 'zem_settings_list',
        isPublic: false,
    },
    {
        name: 'verleih',
        path: '/verleih',
        component: () => import(/* webpackChunkName: "verleih" */ '@/app/verleih/verleih.vue'),
        title: 'verleih',
        isPublic: false,
    },
    {
        name: 'verleih_list',
        path: '/verleih_list',
        component: () => import(/* webpackChunkName: "verleih_list" */ '@/app/verleih/verleih_list.vue'),
        title: 'Übersicht Verleihe',
        isPublic: false,
    },
    {
        name: 'verleih_artikel',
        path: '/verleih_artikel',
        component: () => import(/* webpackChunkName: "verleih_artikel" */ '@/app/verleih/verleih_artikel.vue'),
        title: 'Übersicht Artikel',
        isPublic: false,
    },
    {
        name: 'content_list',
        path: '/content_list/:folder',
        component: () => import(/* webpackChunkName: "content_list" */ '@/app/content/content_list.vue'),
        title: 'Übersicht Dokumente',
        isPublic: false,
    },
    {
        name: 'content',
        path: '/content/:folder/:contentId',
        component: () => import(/* webpackChunkName: "content" */ '@/app/content/content.vue'),
        title: 'Dokument',
        isPublic: false,
    },
    {
        name: 'datamigration',
        path: '/datamigration',
        component: () => import(/* webpackChunkName: "content" */ '@/app/datamigration/datamigration.vue'),
        title: 'Datamigration',
        isPublic: false,
    },
    // {
    //     name: 'updates',
    //     path: '/updates',
    //     component: () => import(/* webpackChunkName: "content" */ '@/app/updates/updatescreen.vue'),
    //     title: 'Updates',
    //     isPublic: false,
    // },
    // {
    //     name: 'uidcheck',
    //     path: '/uid',
    //     component: () => import(/* webpackChunkName: "content" */ '@/app/uidCheck/uid_check.vue'),
    //     title: 'UIDCheck',
    //     isPublic: false,
    // },
    // {
    //     name: 'temporary',
    //     path: '/temporary',
    //     component: () => import(/* webpackChunkName: "content" */ '@/app/uidCheck/temporary.vue'),
    //     title: 'Datamigration',
    //     isPublic: false,
    // },
    {
        name: 'orders_list',
        path: '/webshop',
        component: () => import(/* webpackChunkName: "content" */ '@/app/webshop/orders_list.vue'),
        title: 'Übersicht Webshop',
        isPublic: false,
    },
    {
        name: 'webshop_category_list',
        path: '/webshop_category',
        component: () => import(/* webpackChunkName: "content" */ '@/app/webshop/category_list.vue'),
        title: 'Übersicht Webshop Kategorien',
        isPublic: false,
    },
    {
        name: 'webshop_category_item',
        path: '/webshop_category_item/:slug',
        component: () => import(/* webpackChunkName: "content" */ '@/app/webshop/category_item.vue'),
        title: 'Webshop Kategorie',
        isPublic: false,
    },
    {
        name: 'quartalskalender_list',
        path: '/quartalskalender_list',
        component: () => import(/* webpackChunkName: "content" */ '@/app/quartalskalender/quartalskalender_list.vue'),
        title: 'Übersicht Quartalskalender',
        isPublic: false,
    },
    {
        name: 'quartalskalender',
        path: '/quartalskalender/:slug',
        component: () => import(/* webpackChunkName: "content" */ '@/app/quartalskalender/quartalskalender.vue'),
        title: 'Quartalskalender',
        isPublic: false,
    },
    {
        name: 'QuartalskalenderCreate',
        path: '/quartalskalender_create',
        component: () => import(/* webpackChunkName: "content" */ '@/app/quartalskalender/quartalskalender_create.vue'),
        title: 'Quartalskalender erstellen',
        isPublic: false,
    },
];

export default routes;
