import $ from 'jquery';

const VueTooltipDirective = {
    name: 'tooltip',
    bind: function bsTooltipCreate(el, binding) {
        $(el).tooltip({
            title: binding.value,
            placement: binding.arg,
        });
    },
};

// eslint-disable-next-line no-unused-vars
const VueTooltip = {
    install(Vue) {
        Vue.directive(VueTooltipDirective.name, VueTooltipDirective);
    },
};

if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(VueTooltip);
}

export default VueTooltip;

export { VueTooltip };
