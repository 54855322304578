// Testing
export const DEBUG = true;

// Backend API endpoints
export const API_BASE_URL = '/api';
export const REFRESH_TOKEN_URL = '/auth';

/**
 * Key for local storage.
 *
 * Set the key to use in local storage to hold persistant data. If logged in,
 * you can see this key by going to Chrome > dev tools > application tab,
 * then choosing "Local Storage" and "http://localhost:8080".
 *
 * @type {string}
 */
export const STORAGE_KEY = 'hoerbUI';

export const LANGUAGES = ['de', 'en'];
export const PAGINATION_CLASSES = {
    ul: 'pagination justify-content-center',
    li: 'page-item',
    liActive: 'active',
    liDisable: 'disabled',
    button: 'page-link',
};
export const LIGHT_PAGINATION_CLASSES = {
    ul: 'pagination justify-content-end',
    li: 'page-item-light',
    liActive: 'active',
    liDisable: 'disabled',
    button: 'page-link-light',
};

export const DEBOUNCE_DEFAULT_MILLIS = 500;

export const ADRESSEN_TYP = {
    RECHNUNGSADRESSE: 'rechnungsadresse',
    LIEFERADRESSE: 'lieferadresse',
};
