import Vue from 'vue';
import EinauslagerungService from './einauslagerung.service';
import * as _ from 'lodash';

const getDefaultItem = () => ({
    id: null,
    mandant: null,
    bezeichnung: '',
    kunde: null,
    lagerplatz: {
        id: null,
        code: '',
        bezeichnung: '',
        mandant: null,
    },
    artikel: null,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
    currentPage: 0,
    totalPages: 1,
});

const einauslagerung = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state) {
            Vue.set(state, 'item', getDefaultState().item);
            Vue.set(state, 'list', getDefaultState().list);
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            const { data } = await EinauslagerungService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await EinauslagerungService.update(client_data.id, client_data);
            context.commit('update', data);
            return data;
        },
        async get(context, id) {
            const { data } = await EinauslagerungService.get(id);
            context.commit('update', data);
            return data;
        },
        async query(context, params) {
            const { headers, data } = await EinauslagerungService.query(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let size = params && params.params && params.params.size ? params.params.size : 20;
            let totalCount = headers['x-total-count'];
            let totalPages = 1;

            if (_.toNumber(totalCount) > 0 && _.toNumber(size) > 0) {
                totalPages = _.toNumber(totalCount) / _.toNumber(size);
            }

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            await EinauslagerungService.delete(slug);
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
    },
};

export default einauslagerung;
