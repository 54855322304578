import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'contentfiles';

const ContentfileService = {
    async queryRoot() {
        return ApiService.get('contentFileInFolder');
    },
    async queryByParentFolder(parentfolder) {
        return ApiService.get('contentFileInFolder', parentfolder);
    },
    async create(parentfolder, folderName) {
        let promise = null;
        try {
            let newFolder = { folderName };

            if (parentfolder != null) {
                newFolder.parent = parentfolder;
            }

            promise = await ApiService.put(resource, newFolder);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
};

export default ContentfileService;
