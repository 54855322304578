import ApiService from '@/common/api.service';

const resource = 'artikelkatalogsearch';

const ArtikelKatalogService = {
    async query(params) {
        return ApiService.query(resource, params);
    },
};

export default ArtikelKatalogService;
