import Vue from 'vue';
import ZeitReportService from './zeitreport.service';
import moment from 'moment';

const getDefaultItem = () => ({
    kundeReport: null,
    userReport: null,
    userWorkReport: null,
    projektReport: null,
    zeiteintragList: null,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
});

const zeitreport = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        clear(state) {
            Vue.set(state, 'item', getDefaultState().item);
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async queryReport(context, params) {
            const { data } = await ZeitReportService.queryReportData(params);
            if (data.kundeReport && data.kundeReport.length > 0) {
                data.kundeReport.forEach((kunde) => {
                    if (kunde.projektReport && kunde.projektReport.length > 0) {
                        kunde.projektReport.forEach((projekt) => {
                            projekt.participatingUsers = [];
                            if (projekt.zeiteintragList && projekt.zeiteintragList.length > 0) {
                                projekt.zeiteintragList.forEach((zeiteintrag) => {
                                    if (zeiteintrag.user) {
                                        const user = projekt.participatingUsers.find((u) => u.id === zeiteintrag.user.id);
                                        if (!user) {
                                            zeiteintrag.user.timeSpend = 0;
                                            if (zeiteintrag.von && zeiteintrag.bis) {
                                                let duration = moment.duration(moment(zeiteintrag.bis).diff(moment(zeiteintrag.von)));
                                                zeiteintrag.user.timeSpend = duration;
                                            }
                                            projekt.participatingUsers.push(zeiteintrag.user);
                                        } else {
                                            if (zeiteintrag.von && zeiteintrag.bis) {
                                                let duration = moment.duration(moment(zeiteintrag.bis).diff(moment(zeiteintrag.von)));
                                                user.timeSpend = user.timeSpend.add(duration);
                                            }
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
            }
            context.commit('update', data);
            return data;
        },
        async queryUserReport(context, params) {
            const { data } = await ZeitReportService.queryUserReportData(params);
            context.commit('update', data);
            return data;
        },
        async queryWorkUserReport(context, params) {
            const { data } = await ZeitReportService.queryWorkUserReportData(params);
            context.commit('update', data);
            return data;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
    },
};

export default zeitreport;
