import Vue from 'vue';
import BelegService from './beleg.service';
import * as _ from 'lodash';
import BestellungsBelegService from './bestellungsbeleg.service';
import abstractStore from '../../store/abstractstore';
import ApiService from '@/common/api.service';

export const getDefaultSort = () => ({ currentSort: 'belegDatum,belegNummer', currentSortDir: 'desc' });

const getDefaultsPosition = () => ({
    artikel: {},
    artikelRabatt: 0,
    id: null,
    positionsBeschreibung: '',
    positionsBeschreibungZusatz: '',
    positionsMenge: 1,
    positionsMwst: 0,
    positionsNummer: 1,
    positionsPreis: 0.0,
    positionsSumme: 0.0,
    positionsVersandMenge: 0.0,
    ship_amount: 0.0,
    ship_position: 0.0,
    stillOpenAmount: 0.0,
    teilversandFinalize: false,
    orderKey: null,
    printAddText: false,
    rabatt: null,
    zeiteintraege: [],
    belegspositionLieferant: null,
});

const getDefaultItem = () => ({
    id: null,
    mandant: {
        bezeichnung: null,
        adresseLists: [
            {
                land: {},
            },
        ],
    },
    belegsTyp: null,
    summeBrutto: 0.0,
    summeNetto: 0.0,
    kunde: {},
    belegMwstSet: [],
    rechnungsText: '',
    freigabe: false,
    rechnungsDokument: null,
    rechnungsDokumentContentType: null,
    belegspositionSet: [getDefaultsPosition()],
    rabatt: null,
    belegRabatt: null,
    bankomat: null,
    belegFelder: [],
    zuHanden: null,
    projektList: [],
    zahlungsziel: null,
    belegstext2: null,
    belegstext3: null,
    lieferbedingung: null,
    zahlungsbedingung: null,
    fahrzeug: [],
    adresse: null,
});

const getDefaultSelectedKunde = () => ({});
const getDefaultSelectedArtikel = () => ({});
const getDefaultSelectedBeleg = () => ({});

const getDefaultState = () => ({
    item: getDefaultItem(),
    itemBeforeDuplicate: getDefaultItem(),
    list: [],
    selectedKundenId: null,
    selectedBelegtyp: null,
    selectedProjekt: null,
    selectedKunde: getDefaultSelectedKunde(),
    selectedArtikel: getDefaultSelectedArtikel(),
    selectedBeleg: getDefaultSelectedBeleg(),
    kennzeichenSearchTerm: null,
    displayToast: true,
    ...abstractStore.getAbstractValue(getDefaultSort().currentSort, getDefaultSort().currentSortDir),
});

const beleg = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateItemBeforeDuplicate(state, data) {
            Vue.set(state, 'itemBeforeDuplicate', data);
        },
        selectedKundenId(state, data) {
            state.selectedKundenId = data;
        },
        selectedBelegtyp(state, data) {
            state.selectedBelegtyp = data;
        },
        selectedProjekt(state, data) {
            state.selectedProjekt = data;
        },
        selectedKunde(state, data) {
            state.selectedKunde = data;
        },
        selectedArtikel(state, data) {
            state.selectedArtikel = data;
        },
        selectedBeleg(state, data) {
            state.selectedBeleg = data;
        },
        kennzeichenSearchTerm(state, data) {
            state.kennzeichenSearchTerm = data;
        },
        addPosition(state, data) {
            if (data == null) data = getDefaultsPosition();

            let maxPos = Math.max.apply(
                Math,
                state.item.belegspositionSet.map(function (o) {
                    return o.positionsNummer;
                })
            );
            if (maxPos < 1) maxPos = 0;

            if (!data.positionsNummer || data.positionsNummer <= maxPos) data.positionsNummer = maxPos + 1;
            data.orderKey = data.positionsNummer;
            state.item.belegspositionSet.push(data);
        },
        updateBelegtext2(state, data) {
            Vue.set(state.item, 'belegstext2', data);
        },
        updateBelegtext3(state, data) {
            Vue.set(state.item, 'belegstext3', data);
        },
        delPosition(state, data) {
            let deleteCurrent = false;

            for (let index = 0; index < state.item.belegspositionSet.length; ++index) {
                let pos = state.item.belegspositionSet[index];
                if (data == null && pos == null) {
                    deleteCurrent = true;
                } else if (data.id != null) {
                    if (data.id == pos.id) deleteCurrent = true;
                } else {
                    if (data.positionsNummer == pos.positionsNummer) deleteCurrent = true;
                }

                if (deleteCurrent) {
                    state.item.belegspositionSet.splice(index, 1);
                    break;
                }
            }

            //if (deleteCurrent) calculate new sums ...
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state, data) {
            Vue.set(state, 'item', getDefaultState().item);

            let localStorageBelegKeyName = 'belegsTyp' + data.id;
            if (localStorage.getItem(localStorageBelegKeyName) == null) {
                localStorage.setItem(localStorageBelegKeyName, 'rechnung');
            }
            let belegsTyp = localStorage.getItem(localStorageBelegKeyName);

            Vue.set(state.item, 'belegsTyp', belegsTyp);
            Vue.set(state.item, 'mandant', data);
            Vue.set(state, 'list', getDefaultState().list);
        },
        clearOrder(state, data) {
            Vue.set(state, 'item', getDefaultState().item);
            Vue.set(state.item, 'belegsTyp', 'bestellung');
            Vue.set(state.item, 'mandant', data);
            Vue.set(state, 'list', getDefaultState().list);
        },
        clearFilter(state) {
            Vue.set(state, 'selectedKundenId', getDefaultState().selectedKundenId);
            Vue.set(state, 'selectedBelegtyp', getDefaultState().selectedBelegtyp);
            Vue.set(state, 'selectedProjekt', getDefaultState().selectedProjekt);
            Vue.set(state, 'selectedKunde', getDefaultState().selectedKunde);
            Vue.set(state, 'selectedArtikel', getDefaultState().selectedArtikel);
            Vue.set(state, 'selectedBeleg', getDefaultState().selectedBeleg);
            Vue.set(state, 'currentPage', getDefaultState().currentPage);
            Vue.set(state, 'kennzeichenSearchTerm', getDefaultState().kennzeichenSearchTerm);
        },
        clearFilterProjekt(state) {
            Vue.set(state, 'selectedProjekt', getDefaultState().selectedProjekt);
        },
    },

    actions: {
        clear({ commit, rootState }) {
            commit('clear', rootState.mandant.selectedMandant);
        },
        clearOrder({ commit, rootState }) {
            commit('clearOrder', rootState.mandant.selectedMandant);
        },
        clearFilter({ commit }) {
            commit('clearFilter');
        },
        clearFilterProjekt({ commit }) {
            commit('clearFilterProjekt');
        },
        updateList(context, item) {
            context.commit('updateList', item);
        },
        selectedKundenId(context, kundenId) {
            context.commit('selectedKundenId', kundenId);
        },
        selectedBelegtyp(context, belegTyp) {
            context.commit('selectedBelegtyp', belegTyp);
        },
        selectedProjekt(context, projekt) {
            context.commit('selectedProjekt', projekt);
        },
        selectedKunde(context, kundenId) {
            context.commit('selectedKunde', kundenId);
        },
        selectedArtikel(context, artikel) {
            context.commit('selectedArtikel', artikel);
        },
        selectedBeleg(context, beleg) {
            context.commit('selectedBeleg', beleg);
        },
        kennzeichenSearchTerm(context, beleg) {
            context.commit('kennzeichenSearchTerm', beleg);
        },
        updateItem(context, data) {
            context.commit('update', data);
        },
        addPosition(context, position) {
            if (position == null) position = getDefaultsPosition();
            context.commit('addPosition', position);
        },
        delPosition(context, position) {
            context.commit('delPosition', position);
        },
        async create(context, client_data) {
            const { data } = await BelegService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await BelegService.update(client_data.id, client_data);
            context.commit('update', data);
            return data;
        },
        async get(context, id) {
            const { data } = await BelegService.get(id);
            data.belegspositionSet.forEach((value) => {
                value.orderKey = value.positionsNummer;
            });
            context.commit('update', data);
            return data;
        },
        async duplicate(context, originalId) {
            const { data } = await BelegService.get(originalId);
            context.commit('updateItemBeforeDuplicate', JSON.parse(JSON.stringify(data)));
            data.id = null;
            data.belegspositionSet.forEach(function (belegsposition, index, belegsPositionen) {
                belegsPositionen[index].id = null;
                belegsPositionen[index].orderKey = belegsPositionen[index].positionsNummer;
                if (belegsposition.belegspositionLieferant) {
                    belegsposition.belegspositionLieferant.id = null;
                }
            });
            data.belegMwstSet.forEach(function (belegsposition, index, belegsPositionen) {
                belegsPositionen[index].id = null;
            });
            data.belegNummer = null;
            data.bezahlt = false;
            data.stornoRechnungsnummer = null;
            data.bestellBelegnummer = null;
            data.storniert = false;
            data.freigabe = false;
            data.rksvBarcode = null;
            data.docPath = null;
            delete data.zahlungList;
            context.commit('update', data);
            return data;
        },
        async query(context, params) {
            const { data, headers } = await BelegService.query(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let size = params && params.params && params.params.size ? params.params.size : 20;
            let totalCount = headers['x-total-count'];
            let totalPages = 1;

            if (_.toNumber(totalCount) > 0 && _.toNumber(size) > 0) {
                totalPages = _.toNumber(totalCount) / _.toNumber(size);
            }

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async belegSearch(context, params) {
            const { data, headers } = await BelegService.belegSearch(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let size = params && params.params && params.params.size ? params.params.size : 20;
            let totalCount = headers['x-total-count'];
            let totalPages = 1;

            if (_.toNumber(totalCount) > 0 && _.toNumber(size) > 0) {
                totalPages = _.toNumber(totalCount) / _.toNumber(size);
            }

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async queryBestellbeleg(context, client_data) {
            const { data, headers } = await BestellungsBelegService.query(client_data.params, client_data.kundeIds, client_data.status, client_data.showDrafts);

            let currPage = client_data.params.params && client_data.params.params && client_data.params.params.page ? client_data.params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let size = client_data.params.params && client_data.params.params && client_data.params.params.size ? client_data.params.params.size : 20;
            let totalCount = headers['x-total-count'];
            let totalPages = 1;

            if (_.toNumber(totalCount) > 0 && _.toNumber(size) > 0) {
                totalPages = _.toNumber(totalCount) / _.toNumber(size);
            }

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async updateBelegtext2(context, client_data) {
            const { data } = await ApiService.getQuery('belegtext2', client_data.zahlungszielId, { params: client_data.params });
            context.commit('updateBelegtext2', data.belegtext2);
            return data;
        },
        async updateBelegtext3(context, client_data) {
            const { data } = await ApiService.getQuery('belegtext3', client_data.originalBelegId, { params: client_data.params });
            context.commit('updateBelegtext3', data.belegtext3);
            return data.belegtext3;
        },
        async delete(context, slug) {
            await BelegService.delete(slug);
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        dataBeforeDuplicate(state) {
            return state.itemBeforeDuplicate;
        },
        selectedKundenId(state) {
            return state.selectedKundenId;
        },
        selectedBelegtyp(state) {
            return state.selectedBelegtyp;
        },
        selectedProjekt(state) {
            return state.selectedProjekt;
        },
        selectedKunde(state) {
            return state.selectedKunde;
        },
        selectedArtikel(state) {
            return state.selectedArtikel;
        },
        selectedBeleg(state) {
            return state.selectedBeleg;
        },
        kennzeichenSearchTerm(state) {
            return state.kennzeichenSearchTerm;
        },
        list(state) {
            return state.list;
        },
    },
};

export default _.merge(beleg, abstractStore.getDefaultStore());
