import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'account';
const resourceChangePassword = 'account/change_password';
const resourceResetPassword = 'account/reset_password/init';
const resourceResetPasswordFinish = 'account/reset_password/finish';

const AccountService = {
    get(slug) {
        //account without id
        let params = { slug };
        return ApiService.query(resource, params);
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.post(resource, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async changePassword(params) {
        let promise = null;
        try {
            promise = await ApiService.changeResetPasswort(resourceChangePassword, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async resetPassword(params) {
        let promise = null;
        try {
            promise = await ApiService.changeResetPasswort(resourceResetPassword, params);
            Toast.showSaveOkToastI18n('global.messages.toast.email_sent');
        } catch (e) {
            if (e.response.data === 'e-mail address not registered') {
                Toast.showSaveErrorToastI18n('global.messages.toast.email_not_registered');
            } else {
                Toast.showSaveErrorToastI18n();
            }
        }
        return promise;
    },
    async resetPasswordFinish(params) {
        let promise = null;
        try {
            promise = await ApiService.post(resourceResetPasswordFinish, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    update(slug, params) {
        return ApiService.update(resource, slug, params);
    },
};

export default AccountService;
