import Vue from 'vue';
import BelegtypGuiService from './belegtypGui.service';

const getDefaultItem = () => ({
    id: null,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
});

const belegtypGui = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state) {
            Vue.set(state, 'item', getDefaultState().item);
            Vue.set(state, 'list', getDefaultState().list);
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            const { data } = await BelegtypGuiService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await BelegtypGuiService.update(client_data.id, client_data);
            context.commit('update', data);
            return data;
        },
        async get(context, id) {
            const { data } = await BelegtypGuiService.get(id);
            context.commit('update', data);
            return data;
        },
        async query(context, params) {
            const { data } = await BelegtypGuiService.query(params);
            const belegtypen = data;
            belegtypen.sort((a, b) => {
                let compare = 0;
                if (a > b) {
                    compare = 1;
                } else if (b > a) {
                    compare = -1;
                }
                return compare;
            });
            context.commit('updateList', belegtypen);
            return belegtypen;
        },
        async delete(context, slug) {
            await BelegtypGuiService.delete(slug);
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
    },
};

export default belegtypGui;
