import './assets/styles/main_legacy.css';
import './assets/styles/hoe-theme-color.css';
import './assets/styles/hoe.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/main.css';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '@fortawesome/fontawesome-pro/js/all';
import 'vue-search-select/dist/VueSearchSelect.css';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import store from './store';
import App from './App.vue';
import { sync } from 'vuex-router-sync';
import router from './common/router';
import i18n from './i18n';
import $i18n from './i18n';
import ApiService from './common/api.service';
import Toast from './common/toast';
import land from '@/components/land';
import kundesearch from '@/components/kundesearch';
import lieferantsearch from '@/components/kundesearch';
import artikelsearch from '@/components/artikelsearch';
import benutzersearch from '@/components/benutzersearch';
import belegsearch from '@/components/belegsearch';
import firmengruppesearch from '@/components/firmengruppesearch';
import artikelbilder from '@/components/artikelbilder';
import datatable from '@/components/datatable';
import sorticon from '@/components/sorticon';
import passwordcomp from '@/components/password';
import kundenschnellanlage from '@/components/kundenschnellanlage';
import vue2Dropzone from 'vue2-dropzone';
import artikelschnellanlage from '@/components/artikelschnellanlage';
import artikeldetailsuche from '@/components/artikeldetailsuche';
import artikelvorschau from '@/components/artikelvorschau';
import belegtextmodal from '@/components/belegtextmodal';
import currency from '@/components/currency';
import decimal from '@/components/decimal';
import checkboxlist from '@/components/checkboxlist';
import validation from '@/components/validation';
import projektsearch from '@/components/projektsearch';
import multiprojektsearch from '@/components/multiprojektsearch.vue';
import kontaktsearch from '@/components/kontaktsearch';
import adressesearch from '@/components/adressesearch';
import activatesuccess from '@/components/activatesuccess';
import lieferadresseschnellanlage from '@/components/lieferadresseschnellanlage';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faAddressCard,
    faAlignJustify,
    faAlignLeft,
    faAngleUp,
    faArchive,
    faArrowLeft,
    faArrowRight,
    faBan,
    faBook,
    faBullseyeArrow,
    faCalculator,
    faCartArrowDown,
    faChartBar,
    faCheck,
    faCheckSquare,
    faClock,
    faCog,
    faCogs,
    faCoins,
    faCopy,
    faDollyFlatbed,
    faDollyFlatbedEmpty,
    faDotCircle,
    faEnvelope,
    faFileAlt,
    faFileImage,
    faFileInvoiceDollar,
    faFilePdf,
    faFilePlus,
    faFileSearch,
    faFileSignature,
    faFileTimes,
    faFlagCheckered,
    faFolderOpen,
    faIdBadge,
    faImages,
    faIndustry,
    faInfoCircle,
    faInventory,
    faKey,
    faLayerGroup,
    faMoneyBill,
    faMoneyBillAlt,
    faObjectGroup,
    faPencilAlt,
    faPlayCircle,
    faPlus,
    faProjectDiagram,
    faRedo,
    faSave,
    faShippingFast,
    faSignIn,
    faSignOut,
    faSortDown,
    faSortUp,
    faSpinnerThird,
    faSquare,
    faSquareFull,
    faSync,
    faTachometerAltFast,
    faTags,
    faTasks,
    faTh,
    faTools,
    faTrashAlt,
    faUser,
    faUserCheck,
    faUserPlus,
    faUsersCog,
    faTimes,
    faEye,
    faComment,
    faCalendarCheck,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import { Vue2Dragula } from 'vue2-dragula';
import buttonspinner from '@/components/buttonspinner';
import GlobalDirectives from './directives/index';
import './registerServiceWorker';
import VueBrowserUpdate from '@sum.cumo/vue-browserupdate';
import moment from 'moment';
import 'moment-duration-format';
import { MultiSelect } from 'vue-search-select';
import VueSignature from 'vue-signature-pad';
import VueSignaturePad from 'vue-signature-pad';
import { Tab, Tabs } from 'vue-tabs-component';
import editormenu from '@/components/editormenu';
import qrscan from '@/components/qrscan';
import lieferanteninfo from '@/components/lieferanteninfo';
import updatescreen from '@/app/updates/updatescreen.vue';
import belegtypsearch from '@/components/belegtypsearch.vue';
import multiprojektsearchdeprecated from '@/components/multiprojektsearchdeprecated.vue';
import * as Sentry from '@sentry/vue';
import ConfigService from '@/common/config.service';

library.add(faAlignJustify);
library.add(faPencilAlt);
library.add(faTrashAlt);
library.add(faCopy);
library.add(faFilePdf);
library.add(faPlus);
library.add(faSortDown);
library.add(faSortUp);
library.add(faSave);
library.add(faBan);
library.add(faFileAlt);
library.add(faFolderOpen);
library.add(faFilePlus);
library.add(faFileInvoiceDollar);
library.add(faTools);
library.add(faIndustry);
library.add(faAddressCard);
library.add(faTags);
library.add(faFileImage);
library.add(faAlignLeft);
library.add(faCalculator);
library.add(faMoneyBill);
library.add(faMoneyBillAlt);
library.add(faBook);
library.add(faChartBar);
library.add(faBullseyeArrow);
library.add(faClock);
library.add(faArchive);
library.add(faCartArrowDown);
library.add(faCog);
library.add(faCogs);
library.add(faUsersCog);
library.add(faTh);
library.add(faTasks);
library.add(faInfoCircle);
library.add(faDotCircle);
library.add(faCoins);
library.add(faFileTimes);
library.add(faCheck);
library.add(faRedo);
library.add(faArrowLeft);
library.add(faArrowRight);
library.add(faUser);
library.add(faUserPlus);
library.add(faUserCheck);
library.add(faSignIn);
library.add(faSignOut);
library.add(faTachometerAltFast);
library.add(faKey);
library.add(faIdBadge);
library.add(faPlayCircle);
library.add(faAngleUp);
library.add(faFlagCheckered);
library.add(faProjectDiagram);
library.add(faInventory);
library.add(faDollyFlatbed);
library.add(faDollyFlatbedEmpty);
library.add(faFileSignature);
library.add(faCheckSquare);
library.add(faSquare);
library.add(faSquareFull);
library.add(faShippingFast);
library.add(faSync);
library.add(faLayerGroup);
library.add(faObjectGroup);
library.add(faFileSearch);
library.add(faEnvelope);
library.add(faImages);
library.add(faSpinnerThird);
library.add(faTimes);
library.add(faComment);
library.add(faEye);
library.add(faCalendarCheck);

Vue.component('TabsComponent', Tabs);
Vue.component('TabComponent', Tab);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('VSelect', vSelect);
Vue.component('LandComponent', land);
Vue.component('KundeSearch', kundesearch);
Vue.component('AdresseSearch', adressesearch);
Vue.component('LieferantSearch', lieferantsearch);
Vue.component('FirmengruppeSearch', firmengruppesearch);
Vue.component('BelegSearch', belegsearch);
Vue.component('KundenSchnellAnlage', kundenschnellanlage);
Vue.component('LieferadresseSchnellanlage', lieferadresseschnellanlage);
Vue.component('ArtikelSchnellAnlage', artikelschnellanlage);
Vue.component('ArtikelDetailSuche', artikeldetailsuche);
Vue.component('QrScan', qrscan);
Vue.component('ArtikelVorschau', artikelvorschau);
Vue.component('ArtikelSearch', artikelsearch);
Vue.component('BenutzerSearch', benutzersearch);
Vue.component('BelegtypSearch', belegtypsearch);
Vue.component('ArtikelBilder', artikelbilder);
Vue.component('PasswordComponent', passwordcomp);
Vue.component('BelegtextModal', belegtextmodal);
Vue.component('ButtonSpinner', buttonspinner);
Vue.component('DataTable', datatable);
Vue.component('SortIcon', sorticon);
Vue.component('CurrencyInput', currency);
Vue.component('DecimalInput', decimal);
Vue.component('CheckboxList', checkboxlist);
Vue.component('ValidationComponent', validation);
Vue.component('ProjektSearch', projektsearch);
Vue.component('MultiProjektSearch', multiprojektsearch);
Vue.component('MultiProjektSearchDeprecated', multiprojektsearchdeprecated);
Vue.component('KontaktSearch', kontaktsearch);
Vue.component('ActivateSuccess', activatesuccess);
Vue.component('VueSearchSelect', MultiSelect);
Vue.component('VueSignaturePad', VueSignaturePad);
Vue.component('VueDropzone', vue2Dropzone);
Vue.component('EditorMenu', editormenu);
Vue.component('LieferantenInfo', lieferanteninfo);
Vue.component('UpdatesComponent', updatescreen);

Vue.config.productionTip = false;

// Http and Auth plugins
// Vue.use(http);
// Vue.use(auth);

ApiService.init();
// Sync router to store, as `store.state.route`.
sync(store, router);

Vue.use(Vuelidate);
Vue.use(VueLodash, { lodash });
Vue.use(VuejsDialog);
Vue.use(Vue2Dragula);
Vue.use(GlobalDirectives);
Vue.use(Loading);
Vue.use(VueSignature);
Vue.use(VueBrowserUpdate, {
    options: {
        required: { e: -1, f: -3, o: -2, s: -1, c: -3 },
        insecure: true,
        unsupported: true,
        mobile: false,
        style: 'bottom',
        reminder: 0,
        reminderClosed: 2,
        no_permanent_hide: true,
    },
});
Vue.browserUpdate.onshow(() => {});
Toast.init();

let configStage = ConfigService.getConfigIfExists('stage');
if (configStage && configStage !== 'local') {
    Sentry.init({
        Vue,
        dsn: 'https://29c920c974e1af247e8d77444a56170f@o4507095116283904.ingest.de.sentry.io/4507100558590032',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['https://dev.hoerb.at', 'https://hoerb.at'],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

        environment: ConfigService.getConfigIfExists('stage'),
    });
}

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');

Vue.filter('readableDate', (value) => {
    if (value) {
        return moment(value).format('DD.MM.YYYY');
    }
    return '';
});
Vue.filter('readableDateTime', (value) => {
    if (value) {
        return moment(value).format('DD.MM.YYYY HH:mm');
    }
    return '';
});
Vue.filter('readableDateIncludingDay', (value) => {
    if (value) {
        moment.locale($i18n.locale);
        return moment(value).format('dd, DD.MM.YYYY');
    }
    return '';
});
Vue.filter('readableTime', (value) => {
    if (value) {
        return moment(value).format('HH:mm');
    }
    return '';
});
Vue.filter('readableDuration', (seconds) =>
    moment.duration(seconds, 'seconds').format('h:mm', {
        trim: false,
    })
);
Vue.filter('readableDurationMoment', (duration) =>
    duration.format('HH:mm', {
        trim: false,
    })
);
Vue.filter('readableDurationReport', (seconds) =>
    moment.duration(seconds, 'seconds').format('HH:mm', {
        trim: false,
    })
);

Vue.filter('readableDateDateShort', (value) => {
    if (value) {
        moment.locale($i18n.locale);
        return moment(value).format('DD.MM');
    }
    return '';
});

Vue.filter('readableDateWeekday', (value) => {
    if (value) {
        moment.locale($i18n.locale);
        return moment(value).format('dddd');
    }
    return '';
});
