<template>
    <div>
        <div class="form-row">
            <multiselect
                v-model="displayList"
                :multiple="true"
                :options="projekteSorted"
                :searchable="true"
                :close-on-select="false"
                :placeholder="placeholder"
                :selected-label="''"
                :deselect-label="''"
                :select-label="''"
                :disabled="disabled"
                label="bezeichnung"
                track-by="id"
                @select="onSelect"
                @remove="onRemove"
            >
                <template slot="tag" slot-scope="{ option }"
                    ><span class="custom__tag">{{ option.bezeichnung }}</span></template
                >
                <template slot="option" slot-scope="{ option }">
                    <div class="option__desc">
                        <span class="option__title">{{ option.bezeichnung }}</span>
                    </div>
                </template>
                <template slot="noResult">
                    <div class="option__desc">
                        <span class="option__title">{{ $t('noresult') }}</span>
                    </div>
                </template>
            </multiselect>
        </div>
    </div>
</template>

<script>
import { Multiselect } from 'vue-multiselect';
import { kundemixin } from './mixins/kundemixin';
import _ from 'lodash';

const { mapGetters } = require('vuex');

export default {
    name: 'MultiProjektSearchDeprecated',
    components: { Multiselect },
    mixins: [kundemixin],
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            displayList: this.list,
        };
    },
    computed: {
        ...mapGetters({
            projekte: 'projekt/list',
        }),
        projekteSorted: {
            get() {
                const ordered = _.orderBy(this.projekte, ['geloescht', 'bezeichnung'], ['asc', 'asc']);
                ordered.forEach((p) => {
                    this.fetchKundeBezeichnungAndProjektStatus(p);
                });
                return ordered;
            },
        },
    },
    mounted() {
        this.$store.dispatch('projekt/queryAll', { params: { includeDeactivated: true } });
        this.displayList.forEach((p) => {
            this.fetchKundeBezeichnungAndProjektStatus(p);
        });
    },
    methods: {
        fetchKundeBezeichnungAndProjektStatus(p) {
            const kundeBezeichnung = p.kunde ? kundemixin.methods.getKundenBezeichnungOrName(p.kunde) : '';
            if (kundeBezeichnung !== '') {
                p.bezeichnung = p.bezeichnung + ' | ' + kundeBezeichnung;
            }
            if (p.geloescht) {
                p.bezeichnung += ' - (' + this.$t('deactivated') + ')';
            }
            return p;
        },
        onChange(val) {
            this.$emit('input', val);
        },
        onSelect(val) {
            setTimeout(
                function () {
                    this.$emit('update:list', this.displayList);
                }.bind(this),
                0
            );
            this.$emit('select', val);
        },
        onRemove(val) {
            setTimeout(
                function () {
                    this.$emit('update:list', this.displayList);
                }.bind(this),
                0
            );
            this.$emit('remove', val);
        },
    },
};
</script>

<style scoped></style>

<i18n>
{
    "de": {
        "placeholder": "Schnellsuche: Projekt",
        "deactivated": "deaktiviert",
        "noresult": "Keine Projekte gefunden"
    },
    "en": {
        "placeholder": "Fast search: project",
        "deactivated": "deaktiviert",
        "noresult": "No projects found"
    }
}
</i18n>
