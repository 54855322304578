<template>
    <div style="display: inline">
        <font-awesome-icon v-if="value" :icon="['fa', 'check']" style="--fa-primary-color: white" size="lg" />
        <font-awesome-icon v-if="!value" :icon="['fa', 'ban']" style="--fa-primary-color: white" size="lg" />
    </div>
</template>

<script>
export default {
    name: 'CheckboxList',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
