<template>
    <v-select :value="value" label="land" :filterable="false" :options="options" @input="onChange" @search="onSearch">
        <template slot="no-options">
            {{ $t('placeholder') }}
        </template>
    </v-select>
</template>

<script>
import _ from 'lodash';
import ApiService from '@/common/api.service';
import { DEBOUNCE_DEFAULT_MILLIS } from '@/constants';

export default {
    name: 'LandComponent',
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            options: [],
        };
    },
    methods: {
        onChange(val) {
            this.$emit('input', val);
        },
        onSearch(search, loading) {
            loading(true);
            if (search.length > 0) {
                this.search(loading, search, this);
            } else {
                loading(false);
            }
        },
        search: _.debounce((loading, search, vm) => {
            const resource = 'landsearch';
            ApiService.query(resource, { params: { search: search } }).then((res) => {
                vm.options = res.data;
                loading(false);
            });
        }, DEBOUNCE_DEFAULT_MILLIS),
    },
};
</script>

<style scoped></style>

<i18n>
    {
        "de": {
            "placeholder": "Nach Land suchen"
        },
        "en": {
            "placeholder": "Search for a land"
        }
    }
</i18n>
