<template>
    <nav class="navbar navbar-expand-lg navbar-light hoerb-header-bg">
        <button
            class="navbar-toggler ml-auto"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <span class="navbar-toggler-icon"></span>
        </button>
        <div id="navbarSupportedContent" class="collapse navbar-collapse w-100">
            <ul class="navbar-nav ml-auto">
                <li v-if="isAuthenticated && newsavailable" class="nav-item dropdown ml-auto mobile-left">
                    <a id="newsavailable" class="nav-link" href="#" role="button" aria-haspopup="true" aria-expanded="false" @click="showNews()">
                        <span class="fa-stack" style="height: 1em" v-tooltip:top="'Neuigkeiten anzeigen'">
                            <font-awesome-icon :icon="['fad', 'circle']" class="fa-stack-2x icon-background fa-beat" />
                            <font-awesome-icon :icon="['fad', 'gift']" class="fa-stack-1x" style="color: white" />
                        </span>
                    </a>
                </li>
                <li class="nav-item dropdown ml-auto mobile-left">
                    <a
                        id="navbarDropdown"
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <font-awesome-icon v-if="isAuthenticated" :icon="['fad', 'user-check']" />
                        <font-awesome-icon v-if="!isAuthenticated" :icon="['fad', 'user']" />
                        <span v-if="isAuthenticated">&nbsp;{{ friendlyUsername }}</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                        <router-link v-if="isAuthenticated" :to="{ name: 'settings', params: { slug: userId } }" class="dropdown-item">
                            <font-awesome-icon :icon="['fad', 'id-badge']" fixed-width />&nbsp;
                            <span>{{ $t('settings') }}</span>
                        </router-link>

                        <router-link v-if="isAuthenticated && hasModuleRKSV" :to="{ name: 'rksv' }" class="dropdown-item">
                            <font-awesome-icon :icon="['fad', 'tools']" fixed-width />&nbsp;
                            <span>{{ $t('rksv') }}</span>
                        </router-link>

                        <router-link v-if="isAuthenticated" :to="{ name: 'changepassword', params: { slug: userId } }" class="dropdown-item">
                            <font-awesome-icon :icon="['fad', 'key']" fixed-width />&nbsp;
                            <span>{{ $t('password') }}</span>
                        </router-link>

                        <div v-if="isAuthenticated" class="dropdown-divider"></div>

                        <a v-if="isAuthenticated" id="logout" class="dropdown-item" @click="logout()">
                            <font-awesome-icon :icon="['fad', 'sign-out']" />&nbsp;
                            <span>{{ $t('logout') }}</span>
                        </a>

                        <router-link v-if="!isAuthenticated" :to="{ name: 'login' }" class="dropdown-item">
                            <font-awesome-icon :icon="['fad', 'sign-in']" />&nbsp;
                            <span>{{ $t('login') }}</span>
                        </router-link>
                        <router-link v-if="!isAuthenticated" :to="{ name: 'register' }" class="dropdown-item">
                            <font-awesome-icon :icon="['fad', 'user-plus']" />&nbsp;
                            <span>{{ $t('register') }}</span>
                        </router-link>
                    </div>
                </li>
                <li v-if="isAuthenticated" class="nav-item dropdown ml-auto mobile-left">
                    <a
                        id="navbarDropdownM"
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <font-awesome-icon :icon="['fad', 'industry']" />&nbsp;
                        <span>{{ selectedMandant.bezeichnung }}</span>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                        <div v-for="mandant in mandanten" :key="mandant.id" class="dropdown-item">
                            <a href="" data-toggle="collapse" data-target=".navbar-collapse.in" class="dropdown-item" @click="changeMandant(mandant)">
                                <span>{{ mandant.bezeichnung }}</span>
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import auth from '../auth/auth.helpers';
import router from '@/common/router';
import JwtService from '@/common/jwt.service';
import { mapGetters } from 'vuex';

export default {
    name: 'NavbarComponent',
    props: {
        msg: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapGetters({
            hasModuleRKSV: 'mandant/selectedHasModuleRKSV',
            newsavailable: 'benachrichtigungen/news',
            benachrichtigungen: 'benachrichtigungen/list',
        }),
        friendlyUsername() {
            if (this.$store.state.auth.friendlyUsername == undefined || this.$store.state.auth.friendlyUsername.trim() == 'undefined') {
                this.logoutAndLogin();
            }
            return this.$store.state.auth.friendlyUsername;
        },
        userId() {
            return this.$store.state.auth.userId;
        },
        selectedMandant() {
            return this.$store.state.mandant.selectedMandant;
        },
        mandanten() {
            return this.$store.state.mandant.mandanten;
        },
        isAuthenticated() {
            return this.$store.state.auth.isLoggedIn;
        },
    },

    mounted() {
        let lastmandant = {};
        lastmandant.id = JwtService.getLastSelectedMandantID();
        if (lastmandant.id == null) lastmandant = null;
        if(this.isAuthenticated){
            this.$store.dispatch('mandant/loadAllandUpdateSelected', lastmandant);
        }
    },

    methods: {
        showNews() {
            this.$emit('showNews');
        },
        logoutAndLogin() {
            auth.logout();
            router.push({ name: 'login' }).catch(() => {});
        },

        logout: function () {
            auth.logout();
        },

        initializeStore: function () {
            this.$store.dispatch('beleg/clear');
            this.$store.dispatch('kunde/clear');
            this.$store.dispatch('artikel/clear');
            this.$store.dispatch('beleg/clearFilter');
            this.$store.dispatch('artikelkatalog/clearComplete');
            this.$store.dispatch('favorites/clear');
        },

        changeMandant: function (mandant) {
            this.initializeStore();
            this.$store.dispatch('mandant/selectMandant', mandant).then(() => {
                this.$store.dispatch('belegfelddefinition/query');
                router.push({ name: 'home' }).catch(() => {});
            });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<!-- language=json -->
<i18n>
{
    "de": {
        "main": "Zugang",
        "settings": "Einstellungen",
        "rksv": "RKSV",
        "password": "Passwort",
        "sessions": "Sitzungen",
        "login": "Anmelden",
        "logout": "Abmelden",
        "register": "Registrierung"
    },
    "en": {
        "main": "Account",
        "settings": "Settings",
        "rksv": "RKSV",
        "password": "Password",
        "sessions": "Sessions",
        "login": "Sign in",
        "logout": "Sign out",
        "register": "Register"
    }
}

</i18n>
<style>
.fa-beat {
    animation: fa-beat 5s ease infinite;
}

@keyframes fa-beat {
    0% {
        transform: scale(1);
    }
    5% {
        transform: scale(1.25);
    }
    20% {
        transform: scale(1);
    }
    30% {
        transform: scale(1);
    }
    35% {
        transform: scale(1.25);
    }
    50% {
        transform: scale(1);
    }
    55% {
        transform: scale(1.25);
    }
    70% {
        transform: scale(1);
    }
}
</style>
