<template>
    <div class="container-fluid">
        <div>
            <h4>{{ $t('home.title') }}</h4>
            <br />
        </div>

        <div class="shadow-sm p-3 mb-2 mt-2 bg-body rounded">
            <div class="row">
                <div class="col-md-3">
                    <h5>Belege</h5>
                </div>
            </div>
            <div class="row">
                <br />
                <div class="col-md-3"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'UpdatesComponent',
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters({}),
    },
    methods: {},
};
</script>

<style scoped>
.centered {
    text-align: center;
}
</style>

<!-- language=json -->
<i18n>
{
    "de": {
        "home": {
            "title": "Updates"
        },
        "functions": {
            "updateBelege": "Belege aktualisieren"
        },
        "messages": {
        }
    },
    "en": {
        "home": {
            "title": "Updates"
        },
        "functions": {
           "updateBelege": "Update Belege"
        },
        "messages": {
        }
    }
}
</i18n>
