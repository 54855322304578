<template>
    <div>
        <a v-if="single" :class="classStyle" @click="pinToDashboard(params.route, params.params, params.translation)">
            <font-awesome-icon :class="alreadyPinned(params.route)" :icon="['fad', 'thumbtack']" fixed-width />
        </a>

        <div v-if="!single" class="menu-text pin_main">
            <a :class="area" @click="showPinFor(area)" v-tooltip:top="'Pin einblenden / ausblenden'">
                <font-awesome-icon :icon="['fad', 'thumbtack']" fixed-width />
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'FavoritesPin',
    components: {},
    props: {
        area: {
            type: String,
            default: '',
        },
        single: {
            type: Boolean,
            default: true,
        },
        classStyle: {
            type: String,
            default: 'pin hide',
        },
        params: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            favorites: 'favorites/list',
            selectedFavs: 'favorites/selectedFavs',
            selectedMandant: 'mandant/selectedMandant',
        }),
    },
    mounted: function () {
        this.buildPermissions();
    },
    methods: {
        buildPermissions() {
            if (this.params) {
                this.$store.dispatch('favorites/updateFavs', this.params.route);
            }
        },
        showPinFor(elem) {
            document.getElementsByClassName(elem).forEach((x) => {
                if (x.classList.contains('hide')) {
                    x.classList.remove('hide');
                } else {
                    x.classList.add('hide');
                }
            });
        },
        alreadyPinned(route) {
            let className = '';
            let alreadyExists = this.favorites.findIndex((x) => JSON.parse(x.content)['name'] == route);
            if (alreadyExists >= 0) {
                className = 'pinned';
            } else {
                className = 'pinrotate';
            }
            return className;
        },
        pinToDashboard(route, params, translation) {
            let alreadyExists = this.favorites.findIndex((x) => JSON.parse(x.content)['name'] == route);
            if (alreadyExists >= 0) {
                this.deletePin(this.favorites[alreadyExists]);
            } else {
                let obj = { name: route, params: params, translation: translation };
                this.saveFavorite(obj);
            }
        },
        saveFavorite(fav) {
            this.loading = true;
            let fav_tmp = {
                id: null,
                typ: 'fastaccess',
                content: JSON.stringify(fav),
            };

            this.$store.dispatch('favorites/create', fav_tmp).then((result) => {
                this.favorites.push(result);
                this.$store.dispatch('favorites/updateList', this.favorites);
                this.loading = false;
            });
        },
        deletePin(pin) {
            let find = this.favorites.findIndex((x) => JSON.parse(x.content)['name'] == JSON.parse(pin.content)['name']);
            if (find >= 0) {
                this.favorites.splice(find, 1);

                this.$store.dispatch('favorites/delete', pin.id).then(() => {
                    this.$store.dispatch('favorites/updateList', this.favorites);
                });
            }
        },
    },
};
</script>

<style scoped>
.pin {
    padding-right: 13px;
    cursor: pointer;
    /* width: 20%; */
    float: right;
    margin-top: -29px;
    position: relative;
    font-size: 13px;
}

.pin.hide {
    display: none !important;
}

.pin_main {
    cursor: pointer;
    width: 10%;
    float: right;
    margin-top: -20px;
}

.pin_main a:hover {
    box-shadow: none !important;
}

.hoe-minimized-lpanel #hoe-left-panel .panel-list > li > a div.menu-text {
    display: none;
}

.hoe-minimized-lpanel #hoe-left-panel .panel-list > li > div a.single {
    display: none;
}

.hoe-minimized-lpanel #hoe-left-panel .panel-list > li:hover > a div.menu-text,
.hoe-minimized-lpanel #hoe-left-panel .panel-list > li:focus > a div.menu-text {
    display: inline-block;
}

#hoe-left-panel .panel-list > li > a div.menu-text {
    display: inline-block;
}

.hoe-minimized-lpanel #hoe-left-panel .panel-list > li:hover > div a.single,
.hoe-minimized-lpanel #hoe-left-panel .panel-list > li:focus > div a.single {
    display: inline-block;
}

#hoe-left-panel .panel-list > li > div a.single {
    display: inline-block;
}

.pinrotate {
    transform: rotate(47deg);
    color: #000;
}

.pin:hover {
    box-shadow: none;
}

.pinned {
    color: #0069d9;
}

a.pin.single {
    /*padding-right: 3px;
    margin-top: -20px;*/

    position: absolute;
    margin-left: 245px;
}

#hoeapp-container[hoe-color-type='lpanel-bg9'] .pinrotate {
    color: #ffffff;
}

#hoeapp-container[hoe-color-type='lpanel-bg9'] .pin_main a {
    color: #ffffff;
}

#hoeapp-container[hoe-color-type='lpanel-bg9'] .pinned {
    color: #ffffff;
}

#hoeapp-container[hoe-color-type='lpanel-bg8'] .pinrotate {
    color: #ffffff;
}

#hoeapp-container[hoe-color-type='lpanel-bg8'] .pin_main a {
    color: #ffffff;
}

#hoeapp-container[hoe-color-type='lpanel-bg8'] .pinned {
    color: #ffffff;
}
</style>

<!-- language=json -->
<i18n>
{
    "de": {
        "title": "An das Dashboard pinnen"
    },
    "en": {
        "title": "An das Dashboard pinnen"
    }
}
</i18n>
