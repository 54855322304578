<template>
    <div>
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div v-if="mode" class="modal-container">
                        <form name="editForm" role="form" @submit.prevent="createKunde()">
                            <div class="modal-header">
                                <slot name="header">
                                    <h5>{{ $t('new') }}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('close')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </slot>
                            </div>
                            <div class="modal-body">
                                <slot name="body">
                                    <div class="container">
                                        <div class="row form-group">
                                            <div class="col-md-4">
                                                <label>{{ $t('typ') }}</label>
                                                <select v-model="kunde.firma" class="form-control">
                                                    <option value="firma">{{ $t('firma') }}</option>
                                                    <option value="privatperson">{{ $t('person') }}</option>
                                                </select>
                                            </div>
                                            <div class="inner-addon right-addon form-group col-md-4">
                                                <label class="control-label" for="field_kundennummer">{{
                                                    kunde.lieferant ? $t('lieferantenNummer') : $t('kundenNummer')
                                                }}</label>
                                                <font-awesome-icon
                                                    :icon="['fad', 'sync']"
                                                    class="icon"
                                                    v-tooltip:top="'Kundennummer generieren'"
                                                    @click="generateKundenNummer"
                                                />
                                                <input
                                                    id="field_kundennummer"
                                                    v-model="kunde.kundenNummer"
                                                    type="text"
                                                    class="form-control"
                                                    name="kundennummer"
                                                    autocomplete="new-cid"
                                                />
                                            </div>
                                            <div class="col-md-4">
                                                <label>{{ $t('email') }}</label>
                                                <input v-model="kunde.email" type="text" class="form-control" />
                                            </div>
                                        </div>
                                        <div v-if="kunde.firma === 'firma'" class="row form-group">
                                            <div class="col-md-6">
                                                <label>{{ $t('bezeichnung') }}</label>
                                                <input v-model="kunde.bezeichnung" type="text" class="form-control" />
                                                <span v-if="!kunde.bezeichnung" class="red-text">
                                                    {{ $t('entity.validation.required') }}
                                                </span>
                                            </div>
                                            <div class="col-md-6">
                                                <label>{{ $t('uid') }}</label>
                                                <input v-model="kunde.uid" type="text" class="form-control" />
                                            </div>
                                        </div>
                                        <div v-if="kunde.firma === 'privatperson'" class="row form-group">
                                            <div class="col-md-2">
                                                <label>{{ $t('anrede') }}</label>
                                                <select v-model="kunde.geschlecht" class="form-control">
                                                    <option value="herr">{{ $t('herr') }}</option>
                                                    <option value="frau">{{ $t('frau') }}</option>
                                                </select>
                                                <span v-if="!kunde.geschlecht" class="red-text">
                                                    {{ $t('entity.validation.required') }}
                                                </span>
                                            </div>
                                            <div class="col-md-5">
                                                <label>{{ $t('vorname') }}</label>
                                                <input v-model="kunde.vorname" type="text" class="form-control" />
                                                <span v-if="!kunde.vorname" class="red-text">
                                                    {{ $t('entity.validation.required') }}
                                                </span>
                                            </div>
                                            <div class="col-md-5">
                                                <label>{{ $t('nachname') }}</label>
                                                <input v-model="kunde.nachname" type="text" class="form-control" />
                                                <span v-if="!kunde.nachname" class="red-text">
                                                    {{ $t('entity.validation.required') }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div v-if="!addLieferadresse">
                                                <div class="col form-group">
                                                    <h4>Rechnungsadresse</h4>
                                                </div>
                                            </div>
                                            <ul v-if="addLieferadresse" class="nav nav-tabs mt-3" style="width: 100%">
                                                <li class="nav-item">
                                                    <a :class="activeTab === 'billing' ? 'active' : ''" class="nav-link" @click="activeTab = 'billing'"
                                                        >Rechnungsadresse</a
                                                    >
                                                </li>
                                                <li class="nav-item ml-3" v-if="addLieferadresse">
                                                    <a :class="activeTab === 'shipping' ? 'active' : ''" class="nav-link" @click="activeTab = 'shipping'"
                                                        >Lieferadresse
                                                        <span v-if="checkAddLieferadresse" class="red-text"><i class="fa fa-exclamation-circle"></i></span
                                                    ></a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div v-if="activeTab === 'billing'">
                                            <!--<div class="row form-group">
                                                <div class="col-md-6">
                                                    <label class="control-label">{{ $t('adresse.bezeichnung') }}</label>
                                                    <input
                                                        v-model="kunde.adresseLists[0].bezeichnung"
                                                        type="text"
                                                        class="form-control"
                                                        autocomplete="new-street"
                                                    />
                                                </div>
                                                <div class="col-md-6">
                                                    <label>{{ $t('adresse.firmenname') }}</label>
                                                    <input v-model="kunde.adresseLists[0].firmenName" type="text" class="form-control" />
                                                </div>
                                            </div>-->
                                            <div class="row form-group">
                                                <div class="col-md-6">
                                                    <label>{{ $t('land') }}</label>
                                                    <land-component v-model="kunde.adresseLists[0].land" name="land"></land-component>
                                                    <span v-if="!kunde.adresseLists[0].land" class="red-text">
                                                        {{ $t('entity.validation.required') }}
                                                    </span>
                                                </div>
                                                <div class="col-md-2">
                                                    <label>{{ $t('plz') }}</label>
                                                    <input
                                                        id="field_plz"
                                                        v-model="kunde.adresseLists[0].plz"
                                                        type="text"
                                                        class="form-control"
                                                        name="plz"
                                                        autocomplete="new-zipcode"
                                                    />
                                                    <span v-if="!kunde.adresseLists[0].plz" class="red-text">
                                                        {{ $t('entity.validation.required') }}
                                                    </span>
                                                </div>
                                                <div class="col-md-4">
                                                    <label>{{ $t('ort') }}</label>
                                                    <input v-model="kunde.adresseLists[0].ort" type="text" class="form-control" autocomplete="new-city" />
                                                    <span v-if="!kunde.adresseLists[0].ort" class="red-text">
                                                        {{ $t('entity.validation.required') }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row form-group">
                                                <div class="col-md-6">
                                                    <label>{{ $t('strasse') }}</label>
                                                    <input
                                                        v-model="kunde.adresseLists[0].strasse"
                                                        type="text"
                                                        class="form-control"
                                                        autocomplete="new-street"
                                                        required
                                                    />
                                                    <span v-if="!kunde.adresseLists[0].strasse" class="red-text">
                                                        {{ $t('entity.validation.required') }}
                                                    </span>
                                                </div>
                                                <div class="col-md-2">
                                                    <label>{{ $t('hausnummer') }}</label>
                                                    <input
                                                        v-model="kunde.adresseLists[0].hausNummer"
                                                        type="text"
                                                        class="form-control"
                                                        autocomplete="new-housenumber"
                                                    />
                                                </div>
                                                <div class="col-md-2">
                                                    <label>{{ $t('stiege') }}</label>
                                                    <input
                                                        id="field_stiege"
                                                        v-model="kunde.adresseLists[0].stiege"
                                                        type="text"
                                                        class="form-control"
                                                        name="stiege"
                                                    />
                                                </div>
                                                <div class="col-md-2">
                                                    <label>{{ $t('tuer') }}</label>
                                                    <input id="field_tuer" v-model="kunde.adresseLists[0].tuer" type="text" class="form-control" name="tuer" />
                                                </div>
                                            </div>
                                            <div class="row form-group">
                                                <div class="col-md-6">
                                                    <label>{{ $t('adressZusatz') }}</label>
                                                    <input
                                                        id="field_adressZusatz"
                                                        v-model="kunde.adresseLists[0].adressZusatz"
                                                        type="text"
                                                        class="form-control"
                                                        name="adressZusatz"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="addLieferadresse && activeTab === 'shipping'">
                                            <div class="row">
                                                <div class="form-group col-md-6">
                                                    <label class="control-label">{{ $t('adresse.bezeichnung') }}</label>
                                                    <input
                                                        v-model="kunde.adresseLists[1].bezeichnung"
                                                        type="text"
                                                        class="form-control"
                                                        autocomplete="new-street"
                                                    />
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label class="control-label">{{ $t('adresse.firmenname') }}</label>
                                                    <input
                                                        v-model="kunde.adresseLists[1].firmenName"
                                                        type="text"
                                                        class="form-control"
                                                        autocomplete="new-street"
                                                    />
                                                </div>
                                            </div>
                                            <div class="row form-group">
                                                <div class="col-md-6">
                                                    <label>{{ $t('land') }}</label>
                                                    <land-component v-model="kunde.adresseLists[1].land" name="land"></land-component>
                                                    <span v-if="!kunde.adresseLists[1].land" class="red-text">
                                                        {{ $t('entity.validation.required') }}
                                                    </span>
                                                </div>
                                                <div class="col-md-2">
                                                    <label>{{ $t('plz') }}</label>
                                                    <input
                                                        v-model="kunde.adresseLists[1].plz"
                                                        type="text"
                                                        class="form-control"
                                                        name="plz"
                                                        autocomplete="new-zipcode"
                                                    />
                                                    <span v-if="!kunde.adresseLists[1].plz" class="red-text">
                                                        {{ $t('entity.validation.required') }}
                                                    </span>
                                                </div>
                                                <div class="col-md-4">
                                                    <label>{{ $t('ort') }}</label>
                                                    <input v-model="kunde.adresseLists[1].ort" type="text" class="form-control" autocomplete="new-city" />
                                                    <span v-if="!kunde.adresseLists[1].ort" class="red-text">
                                                        {{ $t('entity.validation.required') }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row form-group">
                                                <div class="col-md-6">
                                                    <label>{{ $t('strasse') }}</label>
                                                    <input
                                                        v-model="kunde.adresseLists[1].strasse"
                                                        type="text"
                                                        class="form-control"
                                                        autocomplete="new-street"
                                                        required
                                                    />
                                                    <span v-if="!kunde.adresseLists[1].strasse" class="red-text">
                                                        {{ $t('entity.validation.required') }}
                                                    </span>
                                                </div>
                                                <div class="col-md-2">
                                                    <label>{{ $t('hausnummer') }}</label>
                                                    <input
                                                        v-model="kunde.adresseLists[1].hausNummer"
                                                        type="text"
                                                        class="form-control"
                                                        autocomplete="new-housenumber"
                                                    />
                                                </div>
                                                <div class="col-md-2">
                                                    <label>{{ $t('stiege') }}</label>
                                                    <input v-model="kunde.adresseLists[1].stiege" type="text" class="form-control" name="stiege" />
                                                </div>
                                                <div class="col-md-2">
                                                    <label>{{ $t('tuer') }}</label>
                                                    <input v-model="kunde.adresseLists[1].tuer" type="text" class="form-control" name="tuer" />
                                                </div>
                                            </div>
                                            <div class="row form-group">
                                                <div class="col-md-6">
                                                    <label>{{ $t('adressZusatz') }}</label>
                                                    <input v-model="kunde.adresseLists[1].adressZusatz" type="text" class="form-control" name="adressZusatz" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </slot>
                            </div>
                            <div class="modal-footer">
                                <slot name="footer">
                                    <button type="button" class="btn dg-btn--cancel" @click="$emit('close')">
                                        <font-awesome-icon :icon="['fad', 'ban']" />&nbsp;<span>{{ $t('cancel') }}</span>
                                    </button>
                                    <button-spinner
                                        :style-class="'btn dg-btn--ok dg-pull-right'"
                                        :is-loading="isLoading"
                                        :mode="1"
                                        :text="$t('save')"
                                        :disabled="isLoading || setButtonStatus()"
                                        :typ="'button'"
                                        :icon="'save'"
                                        @finished="isLoading = false"
                                        @action="
                                            isLoading = true;
                                            createKunde();
                                        "
                                    >
                                    </button-spinner>
                                </slot>
                            </div>
                        </form>
                    </div>
                    <div v-if="!mode" class="modal-container">
                        <form name="editForm" role="form">
                            <div class="modal-header">
                                <slot name="header">
                                    <h5>Kontaktperson anlegen</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('close')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </slot>
                            </div>
                            <div class="modal-body">
                                <slot name="body">
                                    <div class="container">
                                        <div class="row">
                                            <div class="form-group col-md-2">
                                                <label class="control-label">{{ $t('geschlecht') }}</label>
                                                <select
                                                    id="field_kontaktgeschlecht"
                                                    v-model="kundeKontakt.geschlecht"
                                                    class="form-control"
                                                    name="kontaktgeschlecht"
                                                >
                                                    <option value=""></option>
                                                    <option v-for="geschlecht in geschlechts" :key="geschlecht.id" :value="geschlecht">
                                                        {{ _.capitalize(geschlecht) }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label class="control-label" for="field_Kontaktvorname">{{ $t('vorname') }}</label>
                                                <input
                                                    id="field_Kontaktvorname"
                                                    v-model="kundeKontakt.vorname"
                                                    type="text"
                                                    class="form-control"
                                                    name="vorname"
                                                    autocomplete="new-firstname"
                                                />
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label class="control-label" for="field_Kontaktnachname">{{ $t('nachname') }}</label>
                                                <input
                                                    id="field_Kontaktnachname"
                                                    v-model="kundeKontakt.nachname"
                                                    type="text"
                                                    class="form-control"
                                                    name="nachname"
                                                    autocomplete="new-lastname"
                                                />
                                            </div>
                                            <div class="form-group col-md-2">
                                                <label class="control-label" for="field_Kontaktemail">{{ $t('email') }}</label>
                                                <input
                                                    id="field_Kontaktemail"
                                                    v-model="kundeKontakt.email"
                                                    type="text"
                                                    class="form-control"
                                                    name="email"
                                                    autocomplete="new-email"
                                                />
                                                <div class="red-text" v-if="$v.kundeKontakt.email.$invalid">{{ $t('entity.validation.email') }}</div>
                                            </div>
                                            <div class="form-group col-md-2">
                                                <label class="control-label" for="field_Kontakttelnr">{{ $t('telnr') }}</label>
                                                <input
                                                    id="field_Kontakttelnr"
                                                    v-model="kundeKontakt.telnr"
                                                    type="text"
                                                    class="form-control"
                                                    name="telnr"
                                                    autocomplete="new-phonenumber"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </slot>
                            </div>
                            <div class="modal-footer">
                                <slot name="footer">
                                    <button type="button" class="btn dg-btn--cancel" @click="$emit('close')">
                                        <font-awesome-icon :icon="['fad', 'ban']" />&nbsp;<span>{{ $t('cancel') }}</span>
                                    </button>
                                    <button-spinner
                                        :style-class="'btn dg-btn--ok dg-pull-right'"
                                        :is-loading="isLoading"
                                        :mode="1"
                                        :text="$t('save')"
                                        :disabled="isLoading"
                                        :typ="'button'"
                                        :icon="'save'"
                                        @finished="isLoading = false"
                                        @action="
                                            isLoading = true;
                                            createKundeKontakt();
                                        "
                                    >
                                    </button-spinner>
                                </slot>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ADRESSEN_TYP } from '@/constants';

export default {
    name: 'KundenSchnellAnlage',
    props: {
        value: {
            type: String,
            default: '',
        },
        woocommerceKunde: {
            type: Object,
            default: null,
        },
        woocommerceKundeShipping: {
            type: Object,
            default: null,
        },
        addLieferadresse: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: Boolean,
            default: true,
        },
        kundeEdit: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isLoading: false,
            kundeKontakt: {},
            activeTab: 'billing',
        };
    },
    computed: {
        ...mapGetters({
            kunde: 'kunde/data',
            geschlechts: 'geschlecht/list',
        }),
        mutableKundeEdit: {
            get() {
                return this.kundeEdit;
            },
            set(value) {
                this.$emit('update:kundeEdit', value);
            },
        },
        checkAddLieferadresse() {
            return (
                this.kunde.adresseLists[1].strasse == null ||
                this.kunde.adresseLists[1].strasse === '' ||
                this.kunde.adresseLists[1].plz == null ||
                this.kunde.adresseLists[1].plz === '' ||
                this.kunde.adresseLists[1].ort == null ||
                this.kunde.adresseLists[1].ort === '' ||
                this.kunde.adresseLists[1].land == null
            );
        },
    },
    validations() {
        const emailChecker = function (value) {
            let email = value;
            if (email != null) {
                let mailformat =
                    /^(([^<>()[\]\\.,;:äöüÄÖÜ\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-ZäöüÄÖÜ\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (email.match(mailformat)) {
                    return true;
                } else {
                    return false;
                }
            }
            return true;
        };
        return {
            kundeKontakt: {
                email: {
                    emailChecker,
                },
            },
        };
    },
    mounted() {
        this.kundeKontakt = {};
        this.$store.dispatch('kunde/clear').then(() => {
            this.kunde.firma = 'firma';
            this.kunde.geschlecht = 'herr';
            if (this.woocommerceKunde) {
                this.kunde.firma = 'privatperson';
                this.kunde.vorname = this.woocommerceKunde.first_name;
                this.kunde.nachname = this.woocommerceKunde.last_name;
                this.kunde.email = this.woocommerceKunde.email;
                this.kunde.adresseLists[0].firmenName = this.woocommerceKunde.company;
                this.kunde.adresseLists[0].strasse = this.woocommerceKunde.address_1;
                this.kunde.adresseLists[0].adressZusatz = this.woocommerceKunde.address_2;
                this.kunde.adresseLists[0].plz = this.woocommerceKunde.postcode;
                this.kunde.adresseLists[0].ort = this.woocommerceKunde.city;
                this.kunde.adresseLists[0].adressenTyp = ADRESSEN_TYP.RECHNUNGSADRESSE;
                if (this.addLieferadresse) {
                    let adresse = {};
                    adresse.adressenTyp = ADRESSEN_TYP.LIEFERADRESSE;
                    adresse.land = this.kunde.adresseLists[0].land;
                    adresse.bezeichnung = this.woocommerceKundeShipping.first_name + ' ' + this.woocommerceKundeShipping.last_name;
                    adresse.firmenName = this.woocommerceKundeShipping.company;
                    adresse.strasse = this.woocommerceKundeShipping.address_1;
                    adresse.adressZusatz = this.woocommerceKundeShipping.address_2;
                    adresse.plz = this.woocommerceKundeShipping.postcode;
                    adresse.ort = this.woocommerceKundeShipping.city;
                    this.kunde.adresseLists.push(adresse);
                }
                if (this.woocommerceKunde.id) this.kunde.webshopId = this.woocommerceKunde.id;
            }
        });
    },
    methods: {
        createKundeKontakt() {
            this.mutableKundeEdit.kundeKontakte.push(this.kundeKontakt);
            this.$store.dispatch('kunde/update', this.kundeEdit).then(() => {
                this.isLoading = false;
                this.$emit('close-modal', this.kundeKontakt);
            });
        },
        setButtonStatus() {
            if (this.kunde.firma === 'firma') {
                return (
                    this.kunde.bezeichnung == null ||
                    this.kunde.bezeichnung === '' ||
                    this.kunde.adresseLists[0].strasse == null ||
                    this.kunde.adresseLists[0].strasse === '' ||
                    this.kunde.adresseLists[0].plz == null ||
                    this.kunde.adresseLists[0].plz === '' ||
                    this.kunde.adresseLists[0].ort == null ||
                    this.kunde.adresseLists[0].ort === '' ||
                    this.kunde.adresseLists[0].land == null
                );
            } else {
                if (this.addLieferadresse) {
                    return (
                        this.kunde.nachname == null ||
                        this.kunde.nachname === '' ||
                        this.kunde.vorname == null ||
                        this.kunde.vorname === '' ||
                        this.kunde.geschlecht == null ||
                        this.kunde.geschlecht === '' ||
                        this.kunde.adresseLists[0].strasse == null ||
                        this.kunde.adresseLists[0].strasse === '' ||
                        this.kunde.adresseLists[0].plz == null ||
                        this.kunde.adresseLists[0].plz === '' ||
                        this.kunde.adresseLists[0].ort == null ||
                        this.kunde.adresseLists[0].ort === '' ||
                        this.kunde.adresseLists[0].land == null ||
                        this.kunde.adresseLists[1].strasse == null ||
                        this.kunde.adresseLists[1].strasse === '' ||
                        this.kunde.adresseLists[1].plz == null ||
                        this.kunde.adresseLists[1].plz === '' ||
                        this.kunde.adresseLists[1].ort == null ||
                        this.kunde.adresseLists[1].ort === '' ||
                        this.kunde.adresseLists[1].land == null
                    );
                } else {
                    return (
                        this.kunde.nachname == null ||
                        this.kunde.nachname === '' ||
                        this.kunde.vorname == null ||
                        this.kunde.vorname === '' ||
                        this.kunde.geschlecht == null ||
                        this.kunde.geschlecht === '' ||
                        this.kunde.adresseLists[0].strasse == null ||
                        this.kunde.adresseLists[0].strasse === '' ||
                        this.kunde.adresseLists[0].plz == null ||
                        this.kunde.adresseLists[0].plz === '' ||
                        this.kunde.adresseLists[0].ort == null ||
                        this.kunde.adresseLists[0].ort === '' ||
                        this.kunde.adresseLists[0].land == null
                    );
                }
            }
        },
        createKunde() {
            if (this.kunde.firma === 'firma') {
                this.kunde.nachname = null;
                this.kunde.vorname = null;
                this.kunde.geschlecht = null;
            }

            if (this.kunde.firma === 'privatperson') {
                this.kunde.bezeichnung = null;
            }

            this.$store.dispatch('kunde/update', this.kunde).then((response) => {
                this.isLoading = false;
                this.$emit('close-modal', response);
            });
        },
        generateKundenNummer() {
            let that = this;
            this.$store.dispatch('kunde/getNextNumber').then(function (result) {
                if (result != 0) {
                    that.kunde.kundenNummer = result;
                }
            });
        },
    },
};
</script>

<style scoped>
.modal-container {
    overflow-y: auto;
    max-height: 97vh;
}
</style>
<!-- language=json -->
<i18n>
{
    "de": {
        "placeholder": "Schnellsuche: Kundenbezeichnung oder Kundennummer",
        "new": "Neuen Kunden anlegen",
        "no-result": "Kein Ergebnis",
        "save": "Speichern",
        "cancel": "Abbrechen",
        "bezeichnung": "Bezeichnung",
        "uid": "Uid (Umsatzsteueridentifikation)",
        "adresseList": "AdresseList",
        "mandant": "Mandant",
        "preisschiene": "Preisschiene",
        "rechnungList": "RechnungList",
        "innergemeinschaftlich": "Innergemeinschaftlich (EU)",
        "versteuerung": "Umkehr der Steuerschuld (für Kunden im Ausland)",
        "email": "E-Mail Adresse",
        "kundenNummer": "Kundennummer",
        "belegvorlageberwenden": "Belegvorlage verwenden",
        "export": "Export (Nicht-EU)",
        "belegausdruck": "Belegsprache",
        "rabattvorschlag": "Rabattvorschlag",
        "nachname": "Nachname",
        "vorname": "Vorname",
        "geschlecht": "Geschlecht",
        "typ": "Typ",
        "telnr": "Telefon",
        "hausnummer": "Hausnummer",
        "strasse": "Strasse",
        "stiege": "Stiege",
        "tuer": "Tuer",
        "ort": "Ort",
        "land": "Land",
        "plz": "Postleitzahl",
        "anrede": "Anrede",
        "firma": "Firma",
        "person": "Privatperson",
        "herr": "Herr",
        "frau": "Frau",
        "adressZusatz": "Adresszusatz"
    },
    "en": {
        "placeholder": "Fast search: name of Customer or Customer No.",
        "new": "Create new customer",
        "no-result": "No result",
        "save": "Save",
        "cancel": "Cancel",
        "uid": "Uid",
        "adresseList": "AdresseList",
        "mandant": "Mandant",
        "rechnungList": "RechnungList",
        "innergemeinschaftlich": "Within EU",
        "versteuerung": "Reverse Charge",
        "email": "eMail",
        "kundenNummer": "Customer Number",
        "belegvorlageberwenden": "Belegvorlage verwenden",
        "export": "Export",
        "belegausdruck": "Belegsprache",
        "rabattvorschlag": "Rabattvorschlag in %",
        "nachname": "Nachname",
        "vorname": "Vorname",
        "geschlecht": "Geschlecht",
        "typ": "Typ",
        "telnr": "TelNr",
        "hausnummer": "Housenumber",
        "strasse": "Street",
        "stiege": "Stair",
        "tuer": "Door",
        "ort": "City",
        "land": "Country",
        "plz": "Zip Code",
        "anrede": "salutation",
        "firma": "Company",
        "person": "Person",
        "herr": "Mr",
        "frau": "Mis",
        "adressZusatz": "Address supplement"
    }
}
</i18n>
