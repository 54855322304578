import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'belege';
const resourceBelegSearch = 'belegsearchpaginated';

const BelegService = {
    async get(slug) {
        return ApiService.get(resource, slug);
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
    async belegSearch(params) {
        return ApiService.query(resourceBelegSearch, params);
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource, params);
            Toast.showSaveOkToastI18n(null, params.displayToast);
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource, params);
            Toast.showSaveOkToastI18n(null, params.displayToast);
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async delete(slug) {
        let promise = null;
        try {
            promise = await ApiService.delete(resource, slug);
            Toast.showDeleteOkToastI18n();
        } catch (e) {
            Toast.showDeleteErrorToastI18n();
        }
        return promise;
    },
};

export default BelegService;
