import Vue from 'vue';
import ZeiterfassungService from './zeiterfassung.service';
import * as _ from 'lodash';

const getDefaultItem = () => ({
    id: null,
    abgerechnet: false,
    von: new Date(),
    bis: new Date(),
    abrechenbar: true,
    mandant: null,
    projekt: {},
    user: null,
    beschreibung: '',
    detailBeschreibung: '',
    addBreak: false,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
    currentPage: 0,
    totalPages: 1,
    timemode: false,
    selectedDate: new Date(),
    toggleTime: null,
});

const zeiterfassung = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clearItem(state) {
            Vue.set(state, 'item', getDefaultState().item);
        },
        clear(state) {
            Object.assign(state, getDefaultState());
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
        updateTimeMode(state, data) {
            Vue.set(state, 'timemode', data);
        },
        toggleTime(state, data) {
            Vue.set(state, 'toggleTime', data);
        },
        updateSelectedDate(state, data) {
            state.selectedDate = data;
        },
    },

    actions: {
        async clearItem(context) {
            context.commit('clearItem');
        },
        async clear(context) {
            context.commit('clear');
        },
        async create(context, client_data) {
            const { data } = await ZeiterfassungService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await ZeiterfassungService.update(client_data.id, client_data);
            context.commit('update', data);
            return data;
        },
        async get(context, id) {
            const { data } = await ZeiterfassungService.get(id);
            context.commit('update', data);
            context.commit('updateTimeMode', true);
            return data;
        },
        async updateTimeMode(context, mode) {
            context.commit('updateTimeMode', mode);
            return mode;
        },
        async toggleTime(context, client_data) {
            const { data } = await ZeiterfassungService.updateTimer(client_data);
            context.commit('toggleTime', data);
            return data;
        },
        async querytoggleTime(context, slug) {
            const { data } = await ZeiterfassungService.queryToggleTime(slug);
            context.commit('toggleTime', data);
            return data;
        },
        async duplicate(context, id) {
            const { data } = await ZeiterfassungService.get(id);
            data.id = null;
            data.von = new Date();
            context.commit('update', data);
            context.commit('updateTimeMode', true);
            return data;
        },
        async query(context, params) {
            const { headers, data } = await ZeiterfassungService.query(params);
            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let size = params && params.params && params.params.size ? params.params.size : 20;
            let totalCount = headers['x-total-count'];
            let totalPages = 1;

            if (_.toNumber(totalCount) > 0 && _.toNumber(size) > 0) {
                totalPages = _.toNumber(totalCount) / _.toNumber(size);
            }

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async loadMore(context, params) {
            const { data } = await ZeiterfassungService.query(params);
            const items = context.getters.list;
            items.push(...data);
            context.commit('updateList', items);
            return data;
        },
        async delete(context, slug) {
            await ZeiterfassungService.delete(slug);
        },
        async toggleTimeDelete(context, slug) {
            const { data } = await ZeiterfassungService.deleteTimer(slug);
            context.commit('toggleTime', null);
            return data;
        },
        async updateSelectedDate(context, selectedDate) {
            context.commit('updateSelectedDate', selectedDate);
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
        timemode(state) {
            return state.timemode;
        },
        toggleTime(state) {
            return state.toggleTime;
        },
        selectedDate(state) {
            return state.selectedDate;
        },
    },
};

export default zeiterfassung;
