import Vue from 'vue';
import Toasted from 'vue-toasted';
import VueI18n from '@/i18n';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

const Toast = {
    options: {
        theme: 'outline',
        position: 'bottom-right',
        duration: 3000,
        iconPack: 'fontawesome',
    },

    dialogOpt: {
        okText: VueI18n.tc('entity.action.delete'),
        cancelText: VueI18n.tc('entity.action.cancel'),
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
    },

    dialogDeactivateOpt: {
        okText: VueI18n.tc('entity.action.deactivate'),
        cancelText: VueI18n.tc('entity.action.cancel'),
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
    },

    dialogActivateOpt: {
        okText: VueI18n.tc('entity.action.activate'),
        cancelText: VueI18n.tc('entity.action.cancel'),
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
    },

    init() {
        Vue.use(Toasted, this.options);
        Vue.use(VuejsDialog);
    },

    showSaveOkToastI18n(i18nConst, displayToast = true) {
        if (!i18nConst) i18nConst = 'global.messages.toast.save_ok';
        if (displayToast) {
            Vue.toasted.show(VueI18n.tc(i18nConst), { icon: 'check' });
        }
    },

    showDeleteOkToastI18n(i18nConst) {
        if (!i18nConst) i18nConst = 'global.messages.toast.delete_ok';
        Vue.toasted.show(VueI18n.tc(i18nConst), { icon: 'check' });
    },

    showDeactivateOkToastI18n(i18nConst) {
        if (!i18nConst) i18nConst = 'global.messages.toast.deactivate_ok';
        Vue.toasted.show(VueI18n.tc(i18nConst), { icon: 'check' });
    },

    showActivateOkToastI18n(i18nConst) {
        if (!i18nConst) i18nConst = 'global.messages.toast.activate_ok';
        Vue.toasted.show(VueI18n.tc(i18nConst), { icon: 'check' });
    },

    showCreateOkToastI18n(i18nConst) {
        if (!i18nConst) i18nConst = 'global.messages.toast.create_ok';
        Vue.toasted.show(VueI18n.tc(i18nConst), { icon: 'check' });
    },

    askDelete(i18nConst) {
        if (!i18nConst) i18nConst = 'entity.delete.msg';

        let promise = Vue.dialog
            .confirm(VueI18n.tc(i18nConst), this.dialogOpt)
            .then(() => true)
            .catch(() => false);

        return promise;
    },

    askCustomDialog(text, okButtontext, optionalCancelText, useHtml = false) {
        let promise = Vue.dialog
            .confirm(text, {
                okText: okButtontext,
                cancelText: optionalCancelText ? optionalCancelText : VueI18n.tc('entity.action.cancel'),
                animation: 'zoom', // Available: "zoom", "bounce", "fade"
                html: useHtml,
            })
            .then(() => true)
            .catch(() => false);

        return promise;
    },

    showAlert(text, okButtontext) {
        let promise = Vue.dialog
            .alert(text, {
                okText: okButtontext,
                animation: 'zoom', // Available: "zoom", "bounce", "fade"
            })
            .then(() => {});

        return promise;
    },

    askDeactivate(i18nConst) {
        if (!i18nConst) i18nConst = 'entity.deactivate.msg';

        let promise = Vue.dialog
            .confirm(VueI18n.tc(i18nConst), this.dialogDeactivateOpt)
            .then(() => true)
            .catch(() => false);

        return promise;
    },

    askActivate(i18nConst) {
        if (!i18nConst) i18nConst = 'entity.activate.msg';

        let promise = Vue.dialog
            .confirm(VueI18n.tc(i18nConst), this.dialogActivateOpt)
            .then(() => true)
            .catch(() => false);

        return promise;
    },

    showActivateErrorToastI18n(i18nConst) {
        if (!i18nConst) i18nConst = 'global.messages.toast.activate_nok';
        Vue.toasted.show(VueI18n.tc(i18nConst), {
            icon: 'warning',
            theme: 'bubble',
            duration: '3000',
        });
    },

    showDectivateErrorToastI18n(i18nConst) {
        if (!i18nConst) i18nConst = 'global.messages.toast.deactivate_nok';
        Vue.toasted.show(VueI18n.tc(i18nConst), {
            icon: 'warning',
            theme: 'bubble',
            duration: '3000',
        });
    },

    showDeleteErrorToastI18n(i18nConst) {
        if (!i18nConst) i18nConst = 'global.messages.toast.delete_nok';
        Vue.toasted.show(VueI18n.tc(i18nConst), {
            icon: 'warning',
            theme: 'bubble',
            duration: '3000',
        });
    },
    showCustomToastMessage(message) {
        Vue.toasted.show(message, {
            icon: 'info',
            theme: 'bubble',
            duration: '3000',
        });
    },
    showCustomSuccessfulToastMessage(message) {
        Vue.toasted.show(message, {
            icon: 'info',
            theme: 'bubble',
            duration: '7500',
            className: ['custom-toast-green'],
        });
    },
    showCustomFailedToastMessage(message) {
        Vue.toasted.show(message, {
            icon: 'info',
            theme: 'bubble',
            duration: '7500',
        });
    },
    showSaveErrorToastI18n(i18nConst, errorObject, paramToTranslate) {
        if (!i18nConst) i18nConst = 'global.messages.toast.save_nok';

        let message = VueI18n.tc(i18nConst, 1, paramToTranslate);
        if (errorObject) {
            const errorMessage = this.extractErrorMessage(errorObject);
            if (message && message.length > 0) {
                message += ': ' + errorMessage;
            }
        }

        Vue.toasted.show(message, {
            icon: 'warning',
            theme: 'bubble',
            duration: '3000',
        });
    },
    extractErrorMessage(errorObject) {
        let message = '';
        if (errorObject && errorObject.response && errorObject.response.data && errorObject.response.data.message) {
            message = errorObject.response.data.message;
        }
        return message;
    },
};

export default Toast;
{
    // eslint-disable-next-line no-unused-expressions
    () => ({
        resolve: null,
    });
}
