import Vue from 'vue';
import FavoritesService from './favorites.service';

const getDefaultItem = () => ({
    id: null,
    typ: null,
    content: null,
    user: null,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
    selectedFavs: [],
});

const favorites = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state) {
            Object.assign(state, getDefaultState());
        },
        setFavs(state, data) {
            state.selectedFavs.push(data);
            //Vue.set(state, 'selectedFavs', data);
        },
    },

    actions: {
        async clear(context) {
            context.commit('clear');
        },
        async create(context, client_data) {
            const { data } = await FavoritesService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await FavoritesService.update(client_data.id, client_data);
            context.commit('update', data);
            return data;
        },
        async get(context, id) {
            const { data } = await FavoritesService.get(id);
            context.commit('update', data);
            return data;
        },
        async query(context, params) {
            const { data } = await FavoritesService.query(params);
            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            await FavoritesService.delete(slug);
        },
        async updateList(context, client_data) {
            context.commit('updateList', client_data);
            return client_data;
        },
        async updateFavs(context, client_data) {
            context.commit('setFavs', client_data);
            return client_data;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
        selectedFavs(state) {
            return state.selectedFavs;
        },
    },
};

export default favorites;
