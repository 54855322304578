import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'benachrichtigung';

const BenachrichtigungenService = {
    get(slug) {
        return ApiService.get(resource, slug);
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
    async queryMyNews(params) {
        return ApiService.query(resource + '/mynews', params);
    },
    async resetStatus() {
        let promise = null;
        try {
            promise = await ApiService.put(resource + '/resetstatus');
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async delete(slug) {
        let promise = null;
        try {
            promise = await ApiService.delete(resource, slug);
            Toast.showDeleteOkToastI18n();
        } catch (e) {
            Toast.showDeleteErrorToastI18n();
        }
        return promise;
    },
    async deactivate(slug) {
        let promise = null;
        try {
            promise = await ApiService.delete(`${resource}/deactivate`, slug);
            Toast.showDeactivateOkToastI18n();
        } catch (e) {
            Toast.showDectivateErrorToastI18n();
        }
        return promise;
    },
    async activate(slug) {
        let promise = null;
        try {
            promise = await ApiService.delete(`${resource}/activate`, slug);
            Toast.showActivateOkToastI18n();
        } catch (e) {
            Toast.showActivateErrorToastI18n();
        }
        return promise;
    },
};

export default BenachrichtigungenService;
