import axios from 'axios';
import JwtService from '@/common/jwt.service';
import auth from '@/app/auth/auth.helpers';
import ConfigService from '@/common/config.service';

let source;

const ApiService = {
    //see https://medium.com/@zitko/structuring-a-vue-project-authentication-87032e5bfe16
    _401interceptor: null,

    init() {
        axios.defaults.baseURL = ConfigService.getConfigIfExists('backendBaseUrl');
        axios.defaults.headers.common['Cache-Control'] = 'no-cache';
        axios.defaults.headers.common['Pragma'] = 'no-cache';
        axios.defaults.headers.common['Expires'] = 'Sat, 01 Jan 2000 00:00:00 GMT';

        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                return Promise.reject(error);
            }
        );

        if (JwtService.getToken() != null) {
            this.setJwtHeader();
            this.mount401Interceptor();
            this.setMandantHeader();
        }
    },

    setJwtHeader() {
        axios.defaults.headers.common['Authorization'] = `Bearer ${JwtService.getToken()}`;
    },

    setMandantHeader() {
        axios.defaults.headers.common['X-HOERB-M'] = JwtService.getMandant();
    },

mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error.request.status === 401) {
                auth.logout();
            } else {
                // If error was not 401 just reject as is
                throw error;
            }
        }
    );
},

    removeJwtHeader() {
        delete axios.defaults.headers.common['Authorization'];
    },

    removeMandantHeader() {
        delete axios.defaults.headers.common['X-HOERB-M'];
    },

    unmount401Interceptor() {
        axios.interceptors.response.eject(this._401interceptor);
    },

    cleanUp() {
        this.removeJwtHeader();
        this.removeMandantHeader();
        this.unmount401Interceptor();
    },

    query(resource, params) {
        return axios.get(resource, params).catch((error) => {
            this.throwError(error);
        });
    },

    searchItems(resource, params) {
        if (source) source.cancel('Operation canceled by the user.');
        source = axios.CancelToken.source();

        const option = {
            params,
            cancelToken: source.token,
        };

        return axios.get(resource, option).catch((error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                //this.throwError(error);
            }
        });
    },

    get(resource, slug = '') {
        let path = `${resource}/${slug}`;
        if (path.endsWith('/')) {
            path = path.slice(0, -1);
        }
        return axios.get(path).catch((error) => {
            this.throwError(error);
        });
    },

    getQuery(resource, slug = '', params) {
        return axios.get(`${resource}/${slug}`, params).catch((error) => {
            this.throwError(error);
        });
    },

    getPdf(resource, slug = '', params = null) {
        return axios
            .get(`${resource}/${slug}`, {
                responseType: 'arraybuffer',
                params: params,
            })
            .catch((error) => {
                this.throwError(error);
            });
    },
    getBlob(resource, params) {
        return axios.get(`${resource}`, params).catch((error) => {
            this.throwError(error);
        });
    },

    postPdf(resource, params) {
        return axios
            .post(`${resource}`, params, {
                responseType: 'arraybuffer',
            })
            .catch((error) => {
                this.throwError(error);
            });
    },

    async uploadMultipartFormData(resource, params) {
        return axios.post(`${resource}`, params, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },

    post(resource, params, config) {
        return axios.post(`${resource}`, params, config).catch((error) => {
            this.throwError(error);
        });
    },

    changeResetPasswort(resource, params) {
        return axios
            .post(`${resource}`, params, {
                headers: { 'Content-Type': 'text/plain' },
            })
            .catch((error) => {
                this.throwError(error);
            });
    },

    update(resource, slug, params) {
        return axios.put(`${resource}/${slug}`, params).catch((error) => {
            this.throwError(error);
        });
    },

    put(resource, params) {
        return axios.put(`${resource}`, params).catch((error) => {
            this.throwError(error);
        });
    },

    delete(resource, slug) {
        return axios.delete(`${resource}/${slug}`).catch((error) => {
            this.throwError(error);
        });
    },
    throwError(error) {
        throw new Error(`[HOERB] ApiService ${error}`);
    },
};

export default ApiService;
