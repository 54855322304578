import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'artikel';
const resourceNumber = 'nextartikelnumber';

const ArtikelService = {
    async get(slug) {
        return ApiService.get(resource, slug);
    },
    async checkUniqueArtNr(params) {
        return ApiService.put(`artikel/checkartikelnr`, params);
    },
    async getWebshopArtikel(slug) {
        return ApiService.get('artikel/webshop', slug);
    },
    async getNextNumber() {
        return ApiService.get(resourceNumber);
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params, showToast) {
        let promise = null;
        try {
            promise = await ApiService.put(resource, params);
            if (showToast) {
                Toast.showSaveOkToastI18n();
            }
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async delete(slug) {
        let promise = null;
        try {
            promise = await ApiService.delete(resource, slug);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async updateList(params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource + 'list', params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async artikelSuche(searchTerm, verleihbar, geloescht) {
        const resource = 'artikelsearch';
        //return ApiService.query(resource, { params: { search: searchTerm, verleihbar: verleihbar, geloescht: geloescht } });
        return ApiService.searchItems(resource, { search: searchTerm, verleihbar: verleihbar, geloescht: geloescht });
    },
    async saveArticleWebshop(params) {
        console.log(params);
        let promise = null;
        try {
            promise = await ApiService.post('/wc/products', params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
};

export default ArtikelService;
