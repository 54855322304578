import Vue from 'vue';
import ArtikelService from './artikel.service';
import * as _ from 'lodash';
import abstractStore from '../../store/abstractstore';

export const getDefaultSort = () => ({ currentSort: 'artikelBezeichnung', currentSortDir: 'asc' });

const getDefaultItem = () => ({
    id: null,
    artikelNummer: null,
    artikelBezeichnung: null,
    artikelBeschreibung: null,
    artikelKommentar: null,
    artikelLieferantSet: [],
    einkaufsPreis: 0,
    lagerstand: null,
    geloescht: false,
    rabattierFaehig: true,
    mehrwertssteuer: {},
    untergruppe: null,
    hersteller: null,
    gewichtDurchmesser: null,
    laenge: null,
    verpackung: null,
    vpe: null,
    verleihbar: false,
    halter: null,
    artikelImages: [],
    artikelFelder: [],
    artikelPdfs: [],
    locked: false,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
    selectedWG: null,
    selectedUG: null,
    selectedUGS: [],
    selectedVerleihbar: false,
    selectedIncludeDeactivated: false,
    nextArtikelNummer: null,
    ...abstractStore.getAbstractValue(getDefaultSort().currentSort, getDefaultSort().currentSortDir),
});

const artikel = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        updateNextArtikelnumber(state, data) {
            Vue.set(state, 'nextArtikelNummer', data);
        },
        selectedWG(state, data) {
            Vue.set(state, 'selectedWG', data);
        },
        selectedUG(state, data) {
            Vue.set(state, 'selectedUG', data);
        },
        selectedUGS(state, data) {
            Vue.set(state, 'selectedUGS', data);
        },
        selectedVerleihbar(state, data) {
            Vue.set(state, 'selectedVerleihbar', data);
        },
        selectedIncludeDeactivated(state, data) {
            Vue.set(state, 'selectedIncludeDeactivated', data);
        },
        clearComplete(state) {
            Object.assign(state, getDefaultState());
        },
        clear(state) {
            Vue.set(state, 'item', getDefaultState().item);
            Vue.set(state, 'list', getDefaultState().list);
        },
    },
    actions: {
        clearComplete({ commit }) {
            commit('clearComplete');
        },
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            const { data } = await ArtikelService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await ArtikelService.update(client_data.id, client_data, true);
            context.commit('update', data);
            return data;
        },
        async updateNoToast(context, client_data) {
            const { data } = await ArtikelService.update(client_data.id, client_data, false);
            context.commit('update', data);
            return data;
        },
        async getNextNumber(context) {
            const { data } = await ArtikelService.getNextNumber();
            context.commit('updateNextArtikelnumber', data);
            return data;
        },
        async get(context, id) {
            const { data } = await ArtikelService.get(id);
            context.commit('update', data);
            return data;
        },
        async query(context, params) {
            if ((params && params.params && params.params.init) === true && params.params.page === 0 && context.rootState.artikel.currentPage !== 0) {
                params.params.page = context.rootState.artikel.currentPage;
            }
            const { data, headers } = await ArtikelService.query(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let size = params && params.params && params.params.size ? params.params.size : 20;
            let totalCount = headers['x-total-count'];
            let totalPages = 1;

            if (_.toNumber(totalCount) > 0 && _.toNumber(size) > 0) {
                totalPages = _.toNumber(totalCount) / _.toNumber(size);
            }

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async importArtikel(context, client_data) {
            const { data } = await ArtikelService.updateList(client_data);
            return data;
        },
        async delete(context, slug) {
            await ArtikelService.delete(slug);
        },
        selectedWG(context, wg) {
            context.commit('selectedWG', wg);
        },
        selectedUG(context, ug) {
            context.commit('selectedUG', ug);
        },
        selectedUGS(context, ugs) {
            context.commit('selectedUGS', ugs);
        },
        selectedVerleihbar(context, ugs) {
            context.commit('selectedVerleihbar', ugs);
        },
        selectedIncludeDeactivated(context, ugs) {
            context.commit('selectedIncludeDeactivated', ugs);
        },
    },

    getters: {
        selectedWG(state) {
            return state.selectedWG;
        },
        selectedUG(state) {
            return state.selectedUG;
        },
        selectedUGS(state) {
            return state.selectedUGS;
        },
        selectedVerleihbar(state) {
            return state.selectedVerleihbar;
        },
        selectedIncludeDeactivated(state) {
            return state.selectedIncludeDeactivated;
        },
    },
};

export default _.merge(artikel, abstractStore.getDefaultStore());
