<template>
    <div>
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header">
                            <slot name="header">
                                <h5>{{ $t('description') }}</h5>
                            </slot>
                        </div>
                        <div class="modal-body" style="overflow-y: auto">
                            <slot name="body">
                                <div class="container" align="center">
                                    <div class="row">
                                        <div class="form-group col-md-12">
                                            <qrcode-stream @decode="decode"></qrcode-stream>
                                        </div>
                                    </div>
                                </div>
                            </slot>
                        </div>
                        <div class="modal-footer">
                            <slot name="footer">
                                <button type="button" class="btn dg-btn--cancel" @click="$emit('close')">
                                    <font-awesome-icon :icon="['fad', 'ban']" />&nbsp;<span>{{ $t('cancel') }}</span>
                                </button>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
    name: 'QrScan',
    components: { QrcodeStream },
    data() {
        return {
            loading: false,
        };
    },
    computed: {},
    async mounted() {},
    methods: {
        decode(event) {
            this.$emit('scan', event);
        },
    },
};
</script>

<style></style>
<!-- language=json -->
<i18n>
    {
        "de": {
            "description": "QR Scanner",
            "cancel": "Abbrechen"
        },
        "en": {
            "description": "QR Scanner",
            "cancel": "Cancel"
        }
    }
</i18n>
