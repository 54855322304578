<template>
    <div>
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <form name="editForm" role="form">
                            <div class="modal-header">
                                <slot name="header">
                                    <h5>{{ artikel.artikelBezeichnung != null ? artikel.artikelBezeichnung : '' }}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('close')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </slot>
                            </div>
                            <div class="modal-body">
                                <slot name="body">
                                    <div class="container">
                                        <div class="row">
                                            <div class="inner-addon right-addon form-group col-md-3">
                                                <label class="control-label" for="field_artikelNummer">{{ $t('artikelNummer') }}</label>
                                                <input
                                                    id="field_artikelNummer"
                                                    :value="artikel.artikelNummer != null ? artikel.artikelNummer : ''"
                                                    :disabled="true"
                                                    type="text"
                                                    class="form-control"
                                                    name="artikelNummer"
                                                />
                                            </div>
                                            <div class="form-group col-md-9">
                                                <label class="control-label" for="field_artikelBezeichnung">{{ $t('artikelBezeichnung') }}</label>
                                                <input
                                                    id="field_artikelBezeichnung"
                                                    :value="artikel.artikelBezeichnung != null ? artikel.artikelBezeichnung : ''"
                                                    type="text"
                                                    class="form-control"
                                                    name="artikelBezeichnung"
                                                    autocomplete="new-articlelable"
                                                    :disabled="true"
                                                />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-md-6">
                                                <label class="control-label" for="field_artikelBeschreibung">{{ $t('artikelBeschreibung') }}</label>
                                                <textarea
                                                    id="field_artikelBeschreibung"
                                                    :value="artikel.artikelBeschreibung != null ? artikel.artikelBeschreibung : ''"
                                                    class="form-control"
                                                    name="artikelBeschreibung"
                                                    :disabled="true"
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-md-3">
                                                <label class="control-label" for="field_preis">{{ $t('einkaufsPreis') }}</label>
                                                <input
                                                    id="field_preis"
                                                    type="number"
                                                    class="form-control"
                                                    name="preis"
                                                    :value="artikel.einkaufsPreis != null ? artikel.einkaufsPreis : ''"
                                                    :disabled="true"
                                                />
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label class="control-label" for="field_preisnetto">{{ $t('verkaufsPreisExklUst') }}</label>
                                                <input
                                                    id="field_preisnetto"
                                                    type="number"
                                                    class="form-control"
                                                    name="preis"
                                                    :value="artikel.artikelPreisSet != null ? artikel.artikelPreisSet[0].preis : ''"
                                                    :disabled="true"
                                                />
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label class="control-label" for="field_mwst">{{ $t('mehrwertssteuer') }}</label>
                                                <input
                                                    id="field_mwst"
                                                    type="number"
                                                    class="form-control"
                                                    name="mwst"
                                                    :value="artikel.mehrwertssteuer != null ? artikel.mehrwertssteuer.mwst : ''"
                                                    :disabled="true"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </slot>
                            </div>
                            <div class="modal-footer">
                                <slot name="footer">
                                    <button type="button" class="btn dg-btn--cancel" @click="$emit('close')">
                                        <font-awesome-icon :icon="['fad', 'ban']" />&nbsp;<span>{{ $t('close') }}</span>
                                    </button>
                                </slot>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ArtikelVorschau',
    props: {
        id: {
            type: Number,
            default: null,
        },
    },
    computed: {
        ...mapGetters({
            artikel: 'artikel/data',
        }),
    },
    mounted() {
        this.$store.dispatch('artikel/get', this.id);
    },
};
</script>

<style></style>
<!-- language=json -->
<i18n>
    {
        "de": {
            "close": "Schließen",
            "artikelNummer": "Artikelnummer",
            "artikelBezeichnung": "Artikelbezeichnung",
            "artikelBeschreibung": "Artikelbeschreibung Langtext",
            "einkaufsPreis": "Einkaufspreis",
            "verkaufsPreisExklUst": "Verkaufspreis Netto",
            "mehrwertssteuer": "Mehrwertssteuer"
        },
        "en": {
            "close": "Close",
            "artikelNummer": "Artikelnummer",
            "artikelBezeichnung": "Artikelbezeichnung",
            "artikelBeschreibung": "Artikelbeschreibung Langtext",
            "einkaufsPreis": "Einkaufspreis",
            "verkaufsPreisExklUst": "Verkaufspreis Netto",
            "mehrwertssteuer": "Mehrwertssteuer"
        }
    }
</i18n>
