import Vue from 'vue';
import BenachrichtigungenService from './benachrichtigungen.service';
import * as _ from 'lodash';
import abstractStore from '../../store/abstractstore';

export const getDefaultSort = () => ({ currentSort: 'id', currentSortDir: 'asc' });

const getDefaultItem = () => ({
    editor: null,
    id: null,
    bezeichnung: null,
    aktiv: null,
    restrictions: null,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
    news: false,
    lastChecked: null,
    ...abstractStore.getAbstractValue(getDefaultSort().currentSort, getDefaultSort().currentSortDir),
});

const benachrichtigungen = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateInfo(state, data) {
            Vue.set(state, 'item.editor', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state) {
            Vue.set(state, 'item', getDefaultState().item);
            Vue.set(state, 'list', getDefaultState().list);
        },
        news(state, data) {
            Vue.set(state, 'news', data);
        },
        lastChecked(state, data) {
            Vue.set(state, 'lastChecked', data);
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async news(context, data) {
            context.commit('news', data);
            return data;
        },
        async lastChecked(context, data) {
            context.commit('lastChecked', data);
            return data;
        },
        async createInfo(context, params) {
            context.commit('updateInfo', params);
        },
        async create(context, client_data) {
            const { data } = await BenachrichtigungenService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await BenachrichtigungenService.update(client_data.id, client_data);
            context.commit('update', data);
            return data;
        },
        async get(context, id) {
            const { data } = await BenachrichtigungenService.get(id);
            context.commit('update', data);
            return data;
        },
        async resetStatus() {
            await BenachrichtigungenService.resetStatus();
        },
        async query(context, params) {
            const { headers, data } = await BenachrichtigungenService.query(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let size = params && params.params && params.params.size ? params.params.size : 20;
            let totalCount = headers['x-total-count'];
            let totalPages = 1;

            if (_.toNumber(totalCount) > 0 && _.toNumber(size) > 0) {
                totalPages = _.toNumber(totalCount) / _.toNumber(size);
            }

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async queryMyNews(context, params) {
            const { headers, data } = await BenachrichtigungenService.queryMyNews(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let size = params && params.params && params.params.size ? params.params.size : 20;
            let totalCount = headers['x-total-count'];
            let totalPages = 1;

            if (_.toNumber(totalCount) > 0 && _.toNumber(size) > 0) {
                totalPages = _.toNumber(totalCount) / _.toNumber(size);
            }

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            await BenachrichtigungenService.delete(slug);
        },
        async deactivate(context, slug) {
            const { data } = await BenachrichtigungenService.deactivate(slug);
            context.commit('updateList', data);
            return data;
        },
        async activate(context, slug) {
            const { data } = await BenachrichtigungenService.activate(slug);
            context.commit('updateList', data);
            return data;
        },
    },
    getters: {
        data(state) {
            return state.item;
        },
        info(state) {
            return state.item.info;
        },
        news(state) {
            return state.news;
        },
        lastChecked(state) {
            return state.lastChecked;
        },
    },
};

export default _.merge(benachrichtigungen, abstractStore.getDefaultStore());
