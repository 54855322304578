<template>
    <div>
        <v-select :value="value" :filterable="false" :searchable="false" :options="optionsList" label="vorname" @input="onChange">
            <template #list-header>
                <div class="text-center" style="padding-bottom: 10px">
                    <button type="button" class="btn btn-outline-primary" style="padding-top: 1px; padding-bottom: 2px" @click="createKundeKontakt">
                        <font-awesome-icon :icon="['fad', 'plus']" /> {{ $t('new') }}
                    </button>
                </div>
            </template>
            <template #option="option">
                <li>
                    <font-awesome-icon :icon="['fad', 'address-card']" fixed-width />&nbsp; {{ _.capitalize(option.geschlecht) }} {{ option.nachname }}
                    {{ option.vorname }}
                </li>
            </template>
        </v-select>

        <transition name="modal">
            <kunden-schnell-anlage v-if="showModal" :mode="false" @close="showModal = false" :kunde-edit="kunde" @close-modal="closeModal($event)" />
        </transition>
    </div>
</template>

<script>
import { kundemixin } from './mixins/kundemixin';
import _ from 'lodash';

export default {
    name: 'KontaktSearch',
    mixins: [kundemixin],
    props: {
        list: {
            type: Array,
            default: function () {
                return [];
            },
        },
        placeholder: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        kunde: {
            type: Object,
            default: null,
        },
        beleg: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            value: {
                vorname: null,
            },
            displayList: this.list,
            showModal: false,
        };
    },
    computed: {
        optionsList() {
            let list = [];
            this.kunde.kundeKontakte.forEach((x) => {
                if (x.vorname == null) {
                    x.vorname = '';
                }
                if (x.nachname == null) {
                    x.nachname = '';
                }
                if (x.geschlecht == null) {
                    x.geschlecht = null;
                }
                list.push(x);
            });
            list.sort((a, b) => (a.nachname > b.nachname ? 1 : -1));
            return list;
        },
    },
    mounted() {
        if (this.beleg.zuHanden != null && this.beleg.zuHanden != '') {
            this.optionsList.forEach((x) => {
                let test = _.capitalize(x.geschlecht) + ' ' + x.nachname + ' ' + x.vorname;
                if (test == this.beleg.zuHanden) {
                    this.value.vorname = test;
                }
            });
        }
    },
    methods: {
        closeModal(event) {
            this.onChange(event);
            this.showModal = false;
        },
        createKundeKontakt() {
            this.showModal = true;
        },
        onChange(val) {
            if (val == null) {
                this.value.vorname = null;
            } else {
                this.value.vorname = null;
                this.value.vorname = _.capitalize(val.geschlecht) + ' ' + val.vorname + ' ' + val.nachname;
            }

            this.$emit('input', val);
        },
        onSelect(val) {
            setTimeout(
                function () {
                    this.$emit('update:list', this.displayList);
                }.bind(this),
                0
            );
            this.$emit('select', val);
        },
        onRemove(val) {
            setTimeout(
                function () {
                    this.$emit('update:list', this.displayList);
                }.bind(this),
                0
            );
            this.$emit('remove', val);
        },
    },
};
</script>

<style scoped></style>

<i18n>
    {
        "de": {
            "placeholder": "Schnellsuche: Projekt",
            "deactivated": "deaktiviert",
            "noresult": "Keine Projekte gefunden",
            "new": "Neuen Kontakt hinzufügen"
        },
        "en": {
            "placeholder": "Fast search: project",
            "deactivated": "deaktiviert",
            "noresult": "No projects found",
            "new": "Neuen Kontakt hinzufügen"
        }
    }
</i18n>
