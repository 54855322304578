import Vue from 'vue';

const getDefaultItem = () => ({
    name: null,
    params: null,
    translation: null,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
});

const dashboard = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state) {
            Object.assign(state, getDefaultState());
        },
    },

    actions: {
        async clear(context) {
            context.commit('clear');
        },
        async update(context, client_data) {
            context.commit('update', client_data);
            return client_data;
        },
        async updateList(context, client_data) {
            context.commit('updateList', client_data);
            return client_data;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
    },
};

export default dashboard;
