import ApiService from '@/common/api.service';

const resource = 'belegfelddefinitionstypen';

const BelegfelddefinitionstypService = {
    async get(slug) {
        return ApiService.get(resource, slug);
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
};

export default BelegfelddefinitionstypService;
