import Vue from 'vue';
import RechnungskreiseService from './rechnungskreise.service';

const getDefaultItem = () => ({
    id: null,
    rechnungskreisName: '',
    rechnungsnummernPattern: '',
    firstRechnungsnummer: null,
    lastRechnungsnummer: null,
    nextRechnungsnummer: null,
    rksv: false,
    barRechnungOhneRksv: false,
    rechnungskreisIdRechnungslegung: null,
    rechnungskreisIdRKSV: null,
    rechnungskreisIdBarrechnung: null,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
    currentPage: 0,
    totalPages: 1,
});

const rechnungskreise = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state) {
            Vue.set(state, 'item', getDefaultState().item);
            Vue.set(state, 'list', getDefaultState().list);
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            const { data } = await RechnungskreiseService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await RechnungskreiseService.update(client_data.id, client_data);
            context.commit('update', data);
            return data;
        },
        async get(context, id) {
            const { data } = await RechnungskreiseService.get(id);
            context.commit('update', data);
            return data;
        },
        async query(context, params) {
            const { data } = await RechnungskreiseService.query(params);
            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            await RechnungskreiseService.delete(slug);
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
    },
};

export default rechnungskreise;
