import Vue from 'vue';
import HolidayEntryService from './holidayentry.service';

const getDefaultItem = () => ({
    id: null,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
});

const holidayEntry = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state) {
            Vue.set(state, 'item', getDefaultState().item);
            Vue.set(state, 'list', getDefaultState().list);
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            const { data } = await HolidayEntryService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await HolidayEntryService.update(client_data.id, client_data);
            context.commit('update', data);
            return data;
        },
        async get(context, id) {
            const { data } = await HolidayEntryService.get(id);
            context.commit('update', data);
            return data;
        },
        async query(context, params) {
            const { data } = await HolidayEntryService.query(params);
            context.commit('updateList', data);
            return data;
        },
        async queryString(context) {
            const { data } = await HolidayEntryService.get('asstring');
            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            await HolidayEntryService.delete(slug);
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
    },
};

export default holidayEntry;
