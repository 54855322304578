<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 text-center form-group">
                <h3>{{ header }}</h3>
            </div>
            <div class="col-md-6 offset-md-3 text-center form-group">
                <div class="icon icon--order-success svg">
                    <svg xmlns="http://www.w3.org/2000/svg" width="72px" height="72px">
                        <g fill="none" stroke="#F68C25" stroke-width="2">
                            <circle cx="36" cy="36" r="35" style="stroke-dasharray: 240px, 240px; stroke-dashoffset: 480px"></circle>
                            <path d="M17.417,37.778l9.93,9.909l25.444-25.393" style="stroke-dasharray: 50px, 50px; stroke-dashoffset: 0px"></path>
                        </g>
                    </svg>
                </div>
            </div>
            <div class="col-md-6 offset-md-3 form-group text-center">
                <p>{{ text }}</p>
            </div>
            <div v-if="showHome" class="col-md-6 offset-md-3 form-group text-center">
                <button type="button" class="btn btn-primary" @click="goToLogin">Zur Anmeldung</button>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/common/router';

export default {
    name: 'ActivateSuccess',
    props: {
        header: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        showHome: {
            type: Boolean,
            default: true,
        },
    },
    computed: {},
    mounted() {},
    methods: {
        goToLogin() {
            router.push({ name: 'login' });
        },
    },
};
</script>

<style></style>
