import store from '../../store/index';

export const warengruppeutils = {
    methods: {
        async getWarengruppen() {
            let warengruppen = [];

            store.dispatch('warengruppe/query', {}).then((result) => {
                result.forEach((warengruppe) => {
                    if (!warengruppe.geloescht) {
                        warengruppen.push(warengruppe);
                    }
                });
            });

            return warengruppen;
        },
        async getUntergruppen(params) {
            if (!params) {
                params = {};
            }
            let allUntergruppen = [];
            store.dispatch('untergruppe/query', { params }).then((result) => {
                result.forEach((untergruppe) => {
                    if (!untergruppe.geloescht) {
                        allUntergruppen.push(untergruppe);
                    }
                });
            });

            return allUntergruppen;
        },
    },
};
