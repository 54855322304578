<template>
    <div>
        <v-select :value="value" :placeholder="getPlaceholder()" :filterable="false" :options="options" label="strasse" @input="onChange" @search="onSearch">
            <template slot="no-options">
                <span v-if="searched && createLieferadresseFlag" class="error-text text-left">
                    <button type="button" class="btn btn-outline-primary" style="padding-top: 1px; padding-bottom: 2px" @click="createLieferadresse">
                        <font-awesome-icon :icon="['fad', 'plus']" /> {{ $t('new') }}
                    </button>
                </span>
                <span v-else>{{ getPlaceholder() }}</span>
            </template>
            <template #option="option">
                {{
                    (option.bezeichnung == null ? '' : option.bezeichnung + ', ') +
                    (option.firmenName == null ? '' : option.firmenName + ', ') +
                    option.strasse +
                    (option.hausNummer == null ? ', ' : ' ' + option.hausNummer + ', ') +
                    (option.plz == null ? ' ' : option.plz + ' ') +
                    option.ort
                }}
            </template>
        </v-select>
        <transition name="modal">
            <lieferadresse-schnellanlage
                v-if="showModal"
                :kunde="kunde"
                :mandant="mandant"
                :value="search_value"
                @close="showModal = false"
                @close-modal="closeModal($event)"
            />
        </transition>
    </div>
</template>

<script>
import _ from 'lodash';
import ApiService from '@/common/api.service';
import { kundemixin } from './mixins/kundemixin';
import { DEBOUNCE_DEFAULT_MILLIS } from '@/constants';

export default {
    name: 'AdresseSearch',
    mixins: [kundemixin],
    props: {
        value: {
            type: String,
            default: '',
        },
        createLieferadresseFlag: {
            type: Boolean,
            default: false,
        },
        kunde: {
            type: Object,
            default: null,
        },
        mandant: {
            type: Object,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            options: [],
            search_value: null,
            searched: false,
            showModal: false,
            openDropDown: false,
            valueSelected: false,
        };
    },
    mounted() {
        const resource = 'adressesk/' + this.kunde.id;
        ApiService.query(resource).then((res) => {
            this.options = res.data;
        });
    },
    methods: {
        getPlaceholder() {
            if (this.placeholder) {
                return this.placeholder;
            }
            return this.$t('placeholder');
        },
        onChange(val) {
            this.openDropDown = false;
            this.valueSelected = true;
            this.$emit('input', val);
        },
        onSearch(search, loading) {
            loading(true);
            if (!this.valueSelected) {
                this.openDropDown = true;
            }
            if (this.valueSelected) this.valueSelected = false;
            this.search_value = search;
            if (search.length > 0) {
                this.search(loading, search, this);
            } else {
                this.options = this.kunde.adresseLists;
                loading(false);
            }
        },
        createLieferadresse() {
            this.showModal = true;
        },
        closeModal(adresse) {
            this.showModal = false;
            this.onChange(adresse);
        },
        search: _.debounce((loading, search, vm) => {
            ApiService.query('adressessearch/' + vm.kunde.id, { params: { search: search } }).then((res) => {
                vm.searched = true;
                if (res && res.data && Array.isArray(res.data)) {
                    vm.options = res.data;
                } else {
                    vm.options = [];
                }
                loading(false);
            });
        }, DEBOUNCE_DEFAULT_MILLIS),
    },
};
</script>

<style scoped></style>

<!-- language=json -->
<i18n>
    {
        "de": {
            "placeholder": "abweichende Lieferadresse wählen",
            "new": "Neue Lieferadresse anlegen",
            "no-result": "Kein Ergebnis",
            "save": "Speichern",
            "cancel": "Abbrechen"
        },
        "en": {
            "placeholder": "select different billing adress",
            "new": "Create new billing adress",
            "no-result": "No result",
            "save": "Save",
            "cancel": "Cancel"
        }
    }
</i18n>
