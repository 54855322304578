import auth from '@/app/auth/auth.store';
import mandant from '@/app/mandant/mandant.store';
import * as constants from '@/constants';

// Sync with local storage.
if (sessionStorage.getItem(constants.STORAGE_KEY)) {
    const syncedState = JSON.parse(sessionStorage.getItem(constants.STORAGE_KEY));
    mandant.state = Object.assign(mandant.state, syncedState.mandant);
}
if (localStorage.getItem(constants.STORAGE_KEY)) {
    const syncedState = JSON.parse(localStorage.getItem(constants.STORAGE_KEY));
    auth.state = Object.assign(auth.state, syncedState.auth);
}

// LocalStorage plugin.
const sessionStoragePlugin = (store) => {
    store.subscribe((mutation, state) => {
        const syncedData = { mandant: state.mandant };

        sessionStorage.setItem(constants.STORAGE_KEY, JSON.stringify(syncedData));

        if (mutation.type === 'common/clear') {
            sessionStorage.removeItem(constants.STORAGE_KEY);
        }
    });
};

const localStoragePlugin = (store) => {
    store.subscribe((mutation, state) => {
        const syncedData = { auth: state.auth };

        localStorage.setItem(constants.STORAGE_KEY, JSON.stringify(syncedData));

        if (mutation.type === 'common/clear') {
            localStorage.removeItem(constants.STORAGE_KEY);
        }
    });
};

export { sessionStoragePlugin, localStoragePlugin };
