import Vue from 'vue';

const getDefaultState = () => ({
    isLoggedIn: false,
    accessToken: null,
    refreshToken: null,
    friendlyUsername: null,
    userId: null,
    authorities: [],
});

const auth = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            state = Object.assign({}, data);
        },
        updateFriendlyUsername(state, data) {
            Vue.set(state, 'friendlyUsername', data);
        },
        updateAuthorities(state, data) {
            Vue.set(state, 'updateAuthorities', data);
        },
        clear(state) {
            Object.assign(state, getDefaultState());
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        update({ commit }, data) {
            commit('update', data);
        },
        updateFriendlyUsername(context, data) {
            context.commit('updateFriendlyUsername', data);
        },
        updateAuthorities(context, data) {
            context.commit('updateAuthorities', data);
        },
    },

    getters: {
        getAuth(state) {
            return state;
        },
        hasRoleUser(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_USER')) return true;
            }
            return false;
        },
        hasRoleArtikelKatalogRead(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_ARTIKELKATALOG_READ')) return true;
            }
            return false;
        },
        hasRoleAdmin(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_ADMIN')) return true;
            }
            return false;
        },
        hasRoleZeiterfassung(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_ZEITERFASSUNG')) return true;
            }
            return false;
        },
        hasRoleAdminMandant(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_ADMIN_MANDANT')) return true;
            }
            return false;
        },
        hasRoleZeiterfassungAdmin(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_ZEITERFASSUNG_ADMIN')) return true;
            }
            return false;
        },
        hasRoleArbeitszeiterfassung(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_ARBEITSZEITERFASSUNG')) return true;
            }
            return false;
        },
        hasRoleArbeitszeiterfassungAdmin(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_ARBEITSZEITERFASSUNG_ADMIN')) return true;
            }
            return false;
        },
        hasRoleBenachrichtigungenAdmin(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_BENACHRICHTIGUNGEN_ADMIN')) return true;
            }
            return false;
        },
        hasRoleLagerplatz(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_LAGERPLATZ')) return true;
            }
            return false;
        },
        hasRoleProject(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_PROJECT')) return true;
            }
            return false;
        },
        hasRoleLagerplatzReadOnly(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_LAGERPLATZ_READONLY')) return true;
            }
            return false;
        },
        hasRoleLagerist(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_LAGERIST')) return true;
            }
            return false;
        },
        hasRoleAussendienstler(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_AUSSENDIENSTLER')) return true;
            }
            return false;
        },
        hasRoleLagerAdmin(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_LAGER_ADMIN')) return true;
            }
            return false;
        },
        hasRoleKassabuch(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_KASSABUCH')) return true;
            }
            return false;
        },
        hasRoleKassabuchAdmin(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_KASSABUCH_ADMIN')) return true;
            }
            return false;
        },
        hasRoleVerleihAdmin(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_VERLEIH_ADMIN')) return true;
            }
            return false;
        },
        hasRoleVerleihUser(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_VERLEIH')) return true;
            }
            return false;
        },
        hasRoleContentReadOnly(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_CONTENT_READONLY')) return true;
            }
            return false;
        },
        hasRoleContentWrite(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_CONTENT_WRITE')) return true;
            }
            return false;
        },
        hasRoleArtikelWrite(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_ARTIKEL_WRITE')) return true;
            }
            return false;
        },
        hasRolePreisKalkulator(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_PREIS_KALKULATOR')) return true;
            }
            return false;
        },
        hasRoleKalenderWrite(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_KALENDER_WRITE')) return true;
            }
            return false;
        },
        hasRoleKalenderReadOnly(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_KALENDER_READONLY')) return true;
            }
            return false;
        },
        hasRoleBestellungenTechniker(state) {
            if (Array.isArray(state.authorities)) {
                if (state.authorities.includes('ROLE_BESTELLUNGEN_TECHNIKER')) return true;
            }
            return false;
        },
    },
};

export default auth;
