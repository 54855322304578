<template>
    <div>
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <form name="editForm" role="form" @submit.prevent="createArticle()">
                            <div class="modal-header">
                                <slot name="header">
                                    <h5>{{ $t('new') }}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('close')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </slot>
                            </div>
                            <div class="modal-body">
                                <slot name="body">
                                    <div class="container">
                                        <div class="row">
                                            <div class="inner-addon right-addon form-group col-md-3">
                                                <label class="control-label" for="field_artikelNummer">{{ $t('artikelNummer') }}</label>
                                                <font-awesome-icon
                                                    :icon="['fad', 'sync']"
                                                    v-tooltip:top="'Artikelnummer generieren'"
                                                    class="icon"
                                                    @click="generateArtikelNummer"
                                                />
                                                <input
                                                    id="field_artikelNummer"
                                                    v-model="artikel.artikelNummer"
                                                    type="text"
                                                    class="form-control"
                                                    name="artikelNummer"
                                                    autocomplete="new-articlenumber"
                                                    required
                                                    @input="setProp('artikelNummer', $event.target.value)"
                                                />
                                                <validation-component :validation="$v.artikel.artikelNummer"></validation-component>
                                            </div>
                                            <div class="form-group col-md-9">
                                                <label class="control-label" for="field_artikelBezeichnung">{{ $t('artikelBezeichnung') }}</label>
                                                <input
                                                    id="field_artikelBezeichnung"
                                                    type="text"
                                                    class="form-control"
                                                    name="artikelBezeichnung"
                                                    autocomplete="new-articlelable"
                                                    :value="artikel.artikelBezeichnung"
                                                    required
                                                    @input="setProp('artikelBezeichnung', $event.target.value)"
                                                />
                                                <validation-component :validation="$v.artikel.artikelBezeichnung"></validation-component>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label class="control-label" for="field_preis">{{ $t('verkaufsPreisExklUst') }}</label>
                                                <currency-input
                                                    id="field_preis"
                                                    v-model.trim="preis"
                                                    name="preis"
                                                    class="form-control"
                                                    @input="setPreisProp('preis', $event.target.value)"
                                                />
                                                <validation-component :validation="$v.preis"></validation-component>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label for="field_mehrwertssteuer">{{ $t('mehrwertssteuer') }}</label>
                                                <select
                                                    id="field_mehrwertssteuer"
                                                    v-model="artikel.mehrwertssteuer"
                                                    class="form-control"
                                                    name="mehrwertssteuer"
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option v-for="mehrwertssteuer in mehrwertssteuern" :key="mehrwertssteuer.id" :value="mehrwertssteuer">
                                                        {{ mehrwertssteuer.mwst }}
                                                    </option>
                                                </select>
                                                <validation-component :validation="$v.artikel.mehrwertssteuer"></validation-component>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="field_untergruppe">{{ $t('unterGruppe') }}</label>
                                                <select id="field_untergruppe" v-model="artikel.untergruppe" class="form-control" name="untergruppe" required>
                                                    <option value=""></option>
                                                    <option v-for="untergruppe in untergruppen" :key="untergruppe.id" :value="untergruppe">
                                                        {{ untergruppe.unterGruppe }} - {{ untergruppe.bezeichnung }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row" v-if="woocommerceArtikel">
                                            <div class="col">
                                                <label class="control-label">Webshop Kategorien</label>
                                                <multiselect
                                                    v-model="artikel.webshopCategory"
                                                    :options="webshopCategories.filter((c) => c.id)"
                                                    :multiple="true"
                                                    :searchable="false"
                                                    :close-on-select="false"
                                                    :clear-on-select="false"
                                                >
                                                    <template slot="tag" slot-scope="option"
                                                        ><span class="custom__tag">{{ option.option.name }}</span></template
                                                    >
                                                    <template slot="option" slot-scope="option">
                                                        <div class="option__desc">
                                                            <span class="option__title">{{ option.option.name }}</span>
                                                        </div>
                                                    </template>
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </slot>
                            </div>
                            <div class="modal-footer">
                                <slot name="footer">
                                    <button type="button" class="btn dg-btn--cancel" @click="$emit('close')">
                                        <font-awesome-icon :icon="['fad', 'ban']" />&nbsp;<span>{{ $t('cancel') }}</span>
                                    </button>
                                    <button-spinner
                                        :style-class="'btn dg-btn--ok dg-pull-right'"
                                        :is-loading="isLoading"
                                        :mode="1"
                                        :text="$t('save')"
                                        :disabled="isLoading || $v.$anyError || $v.$invalid"
                                        :typ="'button'"
                                        :icon="'save'"
                                        @finished="isLoading = false"
                                        @action="
                                            isLoading = true;
                                            createArticle();
                                        "
                                    >
                                    </button-spinner>
                                </slot>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { artikelutils } from './mixins/artikelutils';
import CurrencyInput from './currency';
import { Multiselect } from 'vue-multiselect';

export default {
    name: 'ArtikelSchnellAnlage',
    components: { CurrencyInput, Multiselect },
    mixins: [artikelutils],
    props: {
        value: {
            type: String,
            default: '',
        },
        woocommerceArtikel: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            preis: 0,
            untergruppen: [],
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            artikel: 'artikel/data',
            mehrwertssteuern: 'mehrwertssteuer/list',
            webshopCategories: 'wccategory/list',
        }),
    },
    validations: {
        preis: {
            required,
        },
        artikel: {
            artikelNummer: {
                required,
            },
            artikelBezeichnung: {
                required,
            },
            mehrwertssteuer: {
                required,
            },
        },
    },
    mounted() {
        this.$store.dispatch('wccategory/query');
        this.$store.dispatch('untergruppe/query', {}).then((result) => {
            result.forEach((untergruppe) => {
                if (!untergruppe.geloescht) {
                    this.untergruppen.push(untergruppe);
                }
            });
        });
        this.$v.artikel.$touch();
        this.$store.dispatch('artikel/clear');
        this.$store.dispatch('mehrwertssteuer/query', {}).then(() => {
            this.artikel.mehrwertssteuer = artikelutils.methods.getDefaultMwst(this.mehrwertssteuern);
            if (this.woocommerceArtikel) {
                this.artikel.webshopId = this.woocommerceArtikel.product_id ? this.woocommerceArtikel.product_id : this.woocommerceArtikel.instance_id;
                this.artikel.webshopArtikel = true;
                this.artikel.locked = this.woocommerceArtikel.locked ? this.woocommerceArtikel.locked : false;
                this.artikel.artikelBezeichnung = this.woocommerceArtikel.name ? this.woocommerceArtikel.name : this.woocommerceArtikel.method_title;
                this.artikel.artikelNummer = this.woocommerceArtikel.sku;
                this.preis = this.woocommerceArtikel.price ? this.woocommerceArtikel.price : this.woocommerceArtikel.total;
            }
        });
    },
    methods: {
        generateArtikelNummer() {
            let that = this;
            this.$store.dispatch('artikel/getNextNumber').then(function (result) {
                if (result != 0) {
                    that.artikel.artikelNummer = result;
                }
            });
        },
        setProp(name, value) {
            this.artikel[name] = value;
            this.$v.artikel[name].$touch();
        },
        setPreisProp(name, value) {
            this[name] = value;
            this.$v[name].$touch();
        },
        createArticle() {
            this.artikel.artikelPreisSet = [
                {
                    preis: this.preis,
                    vonDatum: new Date(),
                },
            ];

            if (this.artikel.untergruppe === '') {
                this.artikel.untergruppe = null;
            }

            this.$store.dispatch('artikel/update', this.artikel).then((response) => {
                this.isLoading = false;
                this.$emit('close-modal', response);
            });
        },
    },
};
</script>

<style></style>
<!-- language=json -->
<i18n>
{
    "de": {
        "placeholder": "Schnellsuche: Artikelbezeichnung oder Artikelnummer",
        "new": "Neuen Artikel anlegen",
        "no-result": "Kein Ergebnis",
        "save": "Speichern",
        "cancel": "Abbrechen",
        "artikelNummer": "Artikelnummer",
        "artikelBezeichnung": "Artikelbezeichnung",
        "verkaufsPreisExklUst": "Verkaufspreis Netto",
        "mehrwertssteuer": "Mehrwertssteuer",
        "unterGruppe": "Untergruppe"
    },
    "en": {
        "placeholder": "Fast search: name of Customer or Customer No.",
        "new": "Create new customer",
        "no-result": "No result",
        "save": "Save",
        "cancel": "Cancel",
        "artikelNummer": "Artikelnummer",
        "artikelBezeichnung": "Artikelbezeichnung",
        "verkaufsPreisExklUst": "Verkaufspreis Netto",
        "mehrwertssteuer": "Mehrwertssteuer",
        "unterGruppe": "Untergruppe"
    }
}
</i18n>
