import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'zeiteintrag';
const resourceTimer = 'timersync';

const ZeiterfassungService = {
    async get(slug) {
        return ApiService.get(resource, slug);
    },
    async query(params) {
        return ApiService.query('zeiteintraguser', params);
    },
    async queryToggleTime(slug) {
        return ApiService.get(resourceTimer + '/typ', slug);
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async updateTimer(params) {
        let promise = null;
        try {
            promise = await ApiService.put(resourceTimer, params);
            Toast.showSaveOkToastI18n('Timer gestartet');
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async delete(slug) {
        let promise = null;
        try {
            promise = await ApiService.delete(resource, slug);
            Toast.showDeleteOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async deleteTimer(slug) {
        let promise = null;
        try {
            promise = await ApiService.delete(resourceTimer, slug);
            Toast.showDeleteOkToastI18n('Timer gestoppt');
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
};

export default ZeiterfassungService;
