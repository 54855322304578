<template>
    <div>
        <v-select
            label="bezeichnung"
            :placeholder="$t('placeholder')"
            :value="value"
            :filterable="false"
            :options="options"
            @input="onChange"
            @search="onSearch"
        >
            <template slot="no-options">
                {{ $t('placeholder') }}
            </template>
        </v-select>
    </div>
</template>

<script>
import _ from 'lodash';
import ApiService from '@/common/api.service';
import { kundemixin } from './mixins/kundemixin';
import { DEBOUNCE_DEFAULT_MILLIS } from '@/constants';

export default {
    name: 'ProjektSearch',
    mixins: [kundemixin],
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            options: [],
        };
    },
    methods: {
        onChange(val) {
            this.$emit('input', val);
        },
        onSearch(search, loading) {
            loading(true);
            if (search.length > 0) {
                this.search(loading, search, this);
            } else {
                loading(false);
            }
        },
        search: _.debounce((loading, search, vm) => {
            const resource = 'projektsearch';
            vm.options = [];
            ApiService.query(resource, { params: { search: search } }).then((res) => {
                if (res && res.data && Array.isArray(res.data.projektList)) {
                    _.forEach(res.data.projektList, function (value) {
                        const kundeBezeichnung = value.kunde ? kundemixin.methods.getKundenBezeichnungOrName(value.kunde) : '';
                        value.bezeichnung = value.bezeichnung + ' | ' + kundeBezeichnung;
                    });

                    vm.options = res.data.projektList;
                    loading(false);
                }
            });
        }, DEBOUNCE_DEFAULT_MILLIS),
    },
};
</script>

<style scoped></style>

<i18n>
    {
        "de": {
            "placeholder": "Schnellsuche: Projekt"
        },
        "en": {
            "placeholder": "Fast search: project"
        }
    }
</i18n>
