import resize from './resize';
import VueTextareaAutogrowDirective from './autosize';
import VueTooltip from './tooltip';

const GlobalDirectives = {
    install(Vue) {
        Vue.directive('resize', resize);
        Vue.use(VueTextareaAutogrowDirective);
        Vue.use(VueTooltip);
    },
};

export default GlobalDirectives;
