<template>
    <div>
        <div class="form-row">
            <div class="col-12">
                <div class="input-group">
                    <v-select
                        :placeholder="$t('placeholder')"
                        :value="value"
                        :searchable="false"
                        :filterable="false"
                        :options="belegtypen"
                        class="artikel-bezeichnung-responsive"
                        @input="onChange"
                        ref="dropdown"
                    >
                        <template slot="option" slot-scope="option">
                            <span>{{ _.capitalize(option.label) }}</span>
                        </template>
                    </v-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BelegtypSearch',
    props: {
        value: {
            type: String,
            default: '',
        },
        belegtyp: {
            type: Object,
            default: null,
        },
        belegtypen: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            options: [],
        };
    },
    methods: {
        onChange(val) {
            this.$emit('input', val);
        },
        closeModal(belegtyp) {
            this.showModalDetail = false;
            this.showModal = false;
            this.onChange(belegtyp);
        },
    },
};
</script>

<style scoped></style>

<!-- language=json -->
<i18n>
{
    "de": {
        "placeholder": "Filtern nach Belegstyp"
    },
    "en": {
        "placeholder": "Order by Beleg"
    }
}
</i18n>
