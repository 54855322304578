<template>
    <div id="hoeapp-wrapper" class="hoe-hide-lpanel" hoe-device-type="desktop">
        <navbar @showNews="showNews = true"></navbar>

        <div id="hoeapp-container" :hoe-color-type="colorType" hoe-lpanel-effect="shrink" class="hoe-minimized-lpanel">
            <sidebar-component></sidebar-component>
            <div v-if="updateExists">
                <transition name="modal">
                    <div class="modal-mask">
                        <div class="modal-wrapper">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">{{ $t('update') }}</h5>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" @click="updateExists = false">
                                            {{ $t('updatelater') }}
                                        </button>
                                        <button type="button" class="btn btn-primary" @click="refreshApp">
                                            {{ $t('updatenow') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <div class="install" v-tooltip:top="$t('install')">
                <div class="install-content">
                    <a class="install-button" style="cursor: pointer; bottom: 30px !important; top: auto" :style="{ display: installBtn }" @click="installer()">
                        <i class="far fa-download"></i>
                    </a>
                </div>
            </div>
            <section id="main-content">
                <div class="container-fluid">
                    <div class="card card-body bg-light main-content" style="padding-top: 30px; padding-left: 0; padding-right: 0">
                        <router-view></router-view>
                    </div>

                    <div style="height: 20px"></div>
                    <div id="footer" class="footer">
                        <p class="text-center">
                            <span translate="footer">h&ouml;rb</span> &copy; 2015 - {{ year }} by
                            <a href="https://hilarion5.com/" rel="noreferrer" target="_blank" style="color: #f68c25">hilarion 5</a>
                        </p>
                    </div>
                </div>
            </section>
        </div>

        <div v-if="showNews">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper" style="padding-top: 20px">
                        <div role="document">
                            <div v-for="info in newsList" v-if="info.isActive" :key="info.id" class="modal-content" style="max-width: 80%">
                                <div class="modal-header">
                                    <h5 id="staticBackdropLabel" class="modal-title">
                                        {{ $t('news') }}
                                        <font-awesome-icon :icon="['fad', 'gift']" style="color: #f68c25" />&nbsp;<span></span>
                                    </h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" @click="closeNews()">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body modal-body-add">
                                    <div class="form-group info-img">
                                        <span v-html="info.text"></span>
                                    </div>
                                </div>
                                <div class="news-pagination">
                                    <v-pagination
                                        v-if="newsList.length > 1"
                                        v-model="currentPageBatchBook"
                                        :page-count="totalPageBatchBook"
                                        :classes="paginationStyleClasses"
                                    ></v-pagination>
                                </div>
                                <div class="modal-footer">
                                    <input id="notshowinganymore" v-model="notshowinganymore" type="checkbox" class="form-control-checkbox" /><span>{{
                                        $t('notshowanymore')
                                    }}</span>
                                    <button type="button" class="btn btn-primary pl-1" @click="closeNews()">
                                        <font-awesome-icon :icon="['fad', 'check']" />&nbsp;<span>{{ $t('ok') }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import navbar from './app/navigation/navbar';
import moment from 'moment';
import vPagination from 'vue-plain-pagination';
import * as constants from '@/constants';
import SidebarComponent from '@/app/navigation/sidebar.vue';

export default {
    name: 'App',
    components: {
        SidebarComponent,
        navbar,
        vPagination,
    },
    data() {
        return {
            paginationStyleClasses: constants.PAGINATION_CLASSES,
            year: new Date().getFullYear(),
            refreshing: false,
            registration: null,
            updateExists: false,
            installBtn: 'none',
            installer: undefined,
            showNews: false,
            notshowinganymore: false,
            pageBatchBook: 0,
            initialLoad: false,
        };
    },
    computed: {
        ...mapGetters({
            account: 'account/data',
            auth: 'auth/getAuth',
            newsList: 'benachrichtigungen/list',
            newsavailable: 'benachrichtigungen/news',
            lastChecked: 'benachrichtigungen/lastChecked',
        }),
        colorType: function () {
            // return 'lpanel-bg8';
            if (window.location.href.indexOf('http://localhost:') === 0 || window.location.href.indexOf('http://127.0.0.1:') === 0) {
                return 'lpanel-bg7';
            } else if (window.location.href.indexOf('https://dev.hoerb.at/') === 0) {
                return 'lpanel-bg9';
            } else {
                return 'lpanel-bg8';
            }
        },
        currentPageBatchBook: {
            get() {
                return this.pageBatchBook + 1;
            },
            set: function (newValue) {
                this.pageBatchBook = newValue - 1;
                this.newsList.forEach((x) => {
                    x.isActive = false;
                });
                this.newsList[newValue - 1].isActive = true;
            },
        },
        totalPageBatchBook: {
            get() {
                return this.newsList.length;
            },
        },
        isAuthenticated() {
            return this.$store.state.auth.isLoggedIn;
        },
    },
    watch: {
        $route(to, from) {
            if (
                this.initialLoad == false &&
                (this.lastChecked == null || (this.lastChecked != null && this.lastChecked < moment(new Date()).format('YYYY-MM-DD')))
            ) {
                this.loadInfo();
            }
            if (from.name == 'login' && to.name == 'home') {
                this.loadInfo();
            }
            // route changed - close max. menü
            this.closeMenu();
        },
    },
    created() {
        let installPrompt;

        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            installPrompt = e;
            this.installBtn = 'block';
        });

        this.installer = () => {
            this.installBtn = 'none';
            installPrompt.prompt();
            installPrompt.userChoice.then((result) => {
                if (result.outcome === 'accepted') {
                    console.log('Install accepted!');
                } else {
                    console.log('Install denied!');
                }
            });
        };

        document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
        document.addEventListener('wheel', function () {
            if (document.activeElement.type === 'number') {
                document.activeElement.blur();
            }
        });

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            });
        }
    },
    async mounted() {
        if(this.isAuthenticated){
            await this.loadAccount();
            this.loadBelegfeldDefinition();
            this.initialLoad = true;
            this.loadInfo();
        }

        const expandMenu = document.getElementById('expand');
        const expandMenuBack = document.getElementById('expandIconHeader');

        expandMenu.addEventListener(
            'click',
            function () {
                this.openMenu();
            }.bind(this),
            false
        );

        expandMenuBack.addEventListener(
            'click',
            function () {
                this.closeMenu();
            }.bind(this),
            false
        );
    },
    methods: {
        openMenu() {
            const panel = document.getElementById('hoe-left-panel');
            const coll = document.getElementById('hoeapp-container');
            const headerBig = document.getElementById('headerBig');
            const headerSmall = document.getElementById('headerSmall');
            const headerExpand = document.getElementById('headerExpand');
            const icon = document.getElementById('expandIcon');

            icon.className = 'rotateIcon down';
            coll.className = '';
            panel.className = 'lpanel-slide-out';
            headerExpand.style = 'display: block';
            icon.style = 'display: none';
            headerBig.style = 'display: block';
            headerSmall.style = 'display: none';
        },
        closeMenu() {
            const panel = document.getElementById('hoe-left-panel');
            const coll = document.getElementById('hoeapp-container');
            const headerBig = document.getElementById('headerBig');
            const headerSmall = document.getElementById('headerSmall');
            const headerExpand = document.getElementById('headerExpand');
            const icon = document.getElementById('expandIcon');

            icon.className = 'rotateIcon';
            panel.className = 'lpanel-slide-back';
            coll.className = 'hoe-minimized-lpanel';
            headerExpand.style = 'display: none';
            icon.style = 'display: block';
            headerBig.style = 'display: none';
            headerSmall.style = 'display: block';
        },
        closeNews() {
            if (this.notshowinganymore) {
                this.account.newsread = true;
                this.$store.dispatch('account/update', this.account);
                this.$store.dispatch('benachrichtigungen/news', false);
            }
            this.showNews = false;
            this.initialLoad = false;
        },
        loadInfo() {
            if (this.isAuthenticated) {
                this.$store.dispatch('account/get', this.auth.userId).then((acc) => {
                    this.$store.dispatch('benachrichtigungen/queryMyNews').then(() => {
                        if (!acc.newsread && this.newsList != null && this.newsList[0] != null) {
                            this.newsList[0].isActive = true;
                            this.showNews = true;

                            this.$store.dispatch('benachrichtigungen/lastChecked', moment(new Date()).format('YYYY-MM-DD'));
                            this.$store.dispatch('benachrichtigungen/news', true);
                        } else {
                            this.$store.dispatch('benachrichtigungen/news', false);
                        }
                    });
                });
            }
        },
        loadAccount() {
            this.$store.dispatch('account/get', this.auth.userId);
        },
        loadBelegfeldDefinition() {
            this.$store.dispatch('belegfelddefinition/query');
        },
        showRefreshUI(e) {
            this.registration = e.detail;
            this.updateExists = true;
        },
        refreshApp() {
            this.updateExists = false;
            if (!this.registration || !this.registration.waiting) {
                return;
            }
            this.registration.waiting.postMessage('skipWaiting');
        },
    },
};
</script>

<style>
.rotateIcon {
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.rotateIcon.down {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-left: 40px;
}

#main-content {
    margin-left: 50px !important;
    position: relative;
}

.lpanel-slide-out {
    transition: all 0.5s ease;
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
    overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.lpanel-slide-out::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.lpanel-slide-out {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.lpanel-slide-back {
    transition: all 0.5s ease;
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
}

.custom__tag {
    padding: 0.5rem;
    margin-right: 0.2rem;
    margin-top: 0.2rem;
    margin-bottom: 0.1rem;
    background-color: #f68c25;
    display: inline-block;
    border-radius: 5px;
}

.multiselect__option--highlight {
    background: rgb(246, 140, 37) !important;
    outline: none;
}

.multiselect__option--highlight::after {
    background: rgb(246, 140, 37) !important;
    outline: none;
}

.multiselect__tags {
    padding: 10px 40px 8px 8px !important;
    color: #fff !important;
    font-weight: bold;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    padding-top: 0px !important;
}

.modal-body-add {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

@media (max-width: 420px) {
    .modal-content {
        zoom: 0.6;
    }
}

.vdp-datepicker > div > input {
    background-color: white !important;
}

.vdp-datepicker > div > input:disabled {
    background-color: gainsboro !important;
}

.vdp-datepicker .cell.day.highlighted {
    background: white;
    border: 2px dotted black;
}

.news-pagination {
    padding: 0.75rem;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

.info-img img {
    max-width: 90%;
}
</style>

<!-- language=json -->
<i18n>
{
    "de": {
        "update": "Update verfügbar",
        "updatenow": "Aktualisierung starten",
        "updatelater": "Später ausführen",
        "install": "Download als Desktop Anwendung",
        "ok": "Schließen",
        "notshowanymore": "Nicht mehr anzeigen",
        "news": "Neuigkeiten"
    },
    "en": {
        "update": "Update available",
        "updatenow": "Start update",
        "updatelater": "Update later",
        "install": "Download as desktop application",
        "ok": "Schließen",
        "notshowanymore": "not show anymore",
        "news": "News available"
    }
}
</i18n>
