import Vue from 'vue';
import NoteService from './note.service';

const getDefaultItem = () => ({
    id: null,
    text: null,
    datum: null,
    user: null,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
});

const note = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state) {
            Object.assign(state, getDefaultState());
        },
    },

    actions: {
        async clear(context) {
            context.commit('clear');
        },
        async create(context, client_data) {
            const { data } = await NoteService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await NoteService.update(client_data.id, client_data);
            context.commit('update', data);
            return data;
        },
        async get(context, id) {
            const { data } = await NoteService.get(id);
            context.commit('update', data);
            return data;
        },
        async query(context, params) {
            const { data } = await NoteService.query(params);
            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            await NoteService.delete(slug);
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
    },
};

export default note;
