import Toast from '@/common/toast';
import store from '../../store/index';
import ApiService from '@/common/api.service';
import { saveAs } from 'file-saver';
import ArtikelService from '@/app/artikel/artikel.service';

export const artikelutils = {
    methods: {
        async deleteItem(artikel, active, translateDeactivateMesage, translateActivateMesage) {
            let promise = null;
            if (!active) {
                promise = await Toast.askDeactivate(translateDeactivateMesage);
            } else {
                promise = await Toast.askActivate(translateActivateMesage);
            }
            if (promise) {
                if (artikel.webshopId && !active) {
                    artikel.webshopArtikel = false;
                    await ArtikelService.create(artikel);
                    ArtikelService.saveArticleWebshop(artikel).then((data) => {
                        console.log('WooCommerce Response', data);
                    });
                }
                return store.dispatch('artikel/delete', artikel.id).then(() => promise);
            } else {
                return promise;
            }
        },
        downlaodExcelfile() {
            return ApiService.getBlob('/artikel/excel', {
                responseType: 'arraybuffer',
            }).then((response) => {
                let file = new Blob([response.data], { type: response.headers['content-type'] });
                saveAs(file, response.headers['x-filename']);
            });
        },
        getDefaultMwst(mehrwertssteuern) {
            let result = {};
            mehrwertssteuern.forEach((mwst) => {
                if (mwst.defaultMwst) {
                    result = mwst;
                }
            });

            return result;
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
    },
};
