<template>
    <span>
        <font-awesome-icon v-if="sortIcon(sortTyp) && currentSortDir === 'asc'" :icon="['fad', 'sort-up']" />
        <font-awesome-icon v-if="sortIcon(sortTyp) && currentSortDir === 'desc'" :icon="['fad', 'sort-down']" />
    </span>
</template>

<script>
export default {
    name: 'SortIcon',
    props: {
        sortTyp: {
            type: String,
            default: '',
        },
        currentSort: {
            type: String,
            default: '',
        },
        currentSortDir: {
            type: String,
            default: '',
        },
    },
    methods: {
        sortIcon(sort) {
            const sortArray = this.currentSort.split(',');
            for (let i = 0; i < sortArray.length; i++) {
                if (sortArray[i] === sort) {
                    return true;
                }
            }
        },
    },
};
</script>
