<template>
    <div>
        <div class="form-row">
            <div class="col-12">
                <div class="input-group">
                    <v-select
                        :placeholder="$t('placeholder')"
                        :value="value"
                        :filterable="false"
                        :options="options"
                        label="artikelBezeichnung"
                        class="artikel-bezeichnung-responsive"
                        @input="onChange"
                        @search="onSearch"
                        ref="dropdown"
                    >
                        <template slot="option" slot-scope="option">
                            <span>{{ option.artikelBezeichnung }} ({{ option.artikelNummer }})</span>
                        </template>
                        <template slot="no-options">
                            <span v-if="searched && createArtikelFlag" class="error-text text-left">
                                <button type="button" class="btn btn-outline-primary" style="padding-top: 1px; padding-bottom: 2px" @click="createArtikel">
                                    <font-awesome-icon :icon="['fad', 'plus']" /> {{ $t('new') }}
                                </button>
                            </span>
                            <span v-else>{{ $t('placeholder') }}</span>
                        </template>
                    </v-select>
                    <button v-if="hasDetailSuche" type="button" class="btn btn-secondary btn-sm" style="margin-left: 5px" @click="showModalDetail = true">
                        <font-awesome-icon :icon="['fad', 'file-search']" />
                    </button>
                    <button v-if="hasQrScanSuche" type="button" class="btn btn-secondary btn-sm" style="margin-left: 5px" @click="showModalQrScan = true">
                        <font-awesome-icon :icon="['fad', 'qrcode']" />
                    </button>
                    <artikel-bilder :artikel="artikel"></artikel-bilder>
                </div>
            </div>
        </div>
        <transition name="modal">
            <artikel-schnell-anlage v-if="showModal" :value="search_value" @close="showModal = false" @close-modal="closeModal($event)" />
        </transition>
        <artikel-detail-suche v-if="showModalDetail" @close="showModalDetail = false" @close-modal="closeModal($event)" />
        <qr-scan v-if="showModalQrScan" @scan="handleScan" @close="showModalQrScan = false" @close-modal="showModalQrScan = false" />
    </div>
</template>

<script>
import _ from 'lodash';
import Toast from '@/common/toast';
import ArtikelService from '@/app/artikel/artikel.service';

export default {
    name: 'ArtikelSearch',
    props: {
        value: {
            type: String,
            default: '',
        },
        artikel: {
            type: Object,
            default: null,
        },
        createArtikelFlag: {
            type: Boolean,
            default: false,
        },
        hasDetailSuche: {
            type: Boolean,
            default: false,
        },
        hasQrScanSuche: {
            type: Boolean,
            default: false,
        },
        verleihbar: {
            type: Boolean,
            default: null,
        },
        geloescht: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            options: [],
            search_value: null,
            searched: false,
            showModal: false,
            showModalDetail: false,
            showModalQrScan: false,
        };
    },
    methods: {
        onChange(val) {
            this.$emit('input', val);
        },
        onSearch(search, loading) {
            loading(true);
            this.search_value = search;
            if (search.length > 0) {
                this.search(loading, search, this.verleihbar, this.geloescht, this);
            } else {
                loading(false);
            }
        },
        createArtikel() {
            this.showModal = true;
        },
        closeModal(artikel) {
            this.showModalDetail = false;
            this.showModal = false;
            this.onChange(artikel);
        },
        handleScan(scannedValue) {
            this.searched = false;
            ArtikelService.artikelSuche(scannedValue, this.verleihbar, this.geloescht).then((res) => {
                if (res && res.data && Array.isArray(res.data.artikelList)) {
                    const artikelsFound = res.data.artikelList;
                    if (artikelsFound && artikelsFound.length === 1) {
                        this.onChange(artikelsFound[0]);
                    } else if (artikelsFound.length > 1) {
                        this.options = res.data.artikelList;
                        this.$refs.dropdown.open = true;
                    } else {
                        Toast.askCustomDialog(this.$t('not_found'), 'OK');
                    }
                    this.searched = true;
                    this.showModalQrScan = false;
                }
            });
        },
        search: _.debounce((loading, search, verleihbar, geloescht, vm) => {
            vm.options = [];
            vm.searched = false;

            ArtikelService.artikelSuche(search, verleihbar, geloescht).then((res) => {
                vm.searched = true;
                if (res && res.data && Array.isArray(res.data.artikelList)) {
                    vm.options = res.data.artikelList;
                } else {
                    vm.options = [];
                }
                loading(false);
                console.log(vm.options);
            });
        }, 200),
    },
};
</script>

<style scoped></style>

<!-- language=json -->
<i18n>
{
    "de": {
        "placeholder": "Schnellsuche: Artikelbezeichnung oder Artikelnummer",
        "new": "Neuen Artikel anlegen",
        "no-result": "Kein Ergebnis",
        "save": "Speichern",
        "cancel": "Abbrechen",
        "not_found": "Artikel nicht gefunden"
    },
    "en": {
        "placeholder": "Fast search: name or number of article",
        "new": "Create new article",
        "no-result": "Kein Ergebnis",
        "save": "Speichern",
        "cancel": "Abbrechen",
        "not_found": "Article not found"
    }
}
</i18n>
