/* eslint-disable no-console */
import router from '@/common/router';
import store from '@/store';
import auth from './index';
import JwtService from '@/common/jwt.service';
import ApiService from '@/common/api.service';
import axios from 'axios';

const LOGIN_URL = 'auth/login';
const CODE_VALIDATION_URL = 'auth/validate2fa';

// const CLIENT_SECRET = 'ZGVtb2FwcDpkZW1vcGFzcw==' // Base64(client_id:client_secret) "demoapp:demopass"

export default {
    login(creds, redirect, callback) {
        let login_json = {};
        login_json.usernameOrEmail = creds.username;
        login_json.password = creds.password;

        axios({
            method: 'post',
            url: LOGIN_URL,
            headers: {
                // 'Authorization': 'Basic ' + CLIENT_SECRET
            },
            data: login_json,
        })
            .then((response) => {
                if (response.data && response.data.forceTokenCheck) {
                    auth.store2FaToken(response);
                    router.push({ name: 'validate2fa' });
                } else {
                    auth.storeToken(response);
                    ApiService.mount401Interceptor();
                    let lastmandant = {};
                    lastmandant.id = JwtService.getLastSelectedMandantID();
                    if (lastmandant.id == null) lastmandant = null;

                    store.dispatch('mandant/loadAllandUpdateSelected', lastmandant).then(() => {
                        if (redirect) router.push({ name: redirect });
                        if (callback) callback(true);
                    });
                }
            })
            .catch((error) => {
                let errorMessage = null;

                if (error.response) errorMessage = error.response.status;
                else if (error.request) errorMessage = 'no response from server';
                else errorMessage = error.message;

                //TODO FAILED LOGIN
                console.log(errorMessage);

                if (callback) callback(false);
            });
    },

    validate2FaToken(token, redirect, callback) {
        const totp = {
            validationCode: token,
        };

        axios({
            method: 'post',
            url: CODE_VALIDATION_URL,
            data: totp,
        })
            .then((response) => {
                auth.storeToken(response);
                ApiService.mount401Interceptor();
                if (redirect) router.push({ name: redirect });
                if (callback) callback({ success: true });
            })
            .catch((error) => {
                let returnObject = {
                    success: false,
                };
                if (error.response && error.response.status === 401) {
                    returnObject.status = 401;
                }
                if (callback) callback(returnObject);
            });
    },

    logout() {
        store.dispatch('common/clear');
        JwtService.destroyToken();
        JwtService.destroyMandant();
        ApiService.cleanUp();
        if (router.currentRoute.name !== 'login') {
            router.push({ name: 'login' });
        }
    },

    createAccount(account, callback) {
        axios({
            method: 'post',
            url: '/register',
            data: account,
        })
            .then((response) => {
                console.log('Account created:', response);
                if (callback) callback(true);
            })
            .catch((error) => {
                if (callback) callback(error);
            });
    },

    activateAccount(key, callback) {
        axios({
            method: 'get',
            url: '/activate',
            params: {
                key: key,
            },
        })
            .then((response) => {
                console.log('Account activated:', response);
                if (callback) callback(true);
            })
            .catch((error) => {
                if (callback) callback(error);
            });
    },
};
