import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'firmengruppe';

const FirmengruppeService = {
    async get(slug) {
        return ApiService.get(resource, slug);
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async delete(params) {
        let promise = null;
        try {
            promise = await ApiService.delete(resource, params.slug);
            Toast.showDeleteOkToastI18n();
        } catch (e) {
            Toast.showDeleteErrorToastI18n(params.deleteErrorText);
        }
        return promise;
    },
};

export default FirmengruppeService;
