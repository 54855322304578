<template>
    <div>
        <v-select
            v-if="firmenGruppeKunde"
            :dropdown-should-open="() => openDropDown"
            :value="value"
            :placeholder="getPlaceholder()"
            :filterable="false"
            :options="options"
            :firmen-gruppe-kunde="firmenGruppeKunde"
            :no-drop="noDrop"
            :loading="isLoading"
            label="bezeichnung"
            @input="onChange"
            @search="onSearch"
        >
            <template slot="no-options">
                <span v-if="searched && createKundeFlag" class="error-text text-left">
                    <button type="button" class="btn btn-outline-primary" style="padding-top: 1px; padding-bottom: 2px" @click="createKunde">
                        <font-awesome-icon :icon="['fad', 'plus']" /> {{ $t('new') }}
                    </button>
                </span>
                <span v-else>{{ getPlaceholder() }}</span>
            </template>
            <template #option="option">
                <font-awesome-icon v-if="option.firmenGruppe" :icon="['fas', 'building']" fixed-width />&nbsp;
                {{ option.bezeichnung }}
            </template>
        </v-select>

        <v-select
            v-if="!firmenGruppeKunde"
            :value="value"
            :placeholder="getPlaceholder()"
            :filterable="false"
            :options="options"
            :no-drop="noDrop"
            :loading="isLoading"
            label="bezeichnung"
            @input="onChange"
            @search="onSearch"
        >
            <template slot="no-options">
                <span v-if="searched && createKundeFlag" class="error-text text-left">
                    <button type="button" class="btn btn-outline-primary" style="padding-top: 1px; padding-bottom: 2px" @click="createKunde">
                        <font-awesome-icon :icon="['fad', 'plus']" /> {{ $t('new') }}
                    </button>
                </span>
                <span v-else>{{ getPlaceholder() }}</span>
            </template>
            <template #option="option">
                <font-awesome-icon v-if="option.firmenGruppe" :icon="['fas', 'building']" fixed-width />&nbsp;
                {{ option.bezeichnung }}
            </template>
        </v-select>

        <transition name="modal">
            <kunden-schnell-anlage v-if="showModal" :mode="true" :value="search_value" @close="showModal = false" @close-modal="closeModal($event)" />
        </transition>
    </div>
</template>

<script>
import _ from 'lodash';
import ApiService from '@/common/api.service';
import { kundemixin } from './mixins/kundemixin';
import { DEBOUNCE_DEFAULT_MILLIS } from '@/constants';

export default {
    name: 'KundeSearch',
    mixins: [kundemixin],
    props: {
        value: {
            type: String,
            default: '',
        },
        searchValue: {
            type: String,
            default: '',
        },
        createKundeFlag: {
            type: Boolean,
            default: false,
        },
        noDrop: {
            type: Boolean,
            default: false,
        },
        bestellung: {
            type: Boolean,
            default: false,
        },
        firmenGruppeKunde: {
            type: Number,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        lieferantSearch: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            options: [],
            search_value: null,
            searched: false,
            showModal: false,
            openDropDown: false,
            valueSelected: false,
            isLoading: null,
        };
    },
    mounted() {
        if (this.firmenGruppeKunde) {
            this.openDropDown = true;
            const resource = 'kundesfg/' + this.firmenGruppeKunde;
            ApiService.query(resource).then((res) => {
                this.options = res.data;
                this.$emit('kunden-options', res.data);
            });
        }
        if (this.searchValue && this.options.length === 0) {
            this.onSearch(this.searchValue, this.setLoading);
        }
    },
    // updated() {
    //     if (
    //         !_.isNull(this.value) &&
    //         !_.isUndefined(this.value) &&
    //         !_.isNull(this.value.firma) &&
    //         !_.isEmpty(this.value.firma) &&
    //         this.value.firma !== 'firma'
    //     ) {
    //         this.value.bezeichnung = kundemixin.methods.getKundenBezeichnungOrName(this.value);
    //     }
    // },
    methods: {
        getPlaceholder() {
            if (this.placeholder) {
                return this.placeholder;
            }
            if (this.bestellung) {
                return this.$t('placeholderBestellung');
            }
            if (this.lieferantSearch) {
                return this.$t('placeholderLieferant');
            }
            return this.$t('placeholder');
        },
        onChange(val) {
            this.openDropDown = false;
            this.valueSelected = true;
            this.$emit('input', val);
        },
        onSearch(search, loading) {
            loading(true);
            if (!this.valueSelected) {
                this.openDropDown = true;
            }
            this.valueSelected = false;
            this.search_value = search;

            if (search.length > 0) {
                this.search(loading, search, this.lieferantSearch, this);
            } else {
                loading(false);
            }
        },
        createKunde() {
            this.showModal = true;
        },
        closeModal(kunde) {
            this.showModal = false;
            this.onChange(kunde);
        },
        setLoading(loading) {
            this.isLoading = loading;
            this.$emit('is-loading', this.isLoading);
        },
        search: _.debounce((loading, search, isLieferantSearch, vm) => {
            let resource = 'kundessearch';
            if (isLieferantSearch) {
                resource = 'lieferantsearch';
            }
            ApiService.query(resource, { params: { search: search } }).then((res) => {
                vm.searched = true;
                if (res && res.data && Array.isArray(res.data.kundeList)) {
                    _.forEach(res.data.kundeList, function (value) {
                        value.bezeichnung = kundemixin.methods.getKundenBezeichnungOrName(value);
                    });
                    vm.options = res.data.kundeList;
                    vm.$emit('kunden-options', res.data.kundeList);
                } else {
                    vm.options = [];
                }

                loading(false);
            });
        }, DEBOUNCE_DEFAULT_MILLIS),
    },
};
</script>

<style scoped></style>

<!-- language=json -->
<i18n>
    {
        "de": {
            "placeholder": "Kunden-/Lieferantensuche",
            "placeholderBestellung": "Schnellsuche: Empfänger",
            "placeholderLieferant": "Lieferantensuche",
            "new": "Neuen Kunden anlegen",
            "no-result": "Kein Ergebnis",
            "save": "Speichern",
            "cancel": "Abbrechen"
        },
        "en": {
            "placeholder": "Customer / Supplier Search",
            "placeholderBestellung": "Search: Receiver",
            "placeholderLieferant": "Supplier Search",
            "new": "Create new customer",
            "no-result": "No result",
            "save": "Save",
            "cancel": "Abbrechen"
        }
    }
</i18n>
