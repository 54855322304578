import Vue from 'vue';
import ContentFileService from './contentfile.service.js';

const getDefaultItem = () => ({
    id: null,
    folderName: null,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
});

const contentfile = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state) {
            Vue.set(state, 'item', JSON.parse(JSON.stringify(getDefaultState().item)));
            Vue.set(state, 'list', JSON.parse(JSON.stringify(getDefaultState().list)));
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async queryRoot(context) {
            const { data } = await ContentFileService.queryRoot();
            context.commit('updateList', data);
            return data;
        },
        async queryByParentFolder(context, client_data) {
            const { parentFolderId } = client_data;
            const { data } = await ContentFileService.queryByParentFolder(parentFolderId);
            context.commit('updateList', data);
            return data;
        },
        async createFolder(context, client_data) {
            const { parentFolderId, folderName } = client_data;
            const { data } = await ContentFileService.create(parentFolderId, folderName);
            context.commit('updateList', data);
            return data;
        },
    },

    getters: {
        list(state) {
            return state.list;
        },
    },
};

export default contentfile;
