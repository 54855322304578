<template>
    <button :ref="refName" :type="typ" :class="styleClass" :disabled="disabled" @click="action">
        <font-awesome-icon v-if="!loading" :icon="['fad', icon]" />
        <font-awesome-icon
            v-for="iconToDisplay in additionalIcons"
            v-if="additionalIcons && !loading"
            :key="iconToDisplay"
            :icon="['fad', iconToDisplay]"
            style="margin-left: 0.5em"
        />
        <div v-if="loading" :class="getSpinnerClass"></div>
        <span> {{ text }}</span>
        <slot v-if="showSlot"></slot>
    </button>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'ButtonSpinner',
    props: {
        refName: {
            type: String,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        typ: {
            type: String,
            default: 'button',
        },
        styleClass: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        mode: {
            type: Number,
            default: 0,
        },
        status: {
            // eslint-disable-next-line vue/require-prop-type-constructor
            type: String | Boolean,
            default: '',
        },
        icon: {
            type: String,
            default: 'save',
        },
        additionalIcons: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            loader: 'common/loaderState',
        }),
        getSpinnerClass() {
            return {
                spinner: this.loading && this.mode == 0,
                spinnerDark: this.loading && this.mode == 1,
                check: !this.emptyStatus && this.isSuccess && !this.loading,
                cross: !this.emptyStatus && !this.isSuccess && !this.loading,
            };
        },
        getBackgroundClass() {
            return {
                'vue-btn-loader-error': !this.emptyStatus && !this.isSuccess,
                'vue-btn-loader-success': this.isSuccess,
                'is-loading': this.loading,
            };
        },
        loading() {
            if (this.loader) {
                this.$emit('finished');
            }
            return this.isLoading ? true : false;
        },
        isSuccess() {
            return this.status === 'success' || this.status === true;
        },
        emptyStatus() {
            return this.status === '';
        },
        showSlot() {
            return this.loading || (!this.loading && this.emptyStatus);
        },
    },
    methods: {
        action() {
            this.$emit('action');
        },
    },
};
</script>

<style lang="css" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}
.fade-enter,
.fade-leave-active {
    opacity: 0;
    will-change: opacity;
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
/**
              Spinner Icon
          **/
.spinner {
    font-size: 1px;
    display: inline-block;
    text-indent: -9999em;
    width: 14em;
    height: 14em;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
.spinner:before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}
.spinner:after {
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.btn-primary .spinner:after {
    background: #2d6ca2 !important;
}

.spinnerDark {
    font-size: 1px;
    display: inline-block;
    text-indent: -9999em;
    width: 14em;
    height: 14em;
    border-radius: 50%;
    background: #007bff;
    background: -moz-linear-gradient(left, #007bff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #007bff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #007bff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #007bff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #007bff 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
.spinnerDark:before {
    width: 50%;
    height: 50%;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}
.spinnerDark:after {
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.btn-success .spinnerDark:after {
    background: rgba(296, 160, 37, 1) !important;
}
.dg-btn--ok .spinnerDark:after {
    background: white !important;
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/**
              Check Icon
          **/
.check {
    display: inline-block;
    width: 23px;
    height: 24px;
    border-radius: 50%;
    transform: rotate(45deg);
    color: white;
    will-change: transform;
}
.check:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 9px;
    background-color: #fff;
    left: 11px;
    top: 6px;
}
.check:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 3px;
    background-color: #fff;
    left: 8px;
    top: 12px;
}
/**
              Cross Icon
          **/
.cross {
    display: inline-block;
    width: 17px;
    height: 16px;
    position: relative;
}
.cross:before,
.cross:after {
    position: absolute;
    left: 8px;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: #fff;
}
.cross:before {
    transform: rotate(45deg);
    will-change: transform;
}
.cross:after {
    transform: rotate(-45deg);
    will-change: transform;
}
</style>
