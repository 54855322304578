import Vue from 'vue';
import ZahlungService from './zahlung.service';
import * as _ from 'lodash';
import moment from 'moment';
import abstractStore from '../../store/abstractstore';
import ApiService from '@/common/api.service';

export const getDefaultSort = () => ({ currentSort: 'belegDatum', currentSortDir: 'asc' });

const getDefaultItem = () => ({
    id: null,
    zahlungsDatum: null,
    kommentar: null,
    betrag: null,
    belegSet: [],
});

const getDefaultListItem = () => ({
    id: null,
    belegNummer: null,
    belegDatum: null,
    summeBrutto: null,
    summeNetto: null,
    kunde: [
        {
            bezeichnung: null,
            geschlecht: null,
            vorname: null,
            nachname: null,
        },
    ],
    bezahlt: null,
    zahlungList: [
        {
            zahlungsDatum: null,
            kommentar: null,
            betrag: null,
        },
    ],
    restbetrag: null,
    zahlungForUI: {
        zahlungsDatum: null,
    },
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: getDefaultListItem(),
    offenerSaldo: 0,
    currentPage: 0,
    totalPages: 1,
    ...abstractStore.getAbstractValue(getDefaultSort().currentSort, getDefaultSort().currentSortDir),
});

const zahlung = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state) {
            Vue.set(state, 'item', getDefaultState().item);
            Vue.set(state, 'list', getDefaultState().list);
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
        updateOffenerSaldo(state, data) {
            Vue.set(state, 'offenerSaldo', data);
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            const { data } = await ZahlungService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await ZahlungService.update(client_data.id, client_data);
            context.commit('update', data);
            return data;
        },
        async get(context, params) {
            const { headers, data } = await ZahlungService.get(params.slug, params);

            let currPage = params && params.params && params.params.get('page') ? params.params.get('page') : 0;
            context.commit('updateCurrentPage', +currPage);

            let size = params && params.params && params.params.get('size') ? params.params.get('size') : 20;
            let totalCount = headers['x-total-count'];
            let totalPages = 1;

            this.offeneBelege = [];
            let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '-');

            /* sort by Zahlungsdatum liefert anzahl belege gleich anzahl zahlungen */
            for (let i = 0; i < data.length; i++) {
                const beleg = data[i];
                const belegAlreadyProcessed = this.offeneBelege.find((x) => beleg.id === x.id);
                if (belegAlreadyProcessed) {
                    continue;
                }
                let brutto = Number(beleg.summeBrutto.toFixed(2));
                beleg.anzahlZahlungen = beleg.zahlungList.length;

                let restBetrag = beleg.summeBrutto;
                let zahlungsDatum = currentDateWithFormat;
                if (beleg.zahlungList.length > 0) {
                    beleg.zahlungList.sort(function (a, b) {
                        return moment(b.zahlungsDatum) - moment(a.zahlungsDatum);
                    });

                    if (beleg.bezahlt) {
                        zahlungsDatum = beleg.zahlungList[0].zahlungsDatum;
                    }
                    let summeZahlungen = 0;
                    beleg.zahlungList.forEach((list) => {
                        summeZahlungen += list.betrag;
                    });
                    restBetrag = brutto - summeZahlungen;
                }

                beleg.zahlungForUI = {
                    zahlungsDatum: zahlungsDatum,
                };

                if (beleg.bezahlt) {
                    beleg.restbetrag = 0;
                } else {
                    beleg.restbetrag = restBetrag;
                }
                this.offeneBelege.push(beleg);
            }

            if (_.toNumber(totalCount) > 0 && _.toNumber(size) > 0) {
                totalPages = _.toNumber(totalCount) / _.toNumber(size);
            }

            context.commit('updateTotalPages', _.ceil(totalPages));

            if (params.params.get('kundenId')) {
                params.slug = params.slug + '?kundenId=' + params.params.get('kundenId');
            }
            var offen = await ApiService.get('unpaidbelegesum', params.slug);
            context.commit('updateOffenerSaldo', offen.data);
            context.commit('updateList', this.offeneBelege);
            return this.offeneBelege;
        },
        async query(context, params) {
            const { headers, data } = await ZahlungService.query(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let size = params && params.params && params.params.size ? params.params.size : 20;
            let totalCount = headers['x-total-count'];
            let totalPages = 1;

            if (_.toNumber(totalCount) > 0 && _.toNumber(size) > 0) {
                totalPages = _.toNumber(totalCount) / _.toNumber(size);
            }

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            await ZahlungService.delete(slug);
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
        offenerSaldo(state) {
            return state.offenerSaldo;
        },
    },
};

export default _.merge(zahlung, abstractStore.getDefaultStore());
