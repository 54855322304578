import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'mandants';

const MandantService = {
    get(slug) {
        return ApiService.get(resource, slug);
    },

    async createStartBeleg(mandant) {
        return ApiService.post('rksv/startbeleg', mandant);
    },

    async getDep(date) {
        return ApiService.get('rksv/dep', date);
    },

    async getSJournal() {
        return ApiService.get('rksv/sjournal');
    },

    async query(params) {
        if (params.isadmin) {
            return ApiService.query(`${resource}/list`, params);
        }
        return ApiService.query(resource, params);
    },

    async queryAll(params) {
        return ApiService.query(`${resource}/all`, params);
    },

    async selectMandant(mandant) {
        return ApiService.post('selectedMandant', mandant);
    },

    async getModules() {
        return ApiService.query('getmandantmodule');
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async delete(slug) {
        let promise = null;
        try {
            promise = await ApiService.delete(resource, slug);
            Toast.showDeleteOkToastI18n();
        } catch (e) {
            Toast.showDeleteErrorToastI18n();
        }
        return promise;
    },
    async deactivate(slug) {
        let promise = null;
        try {
            promise = await ApiService.delete(`${resource}/deactivate`, slug);
            Toast.showDeactivateOkToastI18n();
        } catch (e) {
            Toast.showDectivateErrorToastI18n();
        }
        return promise;
    },
    async activate(slug) {
        let promise = null;
        try {
            promise = await ApiService.delete(`${resource}/activate`, slug);
            Toast.showActivateOkToastI18n();
        } catch (e) {
            Toast.showActivateErrorToastI18n();
        }
        return promise;
    },
};

export default MandantService;
