import Vue from 'vue';
import * as _ from 'lodash';
import abstractStore from '../../store/abstractstore';
import WcCategoryService from '@/app/webshop/wccategory.service';

export const getDefaultSort = () => ({ currentSort: 'date', currentSortDir: 'asc' });

const getDefaultItem = () => ({
    id: null,
    name: '',
    description: '',
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
    ...abstractStore.getAbstractValue(getDefaultSort().currentSort, getDefaultSort().currentSortDir),
});

const wccategory = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state) {
            Vue.set(state, 'item', getDefaultState().item);
            Vue.set(state, 'list', getDefaultState().list);
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            const { data } = await WcCategoryService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await WcCategoryService.update(client_data.id, client_data);
            context.commit('update', data);
            return data;
        },
        async get(context, id) {
            const { data } = await WcCategoryService.get(id);
            context.commit('update', data);
            return data;
        },
        async query(context, params) {
            const { data, headers } = await WcCategoryService.query(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let size = params && params.params && params.params.size ? params.params.size : 20;
            let totalCount = headers['x-total-count'];
            let totalPages = 1;

            if (_.toNumber(totalCount) > 0 && _.toNumber(size) > 0) {
                totalPages = _.toNumber(totalCount) / _.toNumber(size);
            }

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            const { data } = await WcCategoryService.delete(slug);
            context.commit('updateList', data);
            return data;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
    },
};

export default _.merge(wccategory, abstractStore.getDefaultStore());
