const ArtikelPreisHelper = {
    getRecentPreis(artikelPreisArray, preisschiene) {
        if (typeof preisschiene === 'undefined') preisschiene = null;

        let result = null;
        const now = new Date();

        for (let i = 0, len = artikelPreisArray.length; i < len; i++) {
            const aPreis = artikelPreisArray[i];
            const aPreisVonDatum = new Date(aPreis.vonDatum);

            if (aPreis.preisschiene === preisschiene || (aPreis.preisschiene != null && preisschiene != null && aPreis.preisschiene.id === preisschiene.id)) {
                if (result == null) {
                    if (aPreisVonDatum <= now) {
                        result = aPreis;
                    }
                } else {
                    if (aPreisVonDatum <= now && aPreisVonDatum > result.vonDatum) {
                        result = aPreis;
                    }
                }
            }
        }

        //wenn kein Preis gefunden auf dieser Preisschiene dann fallback auf defaultpreis
        if (preisschiene != null && result == null) {
            result = this.getRecentPreis(artikelPreisArray, null);
        }

        return result;
    },
};

export default ArtikelPreisHelper;
