export default {
    bind: function (el, binding) {
        el.addEventListener('click', () => {
            let height = window.scrollY + el.getBoundingClientRect().top; // Y
            let visibleArea = window.innerHeight - height;

            if (visibleArea <= binding.value.size) {
                let diff = binding.value.size - visibleArea;
                el.children.forEach((child) => {
                    if (child.className === binding.value.class) {
                        child.style.marginTop = -diff + 'px';
                    }
                });
            }
        });
    },
};
