<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div role="document">
                    <div class="modal-content" style="min-width: 38em">
                        <div class="modal-header">
                            <h5 class="modal-title mt-2">Lieferanten Informationen</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" @click="$emit('close')">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div v-for="(lieferant, index) in lieferanten" :key="lieferant.id" class="form-group">
                                <div class="row" v-if="lieferant.artikelnummer">
                                    <div class="col-4">Artikelnummer:</div>
                                    <div class="col-8">
                                        {{ lieferant.lieferantArtikelNummer }}
                                    </div>
                                </div>
                                <div class="row" v-if="lieferant.preis">
                                    <div class="col-4">Preis:</div>
                                    <div class="col-8">{{ lieferant.preis.toFixed(2) }} €</div>
                                </div>
                                <div class="row" v-if="lieferant.letzteLieferung">
                                    <div class="col-4">letzte Lieferung:</div>
                                    <div class="col-8">
                                        {{ getLieferdatum(lieferant.letzteLieferung) }}
                                    </div>
                                </div>
                                <div class="row" v-if="lieferant.lieferant.bezeichnung">
                                    <div class="col-4">Bezeichnung:</div>
                                    <div class="col-8">
                                        {{ lieferant.lieferant.bezeichnung }}
                                    </div>
                                </div>
                                <div class="row" v-if="lieferant.lieferant.email">
                                    <div class="col-4">E-Mail:</div>
                                    <div class="col-8">
                                        {{ lieferant.lieferant.email }}
                                    </div>
                                </div>
                                <div class="row" v-if="lieferant.lieferant.homepage">
                                    <div class="col-4">Homepage:</div>
                                    <div class="col-8">
                                        <a target="_blank" :href="lieferant.lieferant.homepage">{{ lieferant.lieferant.homepage }}</a>
                                    </div>
                                </div>
                                <div class="row" v-if="lieferant.lieferant.telnr">
                                    <div class="col-4">Telefonnummer:</div>
                                    <div class="col-8">
                                        {{ lieferant.lieferant.telnr }}
                                    </div>
                                </div>
                                <hr v-if="index + 1 !== lieferanten.length" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import moment from 'moment';

export default {
    name: 'LieferantenInfo',
    props: {
        lieferanten: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        getLieferdatum(lieferDatum) {
            if (lieferDatum) {
                return moment(lieferDatum).format('DD.MM.YYYY');
            } else return lieferDatum;
        },
    },
};
</script>

<style lang="css" scoped></style>
