<template>
    <div>
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <form name="editForm" role="form" @submit.prevent="createBelegtext()">
                            <div class="modal-header">
                                <slot name="header">
                                    <h5>{{ $t('new') }}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('cancle')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </slot>
                            </div>
                            <div class="modal-body">
                                <slot name="body">
                                    <div class="container">
                                        <div class="form-group">
                                            <label class="control-label" for="field_title">{{ $t('title') }}</label>

                                            <input id="field_title" v-model="belegtext.title" class="form-control" @input="setTitle($event.target.value)" />
                                            <validation-component :validation="$v.belegtext.title"></validation-component>
                                        </div>
                                        <div class="form-group">
                                            <label class="control-label" for="field_text">{{ $t('text') }}</label>
                                            <editormenu :editor="editorHotHintModal" :editor-disabled-buttons="editorDisabledButtons"></editormenu>
                                            <div class="signatureTextContent">
                                                <editor-content id="field_text" :editor="editorHotHintModal" />
                                            </div>
                                            <validation-component :validation="$v.belegtext.text"></validation-component>
                                        </div>
                                    </div>
                                </slot>
                            </div>
                            <div class="modal-footer">
                                <slot name="footer">
                                    <button type="button" class="btn dg-btn--cancel" @click="$emit('cancle')">
                                        <font-awesome-icon :icon="['fad', 'ban']" />&nbsp;<span>{{ $t('cancel') }}</span>
                                    </button>
                                    <button type="button" :disabled="$v.belegtext.$invalid" class="btn dg-btn--ok dg-pull-right" @click="createBelegtext()">
                                        <font-awesome-icon :icon="['fad', 'save']" />&nbsp;<span>{{ $t('save') }}</span>
                                    </button>
                                </slot>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { Editor, EditorContent } from '@tiptap/vue-2';
import Editormenu from '@/components/editormenu';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import Paragraph from '@tiptap/extension-paragraph';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';

export default {
    name: 'BelegtextModal',
    components: { EditorContent, Editormenu },
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            editorHotHintModal: new Editor({
                extensions: [Document, Text, Paragraph, Bold, Italic, Underline, BulletList, OrderedList, ListItem],
                content: '',
                onUpdate: ({ editor }) => {
                    this.setText(editor.getHTML());
                },
            }),
            editorDisabledButtons: ['Image', 'Code', 'H1', 'H2', 'H3', 'Left', 'Center', 'Right'],
        };
    },
    computed: {
        ...mapGetters({
            belegtext: 'belegtext/data',
        }),
    },
    validations: {
        belegtext: {
            title: {
                required,
            },
            text: {
                required,
            },
        },
    },
    mounted() {
        this.$store.dispatch('belegtext/clear');
        this.editorHotHintModal.commands.setContent(this.value);
        this.setText(this.value);
    },
    methods: {
        setText(value) {
            this.belegtext.text = value;
            this.$v.belegtext.text.$touch();
        },
        setTitle(value) {
            this.belegtext.title = value;
            this.$v.belegtext.title.$touch();
        },
        createBelegtext() {
            const hotHintText = {
                id: null,
                hotHintTitle: this.belegtext.title,
                hotHintText: this.belegtext.text,
            };
            this.$store.dispatch('belegtext/create', hotHintText).then((response) => {
                let buttonText = {
                    id: response.id,
                    title: response.hotHintTitle,
                    showText: response.hotHintText,
                    text: response.hotHintText,
                };
                this.$emit('close-modal', buttonText);
            });
        },
    },
};
</script>

<style>
.signatureTextContent {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    overflow: auto;
    max-height: 60em;
    height: 20em;
}
</style>

<!-- language=json -->
<i18n>
    {
        "de": {
            "new": "Neuen Belegtext anlegen",
            "no-result": "Kein Ergebnis",
            "save": "Speichern",
            "cancel": "Abbrechen",
            "text": "Belegtext",
            "title": "Bezeichnung"
        },
        "en": {
            "new": "Neuen Belegtext anlegen",
            "no-result": "Kein Ergebnis",
            "save": "Speichern",
            "cancel": "Abbrechen",
            "text": "Belegtext",
            "title": "Bezeichnung"
        }
    }
</i18n>
