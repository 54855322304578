import Vue from 'vue';
import AuswertungService from './auswertung.service';

const getDefaultState = () => ({
    list: [],
    listPreviousYear: [],
    listArtikel: [],
    listKunde: [],
    listStunden: [],
    listKassabuch: [],
});

const auswertung = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        updateListPreviousYear(state, data) {
            Vue.set(state, 'listPreviousYear', data);
        },
        updateListKassabuch(state, data) {
            Vue.set(state, 'listKassabuch', data);
        },
        updateListArtikel(state, data) {
            Vue.set(state, 'listArtikel', data);
        },
        updateListKunde(state, data) {
            Vue.set(state, 'listKunde', data);
        },
        updateListStunden(state, data) {
            Vue.set(state, 'listStunden', data);
        },
        clear(state) {
            Vue.set(state, 'list', getDefaultState().list);
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async get(context, year) {
            const { data } = await AuswertungService.get(year);
            context.commit('updateList', data.belegAuswertungDetailDtoList);
            return data;
        },
        async getPreviousYear(context, year) {
            const { data } = await AuswertungService.get(year);
            context.commit('updateListPreviousYear', data.belegAuswertungDetailDtoList);
            return data;
        },
        async getKassabuch(context, year) {
            const { data } = await AuswertungService.get(year);
            context.commit('updateListKassabuch', data.kassabuchDetailDtoList);
            return data;
        },
        async getArtikel(context, year) {
            let fromTo = 'artikel/' + year;
            const { data } = await AuswertungService.get(fromTo);
            context.commit('updateListArtikel', data.artikelAuswertungDetailDtoList);
            return data;
        },
        async getKunde(context, year) {
            let fromTo = 'kunde/' + year;
            const { data } = await AuswertungService.get(fromTo);
            context.commit('updateListKunde', data.kundeAuswertungDetailDtoList);
            return data;
        },
        async getStunden(context, year) {
            let fromTo = 'stunden/' + year;
            const { data } = await AuswertungService.get(fromTo);
            context.commit('updateListStunden', data.stundenAuswertungDetailDtoList);
            return data;
        },
        async delete(context, slug) {
            await AuswertungService.delete(slug);
        },
    },

    getters: {
        list(state) {
            return state.list;
        },
        listPreviousYear(state) {
            return state.listPreviousYear;
        },
        listKassabuch(state) {
            return state.listKassabuch;
        },
        listArtikel(state) {
            return state.listArtikel;
        },
        listKunde(state) {
            return state.listKunde;
        },
        listStunden(state) {
            return state.listStunden;
        },
    },
};

export default auswertung;
